import { MediaCard, Modal, Page, TextContainer, TextField, VideoThumbnail } from '@shopify/polaris';
import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import VideoSkeleton from '../../../components/VideoSkeleton';
import { PostData } from '../../../services/PostData';
import { ProductContext } from '../../../store';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';

const VideoFiles = () => {
    const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(12)
    const [totalPages, setTotalPages] = useState(1)
    const [interactiveVideos, setInteractiveVideos] = useState([])
	const [pageLoader, setPageLoder] = useState(true)
	const [pagniationLoader, setPagniationLoader]   = useState(true)
    const [hasNext, setHasNext]					    = useState(false)
	const [hasPrevious, setHasPrevious]				= useState(false)
	const [editVideoDetail, setEditVideoDetail]		= useState({})
	const [deleteActionFlag, setDeleteActionFlag]	= useState(false)
	const [renameActionFlag, setRenameActionFlag]	= useState(false)
	const [deleteFlag, setDeleteFlag]				= useState(false)
	const [renameFlag, setRenameFlag]				= useState(false)
	const [videoNameError, setVideoNameError]		= useState(null)
    const {
		userData,
		setUserData,
		isLoading,
		setIsLoading,
		setLoadSideMenu,
		setToastChildActive,
		setToastMessage,
	} = useContext(ProductContext)
    
    useEffect(() => {
		document.title = "Videos Library | Muqala App"
		return () => {
			document.title = "Muqala App"
		}
	}, [])

    useEffect(() => {
		getInteractiveVideo()
	}, [page, pageSize])

    const getInteractiveVideo = () => {
		setPagniationLoader(true)
		let data = { page, page_size: parseInt(pageSize) }
		PostData('api/v1/getVideoLibraries', data).then(result => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					//setPageSize(result.data.page_size)
					//setPage(result.data.page)
					setTotalPages(result.data.totalPages)
					setInteractiveVideos(result.data.interactiveVideos)
					setPageLoder(false)
					setPagniationLoader(false)
					if( result.data.page > 1 ){
						setHasPrevious(true)
					}else{
						setHasPrevious(false)
					}
					if( result.data.totalPages > 1 && result.data.page !== result.data.totalPages ){
						setHasNext(true)
					}else{
						setHasNext(false)
					}
				}
			}
		})
	}

    const converDateTime = (val) => {
		const today = new Date();
		const endDate = new Date(val);
		const days = parseInt(Math.abs(today.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24));
		const hours = parseInt(Math.abs(today.getTime() - endDate.getTime()) / (1000 * 60 * 60) % 24);
		const minutes = parseInt(Math.abs(today.getTime() - endDate.getTime()) / (1000 * 60) % 60);
		const seconds = parseInt(Math.abs(today.getTime() - endDate.getTime()) / (1000) % 60);
		let date = "";
		if (days > 0) {
			date = days + " days ago";
		} else if (hours > 0) {
			date = hours + " hours ago";
		} else if (minutes > 0) {
			date = minutes + " minutes ago";
		} else if (seconds > 0) {
			date = seconds + " seconds ago";
		}
		return date;
	}

    const onPreviousFunc	= () => {
		setPage(page-1)
	}

	const onNextFunc	= () => {
		setPage(page+1)
	}

	const renameVideoFiles	= (video) => {
		setEditVideoDetail(video)
		setRenameActionFlag(true)
	}

	const deleteVideoFiles	= (video) => {
		setEditVideoDetail(video)
		setDeleteActionFlag(true)
	}

	const handleDeleteClose	= () => {
		setEditVideoDetail(null)
		setDeleteActionFlag(false)
		setDeleteFlag(false)
	}

	const handleRenameClose	= () => {
		setEditVideoDetail(null)
		setRenameActionFlag(false)
		setRenameFlag(false)
	}

	const okDelete = () => {
		setDeleteFlag(true)
		let data	= {video_library_id: editVideoDetail.video_library_id}
		PostData("api/v1/deleteVideoFiles", data).then( response => {
			if( response	!== "Invalid" ){
				if( response.status === "success" ){
					setToastMessage(response.message)
					getInteractiveVideo()
				}else if( response.status === "failure" ){
					setToastMessage(response.message)
				}else{
					setToastMessage(response.message.message)
				}
			}
			handleDeleteClose()
		})
	}

	const okRenameVideo	= () => {
		if( editVideoDetail.video_name === "" ){
			setVideoNameError("Please enter the video name");
		}else{
			setVideoNameError(null)
			setRenameFlag(true)
			let data	= {video_library_id: editVideoDetail.video_library_id, video_name: editVideoDetail.video_name}
			PostData("api/v1/renameVideoFiles", data).then( response => {
				if( response	!== "Invalid" ){
					if( response.status === "success" ){
						setToastMessage(response.message)
						getInteractiveVideo()
					}else if( response.status === "failure" ){
						setToastMessage(response.message)
					}else{
						setToastMessage(response.message.message)
					}
				}
				handleRenameClose()
			})
		}
	}

	const handleVideoNameChange	= (value) => {
		setEditVideoDetail({
			...editVideoDetail,
			video_name: value
		})
		if( value === "" ){
			setVideoNameError("Please enter the video name");
		}else{
			setVideoNameError(null)
		}
	}

    return (
        <div className="homePageWrapper mt-20">
			<Page
				title="Muqala Video"
				pagination={{
					hasPrevious: hasPrevious,
					hasNext: hasNext,
					onPrevious: onPreviousFunc,
					onNext: onNextFunc
				}}
			>
				{pageLoader ?
					<VideoSkeleton />
					:
					<>
						{pagniationLoader ?
							<VideoSkeleton />
							:
							<>
								{interactiveVideos.length > 0 ?
									<div className="mt-4 mb-4 row">
										{interactiveVideos.map((video, index) => {
											return (
												<div className="mt-3 mb-3 col-xl-3 col-lg-4 col-sm-6 cursorPointer">
													<MediaCard
														portrait
														title={video.video_name}
														popoverActions={
																			[
																				{content: 'Delete', icon: DeleteMinor, onAction: () => {deleteVideoFiles(video)}},
																				{content: 'Rename', icon: EditMinor, onAction: () => {renameVideoFiles(video)}}
																			]
																		}
														description={converDateTime(video.created_at)}
													>
														<VideoThumbnail
															thumbnailUrl={(video['video_thumbnail'] === "" || video['video_thumbnail'] === null) ? "https://storage.googleapis.com/roundclicksview/Popup%20Template/Image_not_available.png" : video.video_thumbnail}
														/>
													</MediaCard>
												</div>
											)
										})}
									</div>
									:
									<div style={{ minHeight: "400px" }} className="align-items-center justify-content-center row">
										<div className="col-auto">
											<p className="text-center mt-4" style={{ fontSize: "18px" }}>
												<svg className="ant-empty-img-simple" width="210" height="190" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
													<g transform="translate(0 1)" fill="none" fill-rule="evenodd">
														<ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
														<g className="ant-empty-img-simple-g" fill-rule="nonzero">
															<path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
															<path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path>
														</g>
													</g>
												</svg><br />
												<b>No Videos to show</b>
											</p>
										</div>
									</div>
								}
							</>
						}
					</>
				}
			</Page>
			<Modal
				instant
				small
				open={deleteActionFlag}
				onClose={handleDeleteClose}
				title=""
				primaryAction={{
					content: 'Delete',
					onAction: okDelete,
					loading: deleteFlag
				}}
				secondaryActions={[
				{
					content: 'Cancel',
					onAction: handleDeleteClose,
				},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<p>Are you sure you want to delete video file?</p>
					</TextContainer>
				</Modal.Section>
			</Modal>
			<Modal
				instant
				small
				open={renameActionFlag}
				onClose={handleRenameClose}
				title="Rename Video File"
				primaryAction={{
					content: 'Update',
					onAction: okRenameVideo,
					loading: renameFlag
				}}
				secondaryActions={[
				{
					content: 'Cancel',
					onAction: handleRenameClose,
				},
				]}
			>
				<Modal.Section>
					<TextField
						label="Video Name"
						value={editVideoDetail && editVideoDetail.video_name}
						onChange={handleVideoNameChange}
						error={videoNameError}
     					autoComplete="off"
					/>
				</Modal.Section>
			</Modal>
        </div>
    );
}

export default VideoFiles;