import React, { useCallback, useRef, useState, useEffect } from 'react';
import { ActionList, AppProvider, Avatar, Banner, Button, Card, Checkbox, ContextualSaveBar, Form, FormLayout, Frame, Heading, Layout, Link, Loading, Modal, Navigation, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Spinner, TextContainer, TextField, Toast, TopBar } from '@shopify/polaris';
import { ArrowLeftMinor, ConversationMinor, HomeMajor, OrdersMajor } from '@shopify/polaris-icons';
import { PostBeforeLogin } from '../../services/PostData';
import Cookies from 'js-cookie'
import { Navigate, Route, Routes } from 'react-router-dom';

const ForgotPassword = () => {
	useEffect(() => {
		document.title = "Muqala Video | Forgot Password"
		return () => {
			document.title = "Muqala Video"
		}
	}, [])

	const [toastActive, setToastActive] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({})
	const [email, setEmail] = useState("")
	const [passwordResponse, setPasswordResponse] = useState({})

	const toggleToastActive = useCallback(
		() => setToastActive((toastActive) => !toastActive),
		[],
	);

	const handleInputChange = (fieldName, value) => {
		if (fieldName === "email") {
			setEmail(value)
			if (value === "") {
				setErrors({ ...errors, email: "Please enter the email address" })
			} else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
				setErrors({ ...errors, email: "Please enter valid email address" })
			} else {
				if (typeof errors.email !== "undefined") {
					delete errors.email
				}
				setErrors(errors)
			}
		}
	}

	const handleInputBlur = (fieldName) => {
		if (fieldName === "email") {
			if (email === "") {
				setErrors({ ...errors, email: "Please enter the email address" })
			} else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
				setErrors({ ...errors, email: "Please enter valid email address" })
			} else {
				if (typeof errors.email !== "undefined") {
					delete errors.email
				}
				setErrors(errors)
			}
		}
	}

	const handleLoginSubmit = () => {
		let errorFlag = true
		if (email === "") {
			setErrors({ ...errors, email: "Please enter the email address" })
			errorFlag = false
		} else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			setErrors({ ...errors, email: "Please enter valid email address" })
			errorFlag = false
		} else {
			if (typeof errors.email !== "undefined") {
				delete errors.email
			}
			setErrors(errors)
		}
		if (errorFlag) {
			setIsLoading(true)
			PostBeforeLogin("api/v1/user/forgot-password", { email: email }).then(response => {
				if (response !== "Invalid") {
					setIsLoading(false)
					if (response.status === "success") {
						setPasswordResponse({content: 'Reset Link Sent to your email!', status: 'success', title: "" })
					}else{
						setPasswordResponse({content: response.message.message, status: 'critical', title: ""})
					}
				}
			})
		}
	}

	const dismissBanner = () => {
		setPasswordResponse({})
	}

	const toastMarkup = toastActive ? (
		<Toast onDismiss={toggleToastActive} content="Changes saved" />
	) : null;

	const loadingMarkup = isLoading ? <Loading /> : null;

	const actualPageMarkup = (
		<section className='LoginPage'>
			<Layout>
				<Layout.Section secondary>
					<div className='contentSection leftWing'>
						<div className="logo">
							<a href='https://muqala.com' target={"_blank"} className="d-block">
								<img src="https://storage.googleapis.com/speechvid/muqala/muqala_app_new_logo_white_black.png" alt="Muqala Logo" />
							</a>
						</div>
						<svg className="loginLeftSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368 196" fill="none">
							<path opacity="0.08" d="M64 196L524 138.842V-8L64 49.1579V196Z" fill="url(#toprect0_linear)"></path>
							<path opacity="0.15" d="M0 189L219 161.841V80L0 107.159V189Z" fill="url(#toprect1_linear)"></path>
							<defs>
								<linearGradient id="toprect0_linear" x1="221.834" y1="-12.9538" x2="140.887" y2="68.5282" gradientUnits="userSpaceOnUse">
									<stop stopColor="#FFA105" stopOpacity="0"></stop>
									<stop offset="1" stopColor="#FFA105"></stop>
								</linearGradient>
								<linearGradient id="toprect1_linear" x1="219" y1="137.331" x2="1.5643" y2="86.8415" gradientUnits="userSpaceOnUse">
									<stop stopColor="#783FF1" stopOpacity="0"></stop>
									<stop offset="1" stopColor="#783FF1"></stop>
								</linearGradient>
							</defs>
						</svg>
						<TextContainer>
							<div className="bannerTextContent">
								<div className="DisplayText-ls">
									<p className="Polaris-DisplayText Polaris-DisplayText--sizeLarge"> Interact face-to-face with your audience</p>
								</div>
								<div className="spacing"></div>
								<p className="DisplayText-font-size">Let Your Interactive Video Do the Talking!</p>
							</div>
						</TextContainer>
					</div>
				</Layout.Section>
				<Layout.Section>
					<div className='loginForm'>
						<div className='loginInnerWrap'>
							<Heading element='h1' id="loginTitle">Forgot Password</Heading>
							{Object.keys(passwordResponse).length != 0 &&
								<div className='mb-1'>
									<Banner
										title={passwordResponse.title ? passwordResponse.title : ''}
										status={passwordResponse.status}									
										onDismiss={() => dismissBanner()}
									>
										<p>{passwordResponse.content}</p>
									</Banner>
								</div>
							}
							<Form onSubmit={handleLoginSubmit}>
								<FormLayout>
									<TextField
										value={email}
										onChange={(e) => handleInputChange('email', e)}
										onBlur={(e) => handleInputBlur('email')}
										label="Email Address"
										type="email"
										name="email"
										error={typeof errors['email'] !== "undefined" ? errors['email'] : ""}
										placeholder='Email Address'
										className="form-control"
									/>
									<Button submit>
										{isLoading ?
											<>
												<Spinner accessibilityLabel="Small spinner example" size="small" /> Loading
											</>
											:
											<>Send Reset Email</>
										}
									</Button>
								</FormLayout>
							</Form>
							<div className='loginLinkWrapper'>
								<p>Already have an account? <Link url='/account/login'>Login</Link></p>
							</div>
						</div>
					</div>
				</Layout.Section>
			</Layout>
		</section>
	);

	return (
		<div>
			<AppProvider
				i18n={{
					Polaris: {
						Avatar: {
							label: 'Avatar',
							labelWithInitials: 'Avatar with initials {initials}',
						},
						ContextualSaveBar: {
							save: 'Save',
							discard: 'Discard',
						},
						TextField: {
							characterCount: '{count} characters',
						},
						Modal: {
							iFrameTitle: 'body markup',
						},
						Frame: {
							skipToContent: 'Skip to content',
							navigationLabel: 'Navigation',
							Navigation: {
								closeMobileNavigationLabel: 'Close navigation',
							},
						},
					},
				}}
			>
				<Frame
					offset="0px"
				>
					{loadingMarkup}
					{actualPageMarkup}
					{toastMarkup}
				</Frame>
			</AppProvider>
		</div>
	);
};

export default ForgotPassword;