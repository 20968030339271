import React, { useState, useRef } from "react";
import { ProductContext } from "./index";

const ProductProvider = props => {
	const [userLoading, setUserLoading]					= useState(true)
    const [userData, setUserData]						= useState({})
    const [isLoading, setIsLoading]						= useState(true);
	const [toastChildActive, setToastChildActive]	= useState(false)
	const [toastMessage, setToastMessage]				= useState("")
	const [toastDuration, setToastDuration]			= useState(2000)
	const [planData, setPlanData]							= useState([])
	const [interactiveVideos, setInteractiveVideos]	= useState([])
	const [loadSideMenu, setLoadSideMenu]				= useState(true)
	const [folderVideos, setFolderVideos]				= useState([])
	const [newVideoFolderId, setNewVideoFolderId]	= useState(null)
	const [triggerNewFolder, setTriggerNewFolder]	= useState(false)
	const [createFolderSuccess, setCreateFolderSuccess]	= useState(null)
	const [triggerRenameFolder, setTriggerRenameFolder]	= useState(null)
	const [renameFolderSuccess, setRenameFolderSuccess]	= useState(null)
	const [triggerDeleteFolder, setTriggerDeleteFolder]	= useState(null)
	const [deleteFolderSuccess, setDeleteFolderSuccess]	= useState(null)
	const [triggerFolderMuqala, setTriggerFolderMuqala]	= useState(null)
	const [triggerMoveFolder, setTriggerMoveFolder]	= useState(null)
	const [moveFolderSuccess, setMoveFolderSuccess]	= useState(null)
	const [currentFolderId, setCurrentFolderId]		= useState(-1)

	const store = {
		userData,
		setUserData,
		isLoading,
		setIsLoading,
		toastChildActive,
		setToastChildActive,
		toastMessage,
		setToastMessage,
		toastDuration,
		setToastDuration,
		planData,
		setPlanData,
		userLoading,
		setUserLoading,
		interactiveVideos,
		setInteractiveVideos,
		loadSideMenu,
		setLoadSideMenu,
		folderVideos,
		setFolderVideos,
		newVideoFolderId,
		setNewVideoFolderId,
		triggerNewFolder,
		setTriggerNewFolder,
		createFolderSuccess,
		setCreateFolderSuccess,
		triggerRenameFolder,
		setTriggerRenameFolder,
		renameFolderSuccess,
		setRenameFolderSuccess,
		triggerDeleteFolder,
		setTriggerDeleteFolder,
		deleteFolderSuccess,
		setDeleteFolderSuccess,
		triggerFolderMuqala,
		setTriggerFolderMuqala,
		triggerMoveFolder,
		setTriggerMoveFolder,
		moveFolderSuccess,
		setMoveFolderSuccess,
		currentFolderId,
		setCurrentFolderId
	}
	return <ProductContext.Provider value={store}>{props.children}</ProductContext.Provider>;
}

export default ProductProvider;