import React, { useCallback, useState, useEffect } from 'react';
import { AppProvider, Button, Form, FormLayout, Frame, Heading, InlineError, Layout, Link, Loading, Spinner, TextContainer, TextField, Toast } from '@shopify/polaris';
import { PostBeforeLogin } from '../../services/PostData';
import Cookies from 'js-cookie'
import { Navigate, useLocation } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

const Register = () => {

	const [toastActive, setToastActive]					= useState(false);
	const [connectGmailLoader, setConnectGmailLoader]	= useState(false)
	const [isLoading, setIsLoading]						= useState(false);
	const [errors, setErrors]							= useState({})
	const [passwordShown, setPasswordShown]				= useState(false)
	const [redirectFlag, setRedirectFlag]				= useState(false)
	const [email, setEmail]								= useState("")
	const [name, setName]								= useState("")
	const [password, setPassword]						= useState("")
	const [errorMsg, setErrorMsg]						= useState("")
	const search										= useLocation().search;
  	const queryString									= new URLSearchParams(search).get('muqala_source');

	useEffect(() => {
		if( !queryString || queryString !== "BsQciPAhgpiunVH" ){
			setRedirectFlag(true)
		}
		document.title = "Muqala Video | Register"
		return () => {
			document.title = "Muqala Video"
		}
	}, [])

	const toggleToastActive = useCallback(
		() => setToastActive((toastActive) => !toastActive),
		[],
	);

	const handleInputChange = (fieldName, value) => {
		if( fieldName === "name" ){
			setName(value)
			if (value === "") {
				setErrors({ ...errors, name: "Please enter the name" })
			} else if ( value.length < 2 ) {
				setErrors({ ...errors, name: "Please enter valid name" })
			} else {
				if (typeof errors.name !== "undefined") {
					delete errors.name
				}
				setErrors(errors)
			}
		}else if (fieldName === "email") {
			setEmail(value)
			if (value === "") {
				setErrors({ ...errors, email: "Please enter the email address" })
			} else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
				setErrors({ ...errors, email: "Please enter valid email address" })
			} else {
				if (typeof errors.email !== "undefined") {
					delete errors.email
				}
				setErrors(errors)
			}
		} else if (fieldName === "password") {
			setPassword(value)
			if (value === "") {
				setErrors({ ...errors, password: "Please enter the password" })
			} else if (value.length < 6) {
				setErrors({ ...errors, password: "Minimum six character needed" })
			} else {
				if (typeof errors.password !== "undefined") {
					delete errors.password
				}
				setErrors(errors)
			}
		}
	}

	const handleInputBlur = (fieldName) => {
		if( fieldName === "name" ){
			if (name === "") {
				setErrors({ ...errors, name: "Please enter the name" })
			} else if ( name.length < 2 ) {
				setErrors({ ...errors, name: "Please enter valid name" })
			} else {
				if (typeof errors.name !== "undefined") {
					delete errors.name
				}
				setErrors(errors)
			}
		}else if (fieldName === "email") {
			if (email === "") {
				setErrors({ ...errors, email: "Please enter the email address" })
			} else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
				setErrors({ ...errors, email: "Please enter valid email address" })
			} else {
				if (typeof errors.email !== "undefined") {
					delete errors.email
				}
				setErrors(errors)
			}
		} else if (fieldName === "password") {
			if (password === "") {
				setErrors({ ...errors, password: "Please enter the password" })
			} else if (password.length < 6) {
				setErrors({ ...errors, password: "Minimum six character needed" })
			} else {
				if (typeof errors.password !== "undefined") {
					delete errors.password
				}
				setErrors(errors)
			}
		}
	}

	const handleRegisterSubmit = () => {
		let errorFlag = true
		let errorObject = {}
		if( name === "" ){
			errorObject = { ...errorObject, name: "Please enter the name" }
			errorFlag = false
		}else if( name.length < 2 ){
			errorObject = { ...errorObject, name: "Please enter valid name" }
			errorFlag = false
		}
		if (email === "") {
			errorObject = { ...errorObject, email: "Please enter the email address" }
			errorFlag = false
		} else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			errorObject = { ...errorObject, email: "Please enter valid email address" }
			errorFlag = false
		}
		if (password === "") {
			errorObject = { ...errorObject, password: "Please enter the password" }
			errorFlag = false
		} else if (password.length < 6) {
			errorObject = { ...errorObject, password: "Minimum six character needed" }
			errorFlag = false
		}
		setErrors(errorObject)
		if (errorFlag) {
			setIsLoading(true)
			PostBeforeLogin("api/v1/user/ecom-register", { name, email, password }).then(response => {
				if (response !== "Invalid") {
					if (response.status === "success") {
						Cookies.set('_prod_token', response.data.accessToken)
						setIsLoading(false)
						setRedirectFlag(true)
					} else if (response.status === "failure") {
						setIsLoading(false)
						setErrorMsg(response.message)
					}
				}
			})
		}
	}

	const Gmaillogin = useGoogleLogin({		
		onSuccess: (codeResponse) => {
			setConnectGmailLoader(true)
			let userData = {Code:codeResponse.code}
			PostBeforeLogin('api/v1/user/gmail-login', userData).then(response => {
				if (response !== 'Invalid') {
					if( response.status === "success" ){
						Cookies.set('_prod_token', response.data.accessToken)
						setIsLoading(false)
						setRedirectFlag(true)
					}else{
						setIsLoading(false)
						setErrorMsg(response.message)
					}
				} else {
					setIsLoading(false)
					setErrorMsg(response.message.message)
				}
				setConnectGmailLoader(false)
			});
		},
		onError: (error) => console.log('Login Failed:', error),
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
	});

	const toastMarkup = toastActive ? (
		<Toast onDismiss={toggleToastActive} content="Changes saved" />
	) : null;

	const loadingMarkup = isLoading ? <Loading /> : null;

	const actualPageMarkup = (
		<section className='LoginPage'>
			<Layout>
				<Layout.Section secondary>
					<div className='contentSection leftWing'>
						<div className="logo">
							<a href='https://muqala.com' target={"_blank"} className="d-block">
								<img src="https://storage.googleapis.com/speechvid/muqala/muqala_app_new_logo_white_black.png" alt="Muqala Logo" />
							</a>
						</div>
						<svg className="loginLeftSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368 196" fill="none">
							<path opacity="0.08" d="M64 196L524 138.842V-8L64 49.1579V196Z" fill="url(#toprect0_linear)"></path>
							<path opacity="0.15" d="M0 189L219 161.841V80L0 107.159V189Z" fill="url(#toprect1_linear)"></path>
							<defs>
								<linearGradient id="toprect0_linear" x1="221.834" y1="-12.9538" x2="140.887" y2="68.5282" gradientUnits="userSpaceOnUse">
									<stop stopColor="#FFA105" stopOpacity="0"></stop>
									<stop offset="1" stopColor="#FFA105"></stop>
								</linearGradient>
								<linearGradient id="toprect1_linear" x1="219" y1="137.331" x2="1.5643" y2="86.8415" gradientUnits="userSpaceOnUse">
									<stop stopColor="#783FF1" stopOpacity="0"></stop>
									<stop offset="1" stopColor="#783FF1"></stop>
								</linearGradient>
							</defs>
						</svg>
						<TextContainer>
							<div className="bannerTextContent">
								<div className="DisplayText-ls">
									<p className="Polaris-DisplayText Polaris-DisplayText--sizeLarge"> Interact face-to-face with your audience</p>
								</div>
								<div className="spacing"></div>
								<p className="DisplayText-font-size">Let Your Interactive Video Do the Talking!</p>
							</div>
						</TextContainer>
					</div>
				</Layout.Section>
				<Layout.Section>
					<div className='loginForm'>
						<div className='loginInnerWrap registerLoginInner'>
							<div className="logo">
								<a href='https://muqala.com' target={"_blank"} className="d-block">
									<img src="https://storage.googleapis.com/speechvid/muqala_logo_black_yello.png" alt="Muqala Logo" />
								</a>
							</div>
							<Heading element='h1' id="loginTitle">Register</Heading>
							<Form onSubmit={handleRegisterSubmit}>
								<FormLayout>
									<div className='gmailLoginButton'>										
										<Button onClick={() => Gmaillogin()}>
											<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
											<span> Sign up with Google </span>
											{connectGmailLoader &&
												<Spinner
													className="white mr-1"
													color="light"
													size="sm"
												/>
											}
										</Button>
										<div className="orOption">OR</div>
									</div>
									<TextField
										value={name}
										onChange={(e) => handleInputChange('name', e)}
										onBlur={(e) => handleInputBlur('name')}
										label="Name"
										type="text"
										name="name"
										error={typeof errors['name'] !== "undefined" ? errors['name'] : ""}
										placeholder='Name'
										className="form-control"
									/>
									<TextField
										value={email}
										onChange={(e) => handleInputChange('email', e)}
										onBlur={(e) => handleInputBlur('email')}
										label="Email Address"
										type="email"
										name="email"
										error={typeof errors['email'] !== "undefined" ? errors['email'] : ""}
										placeholder='Email Address'
										className="form-control"
									/>
									<div className='passwordWrapper'>
										<TextField
											value={password}
											onChange={(e) => handleInputChange('password', e)}
											onBlur={(e) => handleInputBlur('password')}
											label="Password"
											name="password"
											type={passwordShown ? "text" : "password"}
											error={typeof errors['password'] !== "undefined" ? errors['password'] : ""}
											placeholder='Password'
											className="form-control"
										/>
										<div className='passwordShowIcon' onClick={() => setPasswordShown(!passwordShown)}>
											{passwordShown ?
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18" fill="none"><path d="M11.73 4.60209C9.4845 4.60209 7.65775 6.42884 7.65775 8.67463C7.65775 10.9204 9.4845 12.7472 11.73 12.7472C13.9755 12.7472 15.8022 10.9201 15.8022 8.67463C15.8022 6.42913 13.9755 4.60209 11.73 4.60209ZM11.73 11.2881C10.289 11.2881 9.11681 10.1156 9.11681 8.67463C9.11681 7.23366 10.2893 6.06116 11.73 6.06116C13.1707 6.06116 14.3432 7.23366 14.3432 8.67463C14.3432 10.1156 13.171 11.2881 11.73 11.2881ZM23.1591 8.25151C20.3318 4.27877 16.1658 2 11.73 2C7.29415 2 3.12823 4.27877 0.300859 8.25151L0 8.67463L0.300859 9.09747C3.12794 13.0705 7.29386 15.3493 11.73 15.3493C16.1661 15.3493 20.3321 13.0705 23.1591 9.09747L23.46 8.67463L23.1591 8.25151ZM11.73 13.8902C7.9303 13.8902 4.33896 11.9963 1.80165 8.67463C4.33896 5.35293 7.9306 3.45906 11.73 3.45906C15.5294 3.45906 19.121 5.35293 21.6583 8.67463C19.121 11.9963 15.5297 13.8902 11.73 13.8902Z" fill="#17173A"></path></svg>
												:
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" fill="none"><path d="M16.8733 0.72943L15.6099 0L14.4336 2.03737C13.5497 1.84798 12.6448 1.75071 11.7284 1.75071C7.29313 1.75071 3.1278 4.02916 0.300817 8.00134L0 8.42441L0.300817 8.84719C2.16569 11.468 4.61323 13.3515 7.3366 14.3298L6.41908 15.919L7.68248 16.6484L16.8733 0.72943ZM8.07999 13.0422L9.00156 11.446C8.17615 10.7003 7.65668 9.62192 7.65668 8.42441C7.65668 6.17893 9.48317 4.35244 11.7284 4.35244C12.1659 4.35244 12.5875 4.42182 12.9829 4.55015L13.6616 3.37453C13.0262 3.26534 12.3803 3.20957 11.7284 3.20957C7.92949 3.20957 4.33836 5.10317 1.8014 8.42441C3.50456 10.6541 5.68271 12.2403 8.07999 13.0422ZM12.2271 5.85909C12.0656 5.82773 11.8989 5.8113 11.7284 5.8113C10.2879 5.8113 9.11554 6.98364 9.11554 8.42441C9.11554 9.07919 9.35761 9.67852 9.75696 10.1376L12.2271 5.85909ZM15.1566 6.22951L14.261 7.78073C14.3133 7.98671 14.3412 8.20237 14.3412 8.42441C14.3412 9.61967 13.5345 10.6302 12.437 10.9399L11.5408 12.4921C11.603 12.495 11.6655 12.4964 11.7284 12.4964C13.9735 12.4964 15.8 10.6696 15.8 8.42441C15.8 7.61679 15.5637 6.8633 15.1566 6.22951ZM11.7284 13.6393C11.4497 13.6393 11.1722 13.6291 10.8961 13.6089L10.0961 14.9945C10.6347 15.0632 11.1794 15.0981 11.7284 15.0981C16.1639 15.0981 20.3292 12.8197 23.1559 8.84719L23.4567 8.42441L23.1559 8.00134C21.5119 5.69133 19.4152 3.95414 17.0761 2.90476L16.3417 4.17686C18.3574 5.05172 20.1837 6.49789 21.6553 8.42441C19.1184 11.7457 15.5275 13.6393 11.7284 13.6393Z" fill="#C4C4CD" fillRule="evenodd" clipRule="evenodd"></path></svg>
											}
										</div>
									</div>
									{errorMsg &&
										<div className='mt-1 mb-1'>
											<InlineError message={errorMsg} fieldID="myFieldID" />
										</div>
									}
									<Button submit>
										{isLoading ?
											<>
												<Spinner accessibilityLabel="Small spinner example" size="small" /> Loading
											</>
											:
											<>Register</>
										}
									</Button>									
								</FormLayout>
							</Form>
						</div>
					</div>
				</Layout.Section>
			</Layout>
		</section>
	);

	// const loadingPageMarkup = (
	// 	<SkeletonPage>
	// 		<Layout>
	// 			<Layout.Section>
	// 				<Card sectioned>
	// 					<TextContainer>
	// 						<SkeletonDisplayText size="small" />
	// 						<SkeletonBodyText lines={9} />
	// 					</TextContainer>
	// 				</Card>
	// 			</Layout.Section>
	// 		</Layout>
	// 	</SkeletonPage>
	// );

	if (redirectFlag) {
		return <Navigate replace to="/user/" />
	}

	return (
		<div>
			<AppProvider
				i18n={{
					Polaris: {
						Avatar: {
							label: 'Avatar',
							labelWithInitials: 'Avatar with initials {initials}',
						},
						ContextualSaveBar: {
							save: 'Save',
							discard: 'Discard',
						},
						TextField: {
							characterCount: '{count} characters',
						},
						Modal: {
							iFrameTitle: 'body markup',
						},
						Frame: {
							skipToContent: 'Skip to content',
							navigationLabel: 'Navigation',
							Navigation: {
								closeMobileNavigationLabel: 'Close navigation',
							},
						},
					},
				}}
			>
				<Frame
					offset="0px"
				>
					{loadingMarkup}
					{actualPageMarkup}
					{toastMarkup}
				</Frame>
			</AppProvider>
		</div>
	);
};

export default Register;