import Login from "./views/pages/Login.jsx";
import VideoListing from "./views/pages/Video/VideoListing";
import Video from "./views/pages/Video/Video"
import Launcher from "./views/pages/Launcher.jsx";
import ForgotPassword from "./views/pages/ForgotPassword.jsx";
import ChangePassword from "./views/pages/ChangePassword.jsx";
import {MaximizeMajor, MobilePlusMajor, ConversationMinor, PlanMajor, HomeMinor, CategoriesMajor} from '@shopify/polaris-icons';
import Settings from "./views/pages/Settings.jsx";
import Logout from "./views/pages/Logout.jsx";
import Interactions from "./views/pages/Interactions/Interactions.jsx";
import VideoCreator from "./views/pages/Video/VideoCreator.jsx";
import Dashboard from "./views/pages/Dashboard"
import muqalaIcon from "./assests/images/muqala_icon.svg"
import Leads from "./views/pages/Leads/Leads.jsx";
import VideoFiles from "./views/pages/Video/VideoFiles.jsx";
import Register from "./views/pages/Register.jsx";
import ManageUsers from "./views/pages/Users/ManageUsers.jsx";
import AddUser from "./views/pages/Users/AddUser.jsx";
import EditUser from "./views/pages/Users/EditUser.jsx";

export const userRoute = [
	{
		path: "/",
		name: "Dashboard",
		icon: HomeMinor,
		component: <Dashboard />,
		layout: "/user",
		type: "MainMenu"
	},
	{
		path: "/video",
		name: 'Video Funnels',
		icon: PlanMajor,
		component: <VideoListing />,
		layout: "/user",
		//type: "MainMenu"
	},
	{
		path: "/video-files",
		name: "Video Library",
		icon: CategoriesMajor,
		component: <VideoFiles />,
		layout: "/user",
		type: "MainMenu"
	},
	{
		path: "/interaction",
		name: "All Interactions",
		icon: ConversationMinor,
		component: <Interactions />,
		layout: "/user",
		type: "MainMenu"
	},
	{
		path: "/leads",
		name: "Leads",
		component: <Leads />,
		icon: `<svg fill="none" height="22" width="29" xmlns="http://www.w3.org/2000/svg"><path xmlns="http://www.w3.org/2000/svg" d="M24.68 22c2.386 0 4.32-1.97 4.32-4.4V4.4C29 1.97 27.066 0 24.68 0h-4.24v2.494A2.206 2.206 0 0121.52 4.4c0 1.215-.967 2.2-2.16 2.2s-2.16-.985-2.16-2.2c0-.814.434-1.525 1.08-1.906V0H9.64v2.494A2.206 2.206 0 0110.72 4.4c0 1.215-.967 2.2-2.16 2.2S6.4 5.615 6.4 4.4c0-.814.434-1.525 1.08-1.906V0H4.32C1.934 0 0 1.97 0 4.4v13.2C0 20.03 1.934 22 4.32 22h20.36zM10.72 11c0 1.215-.967 2.2-2.16 2.2S6.4 12.215 6.4 11c0-1.215.967-2.2 2.16-2.2s2.16.985 2.16 2.2zm-2.16 2.2h-.154c-1.704 0-3.086 1.407-3.086 3.143 0 .694.553 1.257 1.234 1.257h4.012c.681 0 1.234-.563 1.234-1.257 0-1.736-1.382-3.143-3.086-3.143H8.56zm5.4-2.2c0-.607.484-1.1 1.08-1.1h7.56c.596 0 1.08.492 1.08 1.1 0 .258-.087.495-.233.683a1.07 1.07 0 01-.847.417h-7.56c-.204 0-.394-.057-.556-.157A1.107 1.107 0 0113.96 11zm0 4.4c0-.607.484-1.1 1.08-1.1h7.56c.596 0 1.08.493 1.08 1.1a1.119 1.119 0 01-.27.727 1.074 1.074 0 01-.81.373h-7.56a1.09 1.09 0 01-1.08-1.1z" fill="black" fill-rule="evenodd"  /></svg>`,
		layout: "/user",
		type: "MainMenu"
	},
	// {
	// 	path: "/video",
	// 	name: 'Video',
	// 	component: <Video />,
	// 	layout: "/user",
	// },
	{
		path: "/video/:video_id",
		name: 'Video',
		component: <Video />,
		layout: "/user",
	},
	{
		path: "/settings",
		name: "Settings",
		component: <Settings />,
		layout: "/user"
	},
	{
		path: "/manage-team",
		name: "Manage Team",
		component: <ManageUsers />,
		layout: "/user"
	},
	{
		path: "/add-user",
		name: "Add User",
		component: <AddUser />,
		layout: "/user"
	},
	{
		path: "/edit-user/:user_id",
		name: "Edit User",
		component: <EditUser />,
		layout: "/user"
	},
	{
		path: "/logout",
		name: "Logout",
		component: <Logout />,
		layout: "/user"
	}
	// {
	// 	path: "/launcher",
	// 	name: "Launcher",
	// 	icon: MaximizeMajor,
	// 	layout: '/user',
	// 	component: <Launcher />,
	// 	type: "MainMenu"

	// },
];

export const newVideoAskRoute	= [
	{
		path: "/video-creator",
		name: 'New Muqala',
		icon: MobilePlusMajor,
		component: <VideoCreator />,
		layout: "/user",
		type: "MainMenu"
	},
]

export const accountRoute = [
	{
		path: "/login",
		component: Login,
		layout: "/account"
	},
	{
		path: "/register",
		component: Register,
		layout: "/account"
	},
	{
		path: "/forgot-password",
		component: ForgotPassword,
		layout: "/account"
	},
	{
		path: '/change-password',
		component: ChangePassword,
		layout: "/account"
	}
];

