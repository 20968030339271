import StatusValidation from './Validation';
import Cookies from 'js-cookie'
const LiveURL			= "https://bend.muqala.com/"
const DevelopmentURL	= "https://qbend.converbot.com/"
const LocalURL			= "http://localhost:8081/"
const FinalURL			= LiveURL

export function PostBeforeLogin(type, userData) {
	return new Promise((resolve, reject) => {
		fetch(  FinalURL + type, {
			method: 'POST',
			body: JSON.stringify(userData),
			headers: { 'Content-Type': 'application/json' },
		})
			.then((response) => StatusValidation(response))
			.then((responseJson) => {
				resolve(responseJson);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export function PostData(type, userData, formData = false) {
	return new Promise((resolve, reject) => {
		fetch( FinalURL + type, {
			method: 'POST',
			body: formData ? userData : JSON.stringify(userData),
			headers: {
				'Content-Type': formData ? 'multipart/form-data' : 'application/json',
				'Authorization': 'Bearer ' + Cookies.get("_prod_token")
			},
		})
			.then((response) => StatusValidation(response))
			.then((responseJson) => {
				resolve(responseJson);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function PostFormData(type, formData) {
	return new Promise((resolve, reject) => {
		fetch( FinalURL + type, {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': 'Bearer ' + Cookies.get("_prod_token")
			},
		})
			.then((response) => StatusValidation(response))
			.then((responseJson) => {
				resolve(responseJson);
			})
			.catch((error) => {
				console.log(error)
				reject(error);
			});
	});
}

export function GetData(type) {
	return new Promise((resolve, reject) => {
		fetch( FinalURL + type, {
			method: 'Get',
			headers: {
				'Authorization': 'Bearer ' + Cookies.get("_prod_token")
			},
		})
			.then((response) => StatusValidation(response))
			.then((responseJson) => {
				resolve(responseJson);
			})
			.catch((error) => {
				reject(error);
			});
	});
}