import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AppProvider } from '@shopify/polaris';
import UserLayout from "./layouts/User.jsx";
import AccountLayout from "./layouts/Account.jsx";
import 'bootstrap/dist/css/bootstrap.css';
import "@shopify/polaris/build/esm/styles.css";
import './App.css';
import './assests/scss/index.scss'
import ProductProvider from './store/ProductProvider'

function App() {	
	return (
		<AppProvider>
			<ProductProvider>
				<BrowserRouter>
					<Routes>
						<Route path="/user/*" element={<UserLayout />} />
						<Route path="/account/*" element={<AccountLayout />} />
						<Route path="/" element={<Navigate to="/user/" replace />} />
					</Routes>
				</BrowserRouter>
			</ProductProvider>
		</AppProvider>
  );
}

export default App;
