import React, { useCallback, useRef, useState, useEffect } from 'react';
import { ActionList, AppProvider, Avatar, Banner, Button, Card, Checkbox, ContextualSaveBar, Form, FormLayout, Frame, Heading, Layout, Link, Loading, Modal, Navigation, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Spinner, TextContainer, TextField, Toast, TopBar } from '@shopify/polaris';
import { ArrowLeftMinor, ConversationMinor, HomeMajor, OrdersMajor } from '@shopify/polaris-icons';
import { PostBeforeLogin } from '../../services/PostData';
import Cookies from 'js-cookie'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

const ChangePassword = () => {
	useEffect(() => {
		document.title = "Muqala Video | Change Password"
		return () => {
			document.title = "Muqala Video"
		}
	}, [])

	const [toastActive, setToastActive]							= useState(false);
	const [isLoading, setIsLoading]								= useState(false);
	const [errors, setErrors]										= useState({})
	const [password, setPassword]									= useState("")
	const[confirmPassword, setConfirmPassword]				= useState("")
   const[confirmPasswordShown, setConfirmPasswordShown]	= useState(false)
	const[passwordShown, setPasswordShown]						= useState(false)
	const [passwordResponse, setPasswordResponse] 			= useState({})
	const token     													= useLocation().search.split(/=(.+)/)[1];
	const [userToken, setUserToken]								= useState(token);
	const [passwordFlag, setPasswordFlag]						= useState(false)

	const toggleToastActive = useCallback(
		() => setToastActive((toastActive) => !toastActive),
		[],
	);

	const handleInputChange = (fieldName, value) => {
		if (fieldName === "password") {
			setPassword(value)
			if( value === "" ){
				setErrors({...errors,password: "Please enter the password"})
			}else if( value.length < 6 ){
				setErrors({...errors,password: "Minimum six character needed"})
			}else{
				if( typeof errors.password !== "undefined" ){
					delete errors.password
				}
				setErrors(errors)
			}
		}else if( fieldName === "confirmPassword" ){
			setConfirmPassword(value)
			if( value === "" ){
				setErrors({...errors,confirmPassword: "Please enter the password"})
			}else if( value.length < 6 ){
				setErrors({...errors,confirmPassword: "Minimum six character needed"})
			}else if( value !== password ){
				setErrors({...errors,confirmPassword: "password doesn't match"})
			}else{
				if( typeof errors.confirmPassword !== "undefined" ){
					delete errors.confirmPassword
				}
				setErrors(errors)
			}
		}
		
	}

	const handleInputBlur = (fieldName) => {
		if (fieldName === "password") {
			if( password === "" ){
				setErrors({...errors,password: "Please enter the password"})
			}else if( password.length < 6 ){
				setErrors({...errors,password: "Minimum six character needed"})
			}else{
				if( typeof errors.password !== "undefined" ){
					delete errors.password
				}
				setErrors(errors)
			}
		}else if( fieldName === "confirmPassword" ){
			if( confirmPassword === "" ){
				setErrors({...errors,confirmPassword: "Please enter the password"})
			}else if( confirmPassword.length < 6 ){
				setErrors({...errors,confirmPassword: "Minimum six character needed"})
			}else if( confirmPassword !== password ){
				setErrors({...errors,confirmPassword: "password doesn't match"})
			}else{
				if( typeof errors.confirmPassword !== "undefined" ){
					delete errors.confirmPassword
				}
				setErrors(errors)
			}
		}
	}

	const handleLoginSubmit = () => {
		let errorFlag = true
		if( password === "" ){
			errorFlag = false
			setErrors({...errors,password: "Please enter the password"})
		}else if( password.length < 6 ){
			errorFlag = false
			setErrors({...errors,password: "Minimum six character needed"})
		}else{
			if( typeof errors.password !== "undefined" ){
				delete errors.password
			}
			setErrors(errors)
		}
		if( confirmPassword === "" ){
			setErrors({...errors,confirmPassword: "Please enter the password"})
		}else if( confirmPassword.length < 6 ){
			setErrors({...errors,confirmPassword: "Minimum six character needed"})
		}else if( confirmPassword !== password ){
			setErrors({...errors,confirmPassword: "password doesn't match"})
		}else{
			if( typeof errors.confirmPassword !== "undefined" ){
				delete errors.confirmPassword
			}
			setErrors(errors)
		}
		if (errorFlag) {
			setIsLoading(true)
			PostBeforeLogin("api/v1/user/change-password", { password: password, confirm: confirmPassword, user_token: userToken }).then(response => {
				if (response !== "Invalid") {
					setIsLoading(false)
					if (response.status === "success") {
						setPasswordFlag(true)
						setPasswordResponse({content: 'Password Update Successfully.', status: 'success', title: "" })
					}else{
						setPasswordResponse({content: response.message.message, status: 'critical', title: ""})
					}
				}
			})
		}
	}

	const dismissBanner = () => {
		setPasswordResponse({})
	}

	const toastMarkup = toastActive ? (
		<Toast onDismiss={toggleToastActive} content="Changes saved" />
	) : null;

	const loadingMarkup = isLoading ? <Loading /> : null;

	const actualPageMarkup = (
		<section className='LoginPage'>
			<Layout>
				<Layout.Section secondary>
					<div className='contentSection leftWing'>
						<div className="logo">
							<img src="https://storage.googleapis.com/speechvid/muqala/muqala_app_new_logo_white_black.png" alt="Muqala Logo" />
						</div>
						<svg className="loginLeftSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368 196" fill="none">
							<path opacity="0.08" d="M64 196L524 138.842V-8L64 49.1579V196Z" fill="url(#toprect0_linear)"></path>
							<path opacity="0.15" d="M0 189L219 161.841V80L0 107.159V189Z" fill="url(#toprect1_linear)"></path>
							<defs>
								<linearGradient id="toprect0_linear" x1="221.834" y1="-12.9538" x2="140.887" y2="68.5282" gradientUnits="userSpaceOnUse">
									<stop stopColor="#FFA105" stopOpacity="0"></stop>
									<stop offset="1" stopColor="#FFA105"></stop>
								</linearGradient>
								<linearGradient id="toprect1_linear" x1="219" y1="137.331" x2="1.5643" y2="86.8415" gradientUnits="userSpaceOnUse">
									<stop stopColor="#783FF1" stopOpacity="0"></stop>
									<stop offset="1" stopColor="#783FF1"></stop>
								</linearGradient>
							</defs>
						</svg>
						<TextContainer>
							<div className="bannerTextContent">
								<div className="DisplayText-ls">
									<p className="Polaris-DisplayText Polaris-DisplayText--sizeLarge">Refine Customer Experience At Every Touchpoint</p>
								</div>
								<div className="spacing"></div>
								<p className="DisplayText-font-size">The Complete Email and SMS Marketing Tool</p>
							</div>
						</TextContainer>
					</div>
				</Layout.Section>
				<Layout.Section>
					<div className='loginForm'>
						<div className='loginInnerWrap'>
							<Heading element='h1' id="loginTitle">Change Password</Heading>
							{Object.keys(passwordResponse).length != 0 &&
								<div className='mb-1'>
									<Banner
										title={passwordResponse.title ? passwordResponse.title : ''}
										status={passwordResponse.status}									
										onDismiss={() => dismissBanner()}
									>
										<p>{passwordResponse.content}</p>
									</Banner>
								</div>
							}
							{!passwordFlag ?
								<Form onSubmit={handleLoginSubmit}>
									<FormLayout>
													<div className='passwordWrapper'>
											<TextField
												value={password}
												onChange={(e) => handleInputChange('password', e)}
												onBlur={(e) => handleInputBlur('password')}
												label="New Password"
												name="password"
												type={passwordShown ? "text" : "password"}
												error={typeof errors['password'] !== "undefined" ? errors['password'] : ""}
												placeholder='Password'
												className="form-control"
											/>
											<div className='passwordShowIcon' onClick={() => setPasswordShown(!passwordShown)}>
												{ passwordShown ?
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18" fill="none"><path d="M11.73 4.60209C9.4845 4.60209 7.65775 6.42884 7.65775 8.67463C7.65775 10.9204 9.4845 12.7472 11.73 12.7472C13.9755 12.7472 15.8022 10.9201 15.8022 8.67463C15.8022 6.42913 13.9755 4.60209 11.73 4.60209ZM11.73 11.2881C10.289 11.2881 9.11681 10.1156 9.11681 8.67463C9.11681 7.23366 10.2893 6.06116 11.73 6.06116C13.1707 6.06116 14.3432 7.23366 14.3432 8.67463C14.3432 10.1156 13.171 11.2881 11.73 11.2881ZM23.1591 8.25151C20.3318 4.27877 16.1658 2 11.73 2C7.29415 2 3.12823 4.27877 0.300859 8.25151L0 8.67463L0.300859 9.09747C3.12794 13.0705 7.29386 15.3493 11.73 15.3493C16.1661 15.3493 20.3321 13.0705 23.1591 9.09747L23.46 8.67463L23.1591 8.25151ZM11.73 13.8902C7.9303 13.8902 4.33896 11.9963 1.80165 8.67463C4.33896 5.35293 7.9306 3.45906 11.73 3.45906C15.5294 3.45906 19.121 5.35293 21.6583 8.67463C19.121 11.9963 15.5297 13.8902 11.73 13.8902Z" fill="#17173A"></path></svg>												
												:
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" fill="none"><path d="M16.8733 0.72943L15.6099 0L14.4336 2.03737C13.5497 1.84798 12.6448 1.75071 11.7284 1.75071C7.29313 1.75071 3.1278 4.02916 0.300817 8.00134L0 8.42441L0.300817 8.84719C2.16569 11.468 4.61323 13.3515 7.3366 14.3298L6.41908 15.919L7.68248 16.6484L16.8733 0.72943ZM8.07999 13.0422L9.00156 11.446C8.17615 10.7003 7.65668 9.62192 7.65668 8.42441C7.65668 6.17893 9.48317 4.35244 11.7284 4.35244C12.1659 4.35244 12.5875 4.42182 12.9829 4.55015L13.6616 3.37453C13.0262 3.26534 12.3803 3.20957 11.7284 3.20957C7.92949 3.20957 4.33836 5.10317 1.8014 8.42441C3.50456 10.6541 5.68271 12.2403 8.07999 13.0422ZM12.2271 5.85909C12.0656 5.82773 11.8989 5.8113 11.7284 5.8113C10.2879 5.8113 9.11554 6.98364 9.11554 8.42441C9.11554 9.07919 9.35761 9.67852 9.75696 10.1376L12.2271 5.85909ZM15.1566 6.22951L14.261 7.78073C14.3133 7.98671 14.3412 8.20237 14.3412 8.42441C14.3412 9.61967 13.5345 10.6302 12.437 10.9399L11.5408 12.4921C11.603 12.495 11.6655 12.4964 11.7284 12.4964C13.9735 12.4964 15.8 10.6696 15.8 8.42441C15.8 7.61679 15.5637 6.8633 15.1566 6.22951ZM11.7284 13.6393C11.4497 13.6393 11.1722 13.6291 10.8961 13.6089L10.0961 14.9945C10.6347 15.0632 11.1794 15.0981 11.7284 15.0981C16.1639 15.0981 20.3292 12.8197 23.1559 8.84719L23.4567 8.42441L23.1559 8.00134C21.5119 5.69133 19.4152 3.95414 17.0761 2.90476L16.3417 4.17686C18.3574 5.05172 20.1837 6.49789 21.6553 8.42441C19.1184 11.7457 15.5275 13.6393 11.7284 13.6393Z" fill="#C4C4CD" fillRule="evenodd" clipRule="evenodd"></path></svg>
												}
											</div>
										</div>
													<div className='passwordWrapper'>
											<TextField
												value={confirmPassword}
												onChange={(e) => handleInputChange('confirmPassword', e)}
												onBlur={(e) => handleInputBlur('confirmPassword')}
												label="Confirm Password"
												name="confirmpassword"
												type={confirmPasswordShown ? "text" : "password"}
												error={typeof errors['confirmPassword'] !== "undefined" ? errors['confirmPassword'] : ""}
												placeholder='Password'
												className="form-control"
											/>
											<div className='passwordShowIcon' onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}>
												{ confirmPasswordShown ?
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18" fill="none"><path d="M11.73 4.60209C9.4845 4.60209 7.65775 6.42884 7.65775 8.67463C7.65775 10.9204 9.4845 12.7472 11.73 12.7472C13.9755 12.7472 15.8022 10.9201 15.8022 8.67463C15.8022 6.42913 13.9755 4.60209 11.73 4.60209ZM11.73 11.2881C10.289 11.2881 9.11681 10.1156 9.11681 8.67463C9.11681 7.23366 10.2893 6.06116 11.73 6.06116C13.1707 6.06116 14.3432 7.23366 14.3432 8.67463C14.3432 10.1156 13.171 11.2881 11.73 11.2881ZM23.1591 8.25151C20.3318 4.27877 16.1658 2 11.73 2C7.29415 2 3.12823 4.27877 0.300859 8.25151L0 8.67463L0.300859 9.09747C3.12794 13.0705 7.29386 15.3493 11.73 15.3493C16.1661 15.3493 20.3321 13.0705 23.1591 9.09747L23.46 8.67463L23.1591 8.25151ZM11.73 13.8902C7.9303 13.8902 4.33896 11.9963 1.80165 8.67463C4.33896 5.35293 7.9306 3.45906 11.73 3.45906C15.5294 3.45906 19.121 5.35293 21.6583 8.67463C19.121 11.9963 15.5297 13.8902 11.73 13.8902Z" fill="#17173A"></path></svg>												
												:
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" fill="none"><path d="M16.8733 0.72943L15.6099 0L14.4336 2.03737C13.5497 1.84798 12.6448 1.75071 11.7284 1.75071C7.29313 1.75071 3.1278 4.02916 0.300817 8.00134L0 8.42441L0.300817 8.84719C2.16569 11.468 4.61323 13.3515 7.3366 14.3298L6.41908 15.919L7.68248 16.6484L16.8733 0.72943ZM8.07999 13.0422L9.00156 11.446C8.17615 10.7003 7.65668 9.62192 7.65668 8.42441C7.65668 6.17893 9.48317 4.35244 11.7284 4.35244C12.1659 4.35244 12.5875 4.42182 12.9829 4.55015L13.6616 3.37453C13.0262 3.26534 12.3803 3.20957 11.7284 3.20957C7.92949 3.20957 4.33836 5.10317 1.8014 8.42441C3.50456 10.6541 5.68271 12.2403 8.07999 13.0422ZM12.2271 5.85909C12.0656 5.82773 11.8989 5.8113 11.7284 5.8113C10.2879 5.8113 9.11554 6.98364 9.11554 8.42441C9.11554 9.07919 9.35761 9.67852 9.75696 10.1376L12.2271 5.85909ZM15.1566 6.22951L14.261 7.78073C14.3133 7.98671 14.3412 8.20237 14.3412 8.42441C14.3412 9.61967 13.5345 10.6302 12.437 10.9399L11.5408 12.4921C11.603 12.495 11.6655 12.4964 11.7284 12.4964C13.9735 12.4964 15.8 10.6696 15.8 8.42441C15.8 7.61679 15.5637 6.8633 15.1566 6.22951ZM11.7284 13.6393C11.4497 13.6393 11.1722 13.6291 10.8961 13.6089L10.0961 14.9945C10.6347 15.0632 11.1794 15.0981 11.7284 15.0981C16.1639 15.0981 20.3292 12.8197 23.1559 8.84719L23.4567 8.42441L23.1559 8.00134C21.5119 5.69133 19.4152 3.95414 17.0761 2.90476L16.3417 4.17686C18.3574 5.05172 20.1837 6.49789 21.6553 8.42441C19.1184 11.7457 15.5275 13.6393 11.7284 13.6393Z" fill="#C4C4CD" fillRule="evenodd" clipRule="evenodd"></path></svg>
												}
											</div>
										</div>
										<Button submit>
											{isLoading ?
												<>
													<Spinner accessibilityLabel="Small spinner example" size="small" /> Loading
												</>
												:
												<>Update Password</>
											}
										</Button>
									</FormLayout>
								</Form>							
							:
								<div className='text-center'>
									<Link url='/account/login' id='loginButton' removeUnderline={true}>LOGIN</Link>
								</div>
							}
						</div>
					</div>
				</Layout.Section>
			</Layout>
		</section>
	);

	if( typeof userToken === "undefined" || userToken === "" ){
		<Navigate replace to="/accounts/login" />
	}

	return (
		<div>
			<AppProvider
				i18n={{
					Polaris: {
						Avatar: {
							label: 'Avatar',
							labelWithInitials: 'Avatar with initials {initials}',
						},
						ContextualSaveBar: {
							save: 'Save',
							discard: 'Discard',
						},
						TextField: {
							characterCount: '{count} characters',
						},
						Modal: {
							iFrameTitle: 'body markup',
						},
						Frame: {
							skipToContent: 'Skip to content',
							navigationLabel: 'Navigation',
							Navigation: {
								closeMobileNavigationLabel: 'Close navigation',
							},
						},
					},
				}}
			>
				<Frame
					offset="0px"
				>
					{loadingMarkup}
					{actualPageMarkup}
					{toastMarkup}
				</Frame>
			</AppProvider>
		</div>
	);
};

export default ChangePassword;