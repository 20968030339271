import { Autocomplete, Icon, Modal, Spinner } from '@shopify/polaris';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import VideoSkeleton from '../../../components/VideoSkeleton';
import { PostData, PostFormData } from '../../../services/PostData';
import ReactDOM from 'react-dom';
import { ProductContext } from '../../../store';
import { SearchMinor } from '@shopify/polaris-icons';
import { useMemo } from 'react';
import { useCallback } from 'react';

const VideoCreator = (props) => {
   const [createPopup, setCreatePopup]											= useState(true)
	const [videoOption, setVideoOption]											= useState("upload")
	const [videoLibrary, setVideoLibrary]										= useState([])
	const [videoUploadedFlag, setVideoUploadedFlag]							= useState(false)
	const [blobURL, setBlobURL]													= useState("")
	const [videoUploadLoader, setVideoUploadLoader]							= useState(false)
	const [videoLibrarySelectVideo, setVideoLibrarySelectVideo]			= useState("")
	const [VideoLibrarySelectedLoader, setVideoLibrarySelectedLoader]	= useState(false)
	const [videoLibraryLoader, setVideoLibraryLoader]						= useState(false)
	const [fileUploaded, setFileUploaded]										= useState("");
	const [videoId, setVideoId]													= useState("")
	const [redirectDetailPage, setRedirectDetailPage]						= useState(false)
	const [hasWebcam, setHasWebCam]												= useState(false)
	const [hasMicroPhone, setHasMicroPhone]									= useState(false)
	const [hasCameraPermission, setHasCameraPermission] 					= useState("prompt")
	const [hasMicroPhonePermission, setHasMicroPhonePermission]			= useState("prompt")
	const [audioDeviceInput, setAudioDeviceInput]							= useState({})
	const [videoDeviceInput, setVideoDeviceInput]							= useState({})
	const [selectedAudioDevice, setSelectedAudioDevice]					= useState("")
	const [selectedVideoDevice, setSelectedVideoDevice]					= useState("")
	const [videoOptionModal, setVideoOptionModal]							= useState(false)
	const [audioOptionModal, setAudioOptionModal]							= useState(false)
	const [mirrorImageFlag, setMirrorImageFlag]								= useState(false)
	const [verticalFlag, setVerticalFlag]										= useState(false)
	const [audioLevel, setAudioLevel]											= useState(1)
	const [recordingFlag, setRecordingFlag]									= useState(false)
	const [recordCounterFlag, setRecordCounterFlag] 						= useState(false)
	const [previewVideoRecorder, setPreviewVideoRecorder]					= useState(false)
	const [videoRecordMutedFlag, setVideoRecordMutedFlag]					= useState(false)
	const inputFileRef																= useRef(null)
	const videoStreamRef 															= useRef(null)
	const videoOptionRef 															= useRef(null)
	const audioOptionRef 															= useRef(null)
	const [videoStream, setVideoStream] 										= useState(null)
	const [mediaRecorder, setMediaRecorder] 									= useState(null)
	const [videoInputOption, setVideoInputOption]							= useState("")
	const [audioInputOption, setAudioInputOption]							= useState("")
	const [videoRecordBlob, setVideoRecordBlob]								= useState(null)
	const [videoRecordLoader, setVideoRecordLoader]							= useState(false);
	const navigate																		= useNavigate()
	const recordTimerInterval														= useRef(null)
	const recordTimerInnerRef														= useRef(null)
	const [recordTimerFlag, setRecordTimerFlag]								= useState(false)
	var videoRecordTimer																= 0
	const [videoFolderId, setVideoFolderId]									= useState("");
	const {
		setLoadSideMenu,
		newVideoFolderId,
		setNewVideoFolderId
	} = useContext(ProductContext)
	var recordCounter	= 3,
	clearIntervalRecording = "",
	blobsRecorded = [],
	volumeInterval = null;

	useEffect(() => {
		if( newVideoFolderId ){
			setVideoFolderId(newVideoFolderId);
			setNewVideoFolderId(null)
		}
	}, [newVideoFolderId])

	const deselectedOptions = useMemo(
		() => [],
		[],
	 );
	 const [selectedOptions, setSelectedOptions] = useState([]);
	 const [inputValue, setInputValue] = useState('');
	 const [optionsH, setOptionsH] = useState(deselectedOptions);
  
	 const updateText = useCallback(
		(value) => {
		  setInputValue(value);
  
		  if (value === '') {
			 setOptionsH(deselectedOptions);
			 return;
		  }
  
		  const filterRegex = new RegExp(value, 'i');
		  const resultOptions = deselectedOptions.filter((option) =>
			 option.label.match(filterRegex),
		  );
		  setOptionsH(resultOptions);
		},
		[deselectedOptions],
	 );
  
	 const updateSelection = useCallback(
		(selected) => {
		  const selectedValue = selected.map((selectedItem) => {
			 const matchedOption = optionsH.find((option) => {
				return option.value.match(selectedItem);
			 });
			 return matchedOption && matchedOption.label;
		  });
  
		  setSelectedOptions(selected);
		  setInputValue(selectedValue[0] || '');
		},
		[optionsH],
	 );
  
	 const textField = (
		<Autocomplete.TextField
		  onChange={updateText}
		  label=""
		  value={inputValue}
		  prefix={<Icon source={SearchMinor} color="base" />}
		  placeholder="Search"
		  autoComplete="off"
		/>
	 );
	
	useEffect(() => {
		getUserVideoLibrary()
		checkPermissions()
		document.addEventListener("mousedown", handleClickOutside)
		document.title	= "Videos | Muqala App"
		return () => {
			document.title	= "Muqala App"
			document.removeEventListener("mousedown", handleClickOutside);
			turnOffVideoCamera()
			if (clearIntervalRecording) {
				clearInterval(clearIntervalRecording)
			}
		}
	},[])

	useEffect(() => {
		if( videoInputOption === "changed" ){
			setVideoInputOption("")
			turnOffVideoCamera()
			turnOnVideoCamera()
		}
	},[videoInputOption])

	useEffect(() => {
		if( audioInputOption === "changed" ){
			setAudioInputOption("")
			turnOffVideoCamera()
			turnOnVideoCamera()
		}
	},[audioInputOption])

	const handleClickOutside = (event) => {
		if (videoOptionRef && videoOptionRef.current && !videoOptionRef.current.contains(event.target)) {
			setVideoOptionModal(false)
		}		
		if (audioOptionRef && audioOptionRef.current && !audioOptionRef.current.contains(event.target)) {
			setAudioOptionModal(false)
		}
	}

	const checkPermissions = () => {
		if (!navigator.mediaDevices?.enumerateDevices) {
			console.log("enumerateDevices() not supported.");
		} else {
			// List cameras and microphones.
			var hasWebcamLocal	= false,
			hasMicroPhoneLocal	= false;
			navigator.mediaDevices.enumerateDevices().then((devices) => {
				devices.forEach((device) => {
					if (device.kind === "videoinput") {
						hasWebcamLocal = true
						setHasWebCam(hasWebcamLocal)
					} else if (device.kind === "audioinput") {
						hasMicroPhoneLocal = true
						setHasMicroPhone(hasMicroPhoneLocal)
					}
				});
				if (hasWebcamLocal && hasMicroPhoneLocal) {
					setVideoOption("record")
					setVideoInputOption("changed")
				}
			}).catch((err) => {
				console.error(`${err.name}: ${err.message}`);
			});
		}

		navigator.permissions.query({ name: 'camera' }).then((result) => {
			if (result.state === 'granted') {
				setHasCameraPermission("granted")
			} else if (result.state === 'prompt') {
				setHasCameraPermission("prompt")
			} else {
				setHasCameraPermission("denied")
			}
		});

		navigator.permissions.query({ name: 'microphone' }).then((result) => {
			if (result.state === 'granted') {
				setHasMicroPhonePermission("granted")
			} else if (result.state === 'prompt') {
				setHasMicroPhonePermission("prompt")
			} else {
				setHasMicroPhonePermission("denied")
			}
		});
	}

	const getDeviceInfo = () => {
		let selectedVideoDeviceLocal	= selectedVideoDevice,
		selectedAudioDeviceLocal		= selectedAudioDevice,
		videoDeviceInputLocal			= videoDeviceInput,
		audioDeviceInputLocal			= audioDeviceInput;
		navigator.mediaDevices.enumerateDevices().then((devices) => {
			devices.forEach((device) => {
				if (device.kind === "videoinput") {
					if (selectedVideoDeviceLocal === "") {
						selectedVideoDeviceLocal = device.label
					}
					if (typeof videoDeviceInputLocal[device.label] === "undefined") {
						videoDeviceInputLocal[device.label] = device.deviceId
					}
				} else if (device.kind === "audioinput") {
					if (selectedAudioDeviceLocal === "") {
						selectedAudioDeviceLocal = device.label
					}
					if (typeof audioDeviceInputLocal[device.label] === "undefined") {
						audioDeviceInputLocal[device.label] = device.deviceId
					}
				}
			});
			setSelectedVideoDevice(selectedVideoDeviceLocal)
			setVideoDeviceInput(videoDeviceInputLocal)
			setSelectedAudioDevice(selectedAudioDeviceLocal)
			setAudioDeviceInput(audioDeviceInputLocal)
		}).catch((err) => {
			console.error(`${err.name}: ${err.message}`);
		});
	}

	const turnOnVideoCamera = () => {
		let selectedVideoDeviceLocal	= selectedVideoDevice,
		selectedAudioDeviceLocal		= selectedAudioDevice,
		videoDeviceInputLocal			= videoDeviceInput,
		audioDeviceInputLocal			= audioDeviceInput;
		let constraint = { audio: { echoCancellation: true }, video: true };
		if (selectedAudioDeviceLocal !== "" && typeof audioDeviceInputLocal[selectedAudioDeviceLocal] !== "undefined") {
			constraint.audio.deviceId = { exact: audioDeviceInputLocal[selectedAudioDeviceLocal] }
		}
		if (selectedVideoDeviceLocal !== "" && typeof videoDeviceInputLocal[selectedVideoDeviceLocal] !== "undefined") {
			constraint.video = { deviceId: { exact: videoDeviceInputLocal[selectedVideoDeviceLocal] } }
		}
		const permissions = navigator.mediaDevices.getUserMedia(constraint)
		permissions.then((stream) => {
			getDeviceInfo()
			setHasCameraPermission("granted")
			setHasMicroPhonePermission("granted")
			videoStreamRef.current.srcObject = stream
			setVideoStream(stream);
			const audioContext = new AudioContext();
			const audioSource = audioContext.createMediaStreamSource(stream);
			const analyser = audioContext.createAnalyser();
			analyser.fftSize = 512;
			analyser.minDecibels = -127;
			analyser.maxDecibels = 0;
			analyser.smoothingTimeConstant = 0.4;
			audioSource.connect(analyser);
			const volumes = new Uint8Array(analyser.frequencyBinCount);
			const volumeCallback = () => {
				analyser.getByteFrequencyData(volumes);
				let volumeSum = 0;
				for (const volume of volumes)
					volumeSum += volume;
				const averageVolume = volumeSum / volumes.length;
				const audioLevel = Math.round((averageVolume * 100 / 127) / 10)
				setAudioLevel(audioLevel)
			};
			volumeInterval = setInterval(volumeCallback, 100);
		}).catch((err) => {
			if (hasCameraPermission === "prompt") {
				setHasCameraPermission("denied")
			}
			if (hasMicroPhonePermission === "prompt") {
				setHasMicroPhonePermission("denied")
			}
		});
	}

	const flipedVideoStream = (stream) => {
		const canvas = document.createElement("canvas");
		Object.assign(canvas, { width: 0, height: 0 });
		const ctx = canvas.getContext("2d");
		const track = stream.getVideoTracks()[0];
		const drawOnCanvas = (image, width, height) => {
			// MediaStream's video size may change over time
			if (canvas.width !== width || canvas.height !== height) {
				canvas.width = width;
				canvas.height = height;
				ctx.setTransform(-1, 0, 0, 1, width, 0);
			}
			ctx.clearRect(0, 0, width, height);
			ctx.drawImage(image, 0, 0);
		};
		// the MediaStreamTrackProcessor API is available, we use it
		if (window.MediaStreamTrackProcessor) {
			const processor = new window.MediaStreamTrackProcessor(track);
			const reader = processor.readable.getReader();
			reader.read().then(function readChunk({ done, value }) {
				if (typeof value !== "undefined") {
					const { displayWidth, displayHeight } = value;
					drawOnCanvas(value, displayWidth, displayHeight);
					value.close(); // close the VideoFrame when we're done with it
				}
				if (!done) {
					reader.read().then(readChunk);
				}
			});
		} else {
			const vid = document.createElement("video");
			vid.srcObject = stream;
			// in case requestVideoFrameCallback is available, we use it
			// otherwise we fallback on rAF
			const scheduler = vid.requestVideoFrameCallback ?
				(cb) => vid.requestVideoFrameCallback(cb) : requestAnimationFrame;
			const draw = () => {
				const { videoWidth, videoHeight } = vid;
				drawOnCanvas(vid, videoWidth, videoHeight);
				scheduler(draw);
			};
			vid.play().then(draw);
		}
		var canvaStream = canvas.captureStream()
		var audioTrack = stream.getTracks().filter(function (track) {
			return track.kind === 'audio'
		})[0];
		canvaStream.addTrack(audioTrack);
		return canvaStream;
	}

	const turnOffVideoCamera = () => {
		if (videoStream) {
			const tracks = videoStream.getTracks();
			tracks.forEach(track => track.stop())
		}
		if (volumeInterval) {
			clearInterval(volumeInterval)
			volumeInterval = null
		}
	}

	const getUserVideoLibrary = () => {
		PostData('api/v1/getUserVideoLibrary').then(result => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					setVideoLibrary(result.data.video_library)
					setVideoLibraryLoader(false)
				}
			}
		})
	}

	const toggleCreatePopup	= () => {
		if( createPopup ){
			navigate(-1)
		}
		if (!createPopup && videoOption === "record" ) {
			turnOnVideoCamera()
		} else if ( createPopup &&  videoOption === "record" &&  videoStream !== "") {
			turnOffVideoCamera()
			if( recordTimerInterval && recordTimerInterval.current ){
				videoRecordTimer	= 0
				clearInterval(recordTimerInterval.current)
				recordTimerInterval.current	= null
			}
			setRecordTimerFlag(false)
			if (videoStreamRef.current) {
				videoStreamRef.current.src = null
				videoStreamRef.current.muted = true
				setVideoRecordMutedFlag(false)
				setPreviewVideoRecorder(false)
				setRecordingFlag(false)
				setVideoRecordBlob("")
			}
		}
		setCreatePopup(!createPopup)
		setBlobURL("")
		setVideoUploadedFlag(false)
		setVideoLibrarySelectVideo("")
		setVideoLibrarySelectedLoader(false)
	}

	const changeVideoOption = (value) => {
		if (videoOption === "record" && value !== videoOption) {
			turnOffVideoCamera()
			if (videoStreamRef.current) {
				videoStreamRef.current.src = null
				videoStreamRef.current.muted = true
				setVideoRecordMutedFlag(false)
				setPreviewVideoRecorder(false)
				setRecordingFlag(false)
				setVideoRecordBlob("")
			}
		} else if (value === "record" && value !== videoOption) {
			turnOnVideoCamera()
		}
		if (value !== videoOption) {
			setVideoOption(value)
		}
	}

	const createUpload = () => {
		let inputFile = React.createElement('input', { type: 'file', name: 'myfile', id: 'myfile', ref: inputFileRef, accept: "video/*", onChange: (event) => {fileUpload(event) } })
		ReactDOM.render(
			inputFile,
			document.getElementById('upload-file')
		);
		inputFileRef.current.click()
	}

	const fileUpload = (event) => {
		let file = event.target.files[0];
		let blobURL = URL.createObjectURL(file);
		setBlobURL(blobURL)
		setVideoUploadedFlag(true)
		setFileUploaded(event.target.files[0])
		event.target.value = ""
	}
	
	const cancelFileUpload = () => {
		setBlobURL("")
		setVideoUploadedFlag(false)
	}

	const continueFileUpload = async () => {
		setVideoUploadLoader(true)
		var data = await new Promise((resolve, reject) => {
			// load the file to a video player
			const videoPlayer = document.createElement('video');
			videoPlayer.setAttribute('src', URL.createObjectURL(fileUploaded));
			videoPlayer.load();
			videoPlayer.addEventListener('error', (ex) => {
				reject("error when loading video file", ex);
			});
			// load metadata of the video to get video duration and dimensions
			videoPlayer.addEventListener('loadedmetadata', () => {
				// delay seeking or else 'seeked' event won't fire on Safari
				setTimeout(() => {
					videoPlayer.currentTime = 1;
				}, 200);
				// extract video thumbnail once seeking is complete
				videoPlayer.addEventListener('seeked', () => {
					const canvas = document.createElement("canvas");
					canvas.width = videoPlayer.videoWidth;
					canvas.height = videoPlayer.videoHeight;
					// draw the video frame to canvas
					const ctx = canvas.getContext("2d");
					ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
					// return the canvas image as a blob
					ctx.canvas.toBlob(
						blob => {
							// let url = URL.createObjectURL(blob);
							// resolve({dataUrl:url, duration: videoPlayer.duration})
							var reader = new FileReader();
							reader.onload = (event) => { resolve({ dataUrl: event.target.result, duration: videoPlayer.duration }) };
							reader.readAsDataURL(blob);
						},
						"image/jpeg",
						0.75 /* quality */
					);
				});
			});
		});
		let fileSize = fileUploaded?.size
		const formData = new FormData()
		formData.append('video_upload', fileUploaded)
		formData.append('duration', data.duration)
		formData.append('file_size', fileSize)
		formData.append('thumbnail_url', data.dataUrl)
		if( videoFolderId ){
			formData.append('folder_id', videoFolderId);
		}
		PostFormData("api/v1/createInteractiveVideo", formData).then((result) => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					setVideoUploadLoader(false)
					setVideoId(result.data.videoId)
					setRedirectDetailPage(true)
					setLoadSideMenu(true)
				}
			}
		})
	}

	const converDateTime = (val) => {
		const today = new Date();
		const endDate = new Date(val);
		const days = parseInt(Math.abs(today.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24));
		const hours = parseInt(Math.abs(today.getTime() - endDate.getTime()) / (1000 * 60 * 60) % 24);
		const minutes = parseInt(Math.abs(today.getTime() - endDate.getTime()) / (1000 * 60) % 60);
		const seconds = parseInt(Math.abs(today.getTime() - endDate.getTime()) / (1000) % 60);
		let date = "";
		if (days > 0) {
			date = days + " days ago";
		} else if (hours > 0) {
			date = hours + " hours ago";
		} else if (minutes > 0) {
			date = minutes + " minutes ago";
		} else if (seconds > 0) {
			date = seconds + " seconds ago";
		}
		return date;
	}

	const librarySelectedVideo = (video) => {
		setVideoLibrarySelectVideo(video.video_library_id)
		setVideoLibrarySelectedLoader(true)		
		let data = { "video_library_id": video.video_library_id}
		if( videoFolderId ){
			data.folder_id	= videoFolderId
		}
		PostData("api/v1/createVideoByVideoLibrary", data).then((result) => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					setVideoLibrarySelectedLoader(false)
					setVideoId(result.data.videoId)
					setRedirectDetailPage(true)
					setVideoLibrarySelectVideo("")
					setRedirectDetailPage(true)
					setLoadSideMenu(true)
				}
			}
		})
	}

	const selectedVideo = (video) => {
		setVideoId(video.video_id)
		setRedirectDetailPage(true)		
	}

	const changeVideoInputOption = (deviceLabel) => {
		let selectedVideoDeviceLocal	= selectedVideoDevice
		if (selectedVideoDeviceLocal !== deviceLabel) {
			setSelectedVideoDevice(deviceLabel)
			setVideoOptionModal(false)
			setVideoInputOption("Changed")
		}
	}

	const changeAudioOption = (deviceLabel) => {
		let selectedAudioDeviceLocal	= selectedAudioDevice
		if (selectedAudioDeviceLocal !== deviceLabel) {
			setSelectedAudioDevice(deviceLabel)
			setAudioOptionModal(false)
			setAudioInputOption("changed")
		}
	}

	const changeVideoPreview = (value) => {
		let verticalFlag = false
		if (value === 'mobile') {
			verticalFlag = true
		}
		setVerticalFlag(verticalFlag)
	}

	const startStopRecording = () => {
		if (!recordingFlag) {
			setRecordingFlag(true)
			setRecordCounterFlag(true)
			clearIntervalRecording = setInterval(interValRecording, 1000)
		} else {
			if( mediaRecorder ){
				mediaRecorder.stop()
			}
			setMirrorImageFlag(false)
			setPreviewVideoRecorder(true)
			setVideoRecordMutedFlag(true)
			videoStreamRef.current.muted = !videoStreamRef.current.muted
			if( recordTimerInterval && recordTimerInterval.current ){
				videoRecordTimer	= 0
				clearInterval(recordTimerInterval.current)
				recordTimerInterval.current	= null
			}
			setRecordTimerFlag(false)
		}
	}

	const interValRecording = () => {
		if (recordCounter <= 1) {
			clearInterval(clearIntervalRecording)
			setRecordCounterFlag(false)
			startStoreRecording()
		} else {
			recordCounter--;
			if( document.getElementById('recordCounterText') ){
				document.getElementById('recordCounterText').innerHTML	= recordCounter
			}
		}
	}

	const startStoreRecording = () => {
		if (videoStream) {
			let mediaStream = videoStream
			if (mirrorImageFlag) {
				mediaStream = flipedVideoStream(mediaStream)
			}
			videoRecordTimer	= 0
			setRecordTimerFlag(true)
			recordTimerInterval.current	= setInterval(videoRecordTimerFunc, 1000);
			if (mediaStream) {
				let mediaRecorderLocal = new MediaRecorder(mediaStream, { mimeType: 'video/webm' });
				setMediaRecorder(mediaRecorderLocal)
				mediaRecorderLocal.addEventListener('dataavailable', function (e) {
					blobsRecorded.push(e.data);
				});
				mediaRecorderLocal.addEventListener('stop', function () {
					if( typeof mediaRecorderLocal.deleteFlag === "undefined" || ( typeof mediaRecorderLocal.deleteFlag !== "undefined" && !mediaRecorderLocal.deleteFlag ) ){
						let videoLocalBlob = new Blob(blobsRecorded, { type: 'video/mp4' })
						let videoLocal = URL.createObjectURL(videoLocalBlob);
						videoStreamRef.current.srcObject = null
						videoStreamRef.current.src = videoLocal
						setVideoRecordBlob(videoLocalBlob)
						turnOffVideoCamera()
					} else {
						mediaRecorder.deleteFlag	= false
					}
					blobsRecorded = []
				});
				mediaRecorderLocal.start(1000);
			}
		}
	}

	const discardVideoRecording = () => {
		setRecordingFlag(false)
		mediaRecorder.deleteFlag	= true
		mediaRecorder.stop()
		setRecordTimerFlag(false)
		if( recordTimerInterval && recordTimerInterval.current ){
			videoRecordTimer	= 0
			clearInterval(recordTimerInterval.current)
			recordTimerInterval.current	= null
		}
	}

	const secondsToHourFunction	= () => {
		const result = new Date(videoRecordTimer * 1000).toISOString().slice(11, 19);
		return result
	}

	const videoRecordTimerFunc	= () => {
		videoRecordTimer++
		if( recordTimerInnerRef && recordTimerInnerRef.current ){
			recordTimerInnerRef.current.innerHTML	= secondsToHourFunction()
		}
	}

	const changeVideoRecordMuteUnmute = (event) => {
		event.stopPropagation()		
		if (videoStreamRef && videoStreamRef.current) {
			setVideoRecordMutedFlag(!videoRecordMutedFlag)
			videoStreamRef.current.muted = !videoStreamRef.current.muted
		}
	}

	const cancelVideoRecord = () => {
		if (videoStreamRef.current) {
			videoStreamRef.current.src = null
			videoStreamRef.current.muted = true
			setVideoRecordMutedFlag(false)
			setPreviewVideoRecorder(false)
			setRecordingFlag(false)
			setVideoInputOption("changed")
		}
	}

	const continueVideoRecord = async () => {
		setVideoRecordLoader(true)
		if (videoStreamRef.current) {
			videoStreamRef.current.muted	= true
			setVideoRecordMutedFlag(false)
		}
		var data = await new Promise((resolve, reject) => {
			// load the file to a video player
			const videoPlayer = document.createElement('video');
			videoPlayer.setAttribute('src', URL.createObjectURL(videoRecordBlob));
			videoPlayer.load();
			videoPlayer.addEventListener('error', (ex) => {
				reject("error when loading video file", ex);
			});
			// load metadata of the video to get video duration and dimensions
			videoPlayer.addEventListener('loadedmetadata', () => {
				// delay seeking or else 'seeked' event won't fire on Safari
				setTimeout(() => {
					videoPlayer.currentTime = 1;
				}, 200);
				// extract video thumbnail once seeking is complete
				videoPlayer.addEventListener('seeked', () => {
					const canvas = document.createElement("canvas");
					canvas.width = videoPlayer.videoWidth;
					canvas.height = videoPlayer.videoHeight;
					// draw the video frame to canvas
					const ctx = canvas.getContext("2d");
					ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
					// return the canvas image as a blob
					ctx.canvas.toBlob(
						blob => {
							// let url = URL.createObjectURL(blob);
							// resolve({dataUrl:url, duration: videoPlayer.duration})
							var reader = new FileReader();
							reader.onload = (event) => { resolve({ dataUrl: event.target.result, duration: videoPlayer.duration }) };
							reader.readAsDataURL(blob);
						},
						"image/jpeg",
						0.75 /* quality */
					);
				});
			});
		});
		let fileSize = videoRecordBlob?.size
		const formData = new FormData()
		formData.append('video_upload', videoRecordBlob)
		formData.append('duration', videoStreamRef.current.duration)
		formData.append('file_size', fileSize)
		formData.append('thumbnail_url', data.dataUrl)
		if( videoFolderId ){
			formData.append('folder_id', videoFolderId);
		}
		PostFormData("api/v1/createInteractiveVideo", formData).then((result) => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					setVideoId(result.data.videoId)
					setRedirectDetailPage(true)
					setLoadSideMenu(true)
				}
			}
			setVideoRecordLoader(false)
		})
	}

	if ( redirectDetailPage && videoId) {
		return <Navigate replace to={"/user/video/"+videoId} />
	}
	
    return (
        <div>
            <Modal
				instant
				large
				open={createPopup}
				onClose={toggleCreatePopup}
				className="createPopupLarge"
			>
				<Modal.Section>
					<div className="row">
						<div className="col-auto videoRightArea">
							<h3>Create Interactive Video</h3>
							<ul>
								{(hasWebcam && hasMicroPhone) &&
									<li className={videoOption === "record" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('record')}>
										<span className="material-icons-outlined">videocam</span> Record video
									</li>
								}
								<li className={videoOption === "upload" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('upload')}>
								<span className="material-icons-outlined">file_upload</span> Upload File
								</li>
								<li className={videoOption === "library" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('library')}>
									<span className="material-icons-outlined">video_library</span> Library
								</li>
								<li className={videoOption === "screen" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('screen')}>
									<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 0H2C.875 0 0 .906 0 2v9c0 1.125.875 2 2 2h4.625l-.313 1.5H4.75a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h8.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75h-1.594l-.312-1.5H16c1.094 0 2-.875 2-2V2c0-1.094-.906-2-2-2Zm-5.875 14.5H7.844l.312-1.5h1.656l.313 1.5ZM16.5 11c0 .281-.25.5-.5.5H2a.494.494 0 0 1-.5-.5V2c0-.25.219-.5.5-.5h14c.25 0 .5.25.5.5v9Z" fill="#50545E"></path></svg> &nbsp; &nbsp;Record Screen
								</li>
								<li className={videoOption === "stock" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('stock')}>
									<svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.625 15.125V3.25C1.57 3.25.75 4.11.75 5.125V15.75c0 1.406 1.094 2.5 2.5 2.5h14.375c1.016 0 1.875-.82 1.875-1.875H3.875c-.703 0-1.25-.547-1.25-1.25ZM20.75.75H7a2.492 2.492 0 0 0-2.5 2.5V12c0 1.406 1.094 2.5 2.5 2.5h13.75c1.367 0 2.5-1.094 2.5-2.5V3.25c0-1.367-1.133-2.5-2.5-2.5ZM8.875 12.625H7A.617.617 0 0 1 6.375 12v-1.563h2.5v2.188Zm0-4.063h-2.5V6.688h2.5v1.875Zm0-3.75h-2.5V3.25c0-.313.273-.625.625-.625h1.875v2.188ZM17 12.625h-6.25V8.562H17v4.063Zm0-5.938h-6.25V2.625H17v4.063ZM21.375 12a.642.642 0 0 1-.625.625h-1.875v-2.188h2.5V12Zm0-3.438h-2.5V6.688h2.5v1.875Zm0-3.75h-2.5V2.626h1.875c.313 0 .625.313.625.625v1.563Z" fill="#50545E"></path></svg> &nbsp; &nbsp;Stock Videos
								</li>
							</ul>
						</div>
						<div className={`videoLeftArea col ${videoOption === "library" ? "libraryLeft" : ""}`}>
							<div>
								{videoOption === "upload" ?
									<>
										{videoUploadedFlag ?
											<div className="videoNewUploadWrapper">
												<video id="VideoPlayer" muted loop autoPlay playsinline="" disablepictureinpicture="" controls="" className="BaseVideoSize-sc-cvf9gr-0 UploadFile__Video-sc-1th8vxy-2 dKMFnV kUaiBp" src={blobURL}></video>
												{videoUploadLoader ?
													<div className="loaderWrapper">
														<Spinner accessibilityLabel="Spinner example" size="large" />
													</div>
													:
													<div className="innerOptionWrapper">
														<div className="d-flex align-items-center optionTextValueWrapper">
															<div className="optionText">
																Use this video?
															</div>
															<div className="optionValueWrapper">
																<div className="noOptionValue" onClick={() => cancelFileUpload()}>
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18">
																		<path fill="#0E1658" d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
																	</svg>
																</div>
																<div className="yesOptionValue" onClick={() => continueFileUpload()}>
																	<svg width="20" height="16" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M16.313 3.348a1.4 1.4 0 0 0 0-2.004c-.563-.528-1.442-.528-1.97 0L6.329 9.359 2.812 5.844c-.562-.528-1.44-.528-1.968 0a1.4 1.4 0 0 0 0 2.004l4.5 4.5c.246.28.633.421.984.421.352 0 .703-.14.984-.421l9-9Z" fill="#01BE81"></path>
																	</svg>
																</div>
															</div>
														</div>
													</div>
												}

											</div>
											:
											<>
												<h3 className="mt-4 mb-4 text-center">Upload file</h3>
												<div className="uploadWrapper cursorPointer" onClick={() => createUpload()}>
													<div className="text-center">
														<svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M83.906 30.469C84.531 28.75 85 27.03 85 25c0-8.281-6.719-15-15-15-3.125 0-6.094.938-8.438 2.656C57.344 5.156 49.22 0 40 0 26.094 0 15 11.25 15 25v1.406C6.25 29.375 0 37.813 0 47.5 0 60 10 70 22.5 70H80c10.938 0 20-8.906 20-20 0-9.531-6.875-17.656-16.094-19.531ZM33.594 35l13.75-13.75C47.656 20.781 48.594 20 50 20c1.25 0 2.188.781 2.5 1.25L66.25 35c1.563 1.406 1.563 3.75 0 5.156-1.406 1.563-3.75 1.563-5.156 0l-7.344-7.343V56.25c0 2.188-1.719 3.75-3.75 3.75-2.188 0-3.75-1.563-3.75-3.75V32.812l-7.5 7.344c-1.406 1.563-3.75 1.563-5.156 0-1.563-1.406-1.563-3.75 0-5.156Z" fill="#7D8087"></path>
														</svg>
														<br />
														<p className="mt-3">Please upload your files</p>
													</div>
												</div>
											</>
										}
									</>
									: (videoOption === "library" ?
										<div className="videoLibraryWrapper slimScroll">
											<div className="videoLibraryWrap">
												{videoLibraryLoader ?
													<VideoSkeleton />
													: (videoLibrary.length > 0 ?
														<>
															<div className="mt-4 mb-4 row">
																{videoLibrary.map((video, index) => {
																	return (
																		<div onClick={() => librarySelectedVideo(video)} className="mt-3 mb-3 col-4">
																			<div className="interactiveWrapper">
																				<div className="thumbnailWrapper">
																					{(video['video_thumbnail'] === "" || video['video_thumbnail'] === null) ?
																						<img src={"https://storage.googleapis.com/roundclicksview/Popup%20Template/Image_not_available.png"} width='' className='img-fluid' alt={video['Video_Name']} />
																						:
																						<img src={video.video_thumbnail} className='img-fluid' alt={video['video_name']} />
																					}
																				</div>
																				<div className="contentWrapper">
																					<h5>{video.video_name}</h5>
																					<div className="contentTimeWrap">
																						<p>{moment(video.created_at).format("MM/DD/YYYY")}</p>
																					</div>
																				</div>
																				{videoLibrarySelectVideo === video.video_library_id &&
																					<div className="loaderWrapper">
																						<Spinner accessibilityLabel="Spinner example" size="large" />
																					</div>
																				}
																			</div>
																		</div>
																	)
																})}
															</div>
															{VideoLibrarySelectedLoader &&
																<div className="loaderWrapper">
																	<Spinner accessibilityLabel="Spinner example" size="large" />
																</div>
															}
														</>
														:
														<>
														</>

													)}
											</div>
										</div>
										: (videoOption === "record" ?
											<>
												<div className="videoNewUploadWrapper">
													{(hasCameraPermission !== "denied" && hasMicroPhonePermission !== "denied") ?
														<>
															<video autoPlay muted loop playsInline disablePictureInPicture="" className={`videoRecorderStream ${mirrorImageFlag ? "mirrorImage" : ""}`} id="videoRecorderStream" ref={videoStreamRef} src={videoStream}></video>
															{videoRecordLoader ?
																<div className="loaderWrapper">
																	<Spinner accessibilityLabel="Spinner example" size="large" />
																</div>
																:
																<>
																	{recordCounterFlag &&
																		<div className="countDownRecordingLayout">
																			<div className="counterText" id="recordCounterText">{recordCounter}</div>
																		</div>
																	}
																	{!recordingFlag &&
																		<div className="VideoRecordControlTop">
																			<div className="videoRecordDeviceOptionWrap">
																				<div className="videoInputWrapper" onClick={() => setVideoOptionModal(!videoOptionModal)} ref={videoOptionRef}>
																					<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none" viewBox="0 0 18 16"><path fill="white" d="M17.406 3.219a1.16 1.16 0 00-1.187-.094L13 4.625V4c0-1.094-.906-2-2-2H2C.875 2 0 2.906 0 4v8c0 1.125.875 2 2 2h9c1.094 0 2-.875 2-2v-.594l3.219 1.5c.156.063.343.125.531.125.219 0 .469-.094.656-.219.375-.218.594-.624.594-1.062V4.281a1.22 1.22 0 00-.594-1.062zM11.5 12c0 .281-.25.5-.5.5H2a.494.494 0 01-.5-.5V4c0-.25.219-.5.5-.5h9c.25 0 .5.25.5.5v8zm5-.625L13 9.781V6.25l3.5-1.594v6.719z"></path></svg>
																					{videoOptionModal &&
																						<div className="videoInputOptionWrap">
																							<div className="videoInputOptionInner">
																								<ul>
																									{Object.keys(videoDeviceInput).map((deviceLabel, index) => {
																										return (
																											<li key={index} className={`deviceLabel ${(deviceLabel === selectedVideoDevice) ? "activeDevice" : ""}`} onClick={() => changeVideoInputOption(deviceLabel)}>{deviceLabel}</li>
																										)
																									})}
																								</ul>
																							</div>
																						</div>
																					}
																				</div>
																				<div className="audioInputWrapper" onClick={() => setAudioOptionModal(!audioOptionModal)} ref={audioOptionRef}>
																					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path fill="#fff" d="M12.75 6a.74.74 0 00-.75.75V8c0 2.344-2.031 4.219-4.406 4C5.5 11.781 4 9.937 4 7.844V6.75A.76.76 0 003.25 6a.74.74 0 00-.75.75v1.031c0 2.782 2 5.282 4.75 5.657V14.5h-1.5a.74.74 0 00-.75.75c0 .438.313.75.75.75h4.5a.74.74 0 00.75-.75.76.76 0 00-.75-.75h-1.5v-1.031C11.406 13.094 13.5 10.78 13.5 8V6.75a.76.76 0 00-.75-.75zM8 11a3 3 0 003-3V3a3 3 0 00-6 0v5a3 3 0 003 3zM6.5 3A1.5 1.5 0 018 1.5c.813 0 1.5.688 1.5 1.5v5A1.5 1.5 0 018 9.5 1.48 1.48 0 016.5 8V3z"></path></svg>
																					<div className="audioTestContainerWrapper">
																						{[...Array(10)].map((e, i) => {
																							return (
																								<div key={i} className={`audioTestInnerWrap ${i < audioLevel ? "activeAudioTest" : ""}`}></div>
																							)
																						})}
																					</div>
																					{audioOptionModal &&
																						<div className="audioInputOptionWrap">
																							<div className="audioInputOptionInner">
																								<ul>
																									{Object.keys(audioDeviceInput).map((deviceLabel, index) => {
																										return (
																											<li key={index} className={`deviceLabel ${(deviceLabel === selectedAudioDevice) ? "activeDevice" : ""}`} onClick={() => changeAudioOption(deviceLabel)}>{deviceLabel}</li>
																										)
																									})}
																								</ul>
																							</div>
																						</div>
																					}
																				</div>
																			</div>
																			<div className="videoRecordDesktopMobilePreviewWrap">
																				<div className="videoRecordDesktopOuterWrap" onClick={() => changeVideoPreview('desktop')}>
																					<div className="videoRecordDesktopInnerWrap">
																						<svg className={`${verticalFlag ? "inActivePreview" : ""}`} xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="white" viewBox="0 0 18 16"><path fill="#fff" d="M16 0H2C.875 0 0 .906 0 2v9c0 1.125.875 2 2 2h4.625l-.313 1.5H4.75a.74.74 0 00-.75.75c0 .438.313.75.75.75h8.5a.74.74 0 00.75-.75.76.76 0 00-.75-.75h-1.594l-.312-1.5H16c1.094 0 2-.875 2-2V2c0-1.094-.906-2-2-2zm-5.875 14.5H7.844l.312-1.5h1.656l.313 1.5zM16.5 11c0 .281-.25.5-.5.5H2a.494.494 0 01-.5-.5V9.5h15V11zm0-3h-15V2c0-.25.219-.5.5-.5h14c.25 0 .5.25.5.5v6z"></path></svg>
																					</div>
																				</div>
																				<div className="videoRecordPreviewSepartor"></div>
																				<div className="videoRecordMobileOuterWrap" onClick={() => changeVideoPreview('mobile')}>
																					<div className="videoRecordMobileInnerWrap">
																						<svg className={`${!verticalFlag ? "inActivePreview" : ""}`} xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="white" viewBox="0 0 16 17"><path fill="#fff" d="M11.5.5h-7c-1.125 0-2 .906-2 2v12c0 1.125.875 2 2 2h7c1.094 0 2-.875 2-2v-12c0-1.094-.906-2-2-2zm.5 14c0 .281-.25.5-.5.5h-7a.494.494 0 01-.5-.5V12h8v2.5zm0-4H4v-8c0-.25.219-.5.5-.5h7c.25 0 .5.25.5.5v8zm-4 3.75a.74.74 0 00.75-.75.76.76 0 00-.75-.75.74.74 0 00-.75.75c0 .438.313.75.75.75z"></path></svg>
																					</div>
																				</div>
																			</div>
																		</div>
																	}
																	{recordTimerFlag &&
																		<div className="videoRecordTimerWrapper">
																			<div className="videoRecordTimerInner" ref={recordTimerInnerRef}>{secondsToHourFunction()}</div>
																		</div>
																	}
																	{verticalFlag &&
																		<div className="orientationPreviewOverlay">
																			<div className="orientationPreviewOverlayLeft"></div>
																			<div className="orientationPreviewOverlayRight"></div>
																		</div>
																	}
																	<div className={`videoRecordControlBottom ${previewVideoRecorder ? "previewVideoRecordBottom" : ""}`}>
																		<div className="mirrorImageWrapper" >
																			{!recordingFlag &&
																				<div className="mirrorImageInnerWrap cursorPointer" onClick={() => setMirrorImageFlag(!mirrorImageFlag) }>
																					<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 5H9.96875V6.75C9.96875 7.0625 10.1562 7.34375 10.4375 7.46875C10.7188 7.5625 11.0625 7.5 11.25 7.28125L13.75 4.78125C14.0625 4.5 14.0625 4.03125 13.75 3.75L11.25 1.25C11.0625 1.03125 10.7188 0.96875 10.4375 1.0625C10.1562 1.1875 9.96875 1.46875 9.96875 1.75V3.5H5.5C2.4375 3.5 0 5.96875 0 9C0 9.4375 0.3125 9.75 0.75 9.75C1.15625 9.75 1.5 9.4375 1.5 9C1.5 6.8125 3.28125 5 5.5 5ZM15.25 8.25C14.8125 8.25 14.5 8.59375 14.5 9C14.5 11.2188 12.6875 13 10.5 13H6V11.25C6 10.9688 5.8125 10.6875 5.53125 10.5625C5.25 10.4688 4.90625 10.5312 4.71875 10.75L2.21875 13.25C1.90625 13.5312 1.90625 14 2.21875 14.2812L4.71875 16.7812C4.90625 17 5.25 17.0625 5.53125 16.9688C5.8125 16.8438 6 16.5625 6 16.25V14.5H10.5C13.5312 14.5 16 12.0625 16 9C16 8.59375 15.6562 8.25 15.25 8.25Z" fill="white"></path></svg>
																					<div className="mirrorImageTextWrap">Mirror image</div>
																				</div>
																			}
																		</div>
																		<div className="videoRecordOptionWrapper">
																			{previewVideoRecorder ?
																				<>
																					<div className="innerOptionWrapper">
																						<div className="d-flex align-items-center optionTextValueWrapper">
																							<div className="optionText">
																								Use this video?
																							</div>
																							<div className="optionValueWrapper">
																								<div className="noOptionValue" onClick={() => cancelVideoRecord()}>
																									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18">
																										<path fill="#0E1658" d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
																									</svg>
																								</div>
																								<div className="yesOptionValue" onClick={() => continueVideoRecord()}>
																									<svg width="20" height="16" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																										<path d="M16.313 3.348a1.4 1.4 0 0 0 0-2.004c-.563-.528-1.442-.528-1.97 0L6.329 9.359 2.812 5.844c-.562-.528-1.44-.528-1.968 0a1.4 1.4 0 0 0 0 2.004l4.5 4.5c.246.28.633.421.984.421.352 0 .703-.14.984-.421l9-9Z" fill="#01BE81"></path>
																									</svg>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className={`videoRecordDiscardOtion ${!recordingFlag ? "hideOption" : ""}`} onClick={(e) => changeVideoRecordMuteUnmute(e)}>
																						{!videoRecordMutedFlag ?
																							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
																								<path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" fill="white" />
																							</svg>
																							:
																							<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
																								<path d="M12.79 9c0-1.3-.72-2.42-1.79-3v6c1.06-.58 1.79-1.7 1.79-3zM2 7v4h3l4 4V3L5 7H2zm9-5v1.5c2.32.74 4 2.93 4 5.5s-1.68 4.76-4 5.5V16c3.15-.78 5.5-3.6 5.5-7S14.15 2.78 11 2z" fill="white" />
																							</svg>
																						}
																					</div>
																				</>
																				:
																				<>
																					<div className="videoRecordButtonWrap" onClick={() => startStopRecording()}>
																						<div className="videoRecordButtonIcon">
																							<div className="videoRecordBtnIconInner"></div>
																						</div>
																						<div className="videoRecordBtnTextWrapper">{!recordingFlag ? "Start" : "Stop"} recording</div>
																					</div>
																					<div className={`videoRecordDiscardOtion ${!recordingFlag ? "hideOption" : ""}`} onClick={() => discardVideoRecording()}>
																						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.25 2.5H11.9062L10.8438 0.75C10.5625 0.28125 10.0938 0 9.5625 0H6.40625C5.875 0 5.40625 0.28125 5.125 0.75L4.0625 2.5H1.75C1.3125 2.5 1 2.84375 1 3.25C1 3.6875 1.3125 4 1.75 4H2L2.65625 14.5938C2.6875 15.4062 3.34375 16 4.15625 16H11.8125C12.625 16 13.2812 15.4062 13.3125 14.5938L14 4H14.25C14.6562 4 15 3.6875 15 3.25C15 2.84375 14.6562 2.5 14.25 2.5ZM6.40625 1.5H9.5625L10.1562 2.5H5.8125L6.40625 1.5ZM11.8125 14.5H4.15625L3.5 4H12.4688L11.8125 14.5Z" fill="#FFFFFF"></path></svg>
																					</div>
																				</>
																			}
																		</div>
																		<div className="videoRecordEmptyWrapper"></div>
																	</div>
																</>
															}
														</>
														:
														<>
															<div className="permissionErrorWrapper">
																<div className="permissionErrorContent">
																	<div className="permissionErrorContentInner">
																		<p>Please reset
																			{(hasCameraPermission === "denied" && hasMicroPhonePermission === "denied") ?
																				" camera & microphone "
																				: (hasCameraPermission === "denied" ?
																					" camera "
																					:
																					" microphone "
																				)
																			}
																			permissions and try again.
																		</p>
																	</div>
																</div>
															</div>
														</>
													}

												</div>
											</>
										: (videoOption === "screen") ?
											<>
												<div className="permissionErrorWrapper">
													<div className="permissionErrorContent">
														<div className="permissionErrorContentInner">
															<p><strong>Hey, please install the Chrome Extension to use this feature.</strong></p>
															<p>The extension will allow you to record your screen quickly. Need help setting up the extension?</p>
														</div>
													</div>
												</div>
											</>
										: (videoOption === "stock") &&
											<div className="videoStockWrapper">
												<div className="videoStockHeaderWrapper">
													Stock Videos
												</div>
												<div style={{height: '225px'}}>
													<Autocomplete
														options={optionsH}
														selected={selectedOptions}
														onSelect={updateSelection}
														textField={textField}
													/>
												</div>
											</div>
										))}
							</div>
						</div>
					</div>
				</Modal.Section>
			</Modal>
			<div id="upload-file"></div>
      </div>		  
    );
}

export default VideoCreator;