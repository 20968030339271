import { Badge, Button, Card, DataTable, EmptyState, Form, FormLayout, Icon, IndexTable, Layout, Link, Modal, Pagination, Spinner, Stack, Tabs, Text, TextField, useIndexResourceState } from '@shopify/polaris';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { CustomersMinor, KeyMajor, AddCodeMajor, ClipboardMinor, EditMajor, EditMinor, DeleteMinor } from '@shopify/polaris-icons';
import { ProductContext } from '../../../store';
import { GetData, PostData } from '../../../services/PostData';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Navigate } from 'react-router-dom';

const ManageUsers = (props) => {
	const [redirect, setRedirect]						= useState(false)
	const [page, setPage]								= useState(1);
	const [pageSize]										= useState(15);
	const [hasNext, setHasNext]						= useState(false)
	const [hasPrevious, setHasPrevious]				= useState(false)
	const [userLoading, setUserLoading]				= useState(true)
	const [users, setUsers]								= useState([])
	const [userMarkup, setUserMarkup]				= useState(null)
	const [totalPages, setTotalPages]				= useState(1)
	const [deleteModalFlag, setDeleteModalFlag]	= useState(false)
	const [deleteUserId, setDeleteUserId]			= useState(null)
	const [deleteFlag, setDeleteFlag]				= useState(false)

	const resourceName						= {
															singular: 'order',
															plural: 'orders',
														}
	const emptyStateMarkup					= (
															<EmptyState
																heading="Manage Team"
																action={{content: 'Add User', url: "/user/add-user"}}
																image='https://storage.googleapis.com/speechvid/no-users.png'
															>
																<p>Streamline User Management and Enhance User Experiences</p>
															</EmptyState>
														);
	const {
				planData,
				setToastChildActive,
				setToastMessage
			}										= useContext(ProductContext)

	useEffect(() => {
		if( planData && planData.length > 0 && planData.indexOf("MQ005") === -1 ){
			setRedirect(true)
		}else{
			getAllUsers()
		}
	}, [planData])

	useEffect(() => {
		setRowMarkup(users)
	}, [users])

	const getAllUsers	= () => {
		setUserLoading(true)
		let data = { page, page_size: parseInt(pageSize) }
		PostData("api/v1/team/get-all-users", data).then(response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){
					setUsers(response.data.users)
					setTotalPages(response.data.totalPages)
					if( response.data.page > 1 ){
						setHasPrevious(true)
					}else{
						setHasPrevious(false)
					}
					if( response.data.totalPages > 1 && response.data.page !== response.data.totalPages ){
						setHasNext(true)
					}else{
						setHasNext(false)
					}
				}
			}
			setUserLoading(false)
		})
	}

	const onPreviousFunc	= () => {
		setPage(page-1)
	}

	const onNextFunc	= () => {
		setPage(page+1)
	}

	useEffect(() => {
		getAllUsers()
	}, [page])

	const toggleDeletePopup	= () => {
		setDeleteModalFlag(!deleteModalFlag)
	}

	const enableDeleteModal	= (user_id) => {
		setDeleteUserId(user_id)
		setDeleteModalFlag(true)
	}

	const setRowMarkup = (users) => {
		if( users.length > 0 ){
			let userMarkupLocal	= users.map(( {user_id, email, first_name, last_name, status}, index ) => (
				<IndexTable.Row id={user_id} key={user_id} position={index}>
					<IndexTable.Cell>
						<Text variant="bodyMd" fontWeight="bold" as="span">
							{first_name}
						</Text>
					</IndexTable.Cell>
					<IndexTable.Cell>
						<Text variant="bodyMd" fontWeight="bold" as="span">
							{last_name}
						</Text>
					</IndexTable.Cell>
					<IndexTable.Cell>
						<Link
							url={'mailto:'+email}
							target="_blank"
							external={true}
						>
							{email}
						</Link>
					</IndexTable.Cell>
					<IndexTable.Cell>
						{status ? 
							<Badge status="success">Active</Badge>
						:
							<Badge status='critical'>In active</Badge>
						}
					</IndexTable.Cell>
					<IndexTable.Cell>
						<Stack spacing="loose" alignment="center">
							<Link
								url={'/user/edit-user/'+user_id}
							>
								<Icon
									source={EditMinor}
								/>
							</Link>
							<Link
								onClick={() => enableDeleteModal(user_id)}
							>
								<Icon
									source={DeleteMinor}
								/>
							</Link>
						</Stack>
					</IndexTable.Cell>
				</IndexTable.Row>
			))
			setUserMarkup(userMarkupLocal)
		}
	}

	const deleteUserConfirm = () => {
		setDeleteFlag(true)
		let data	= {team_user_id: deleteUserId}
		PostData("api/v1/team/delete-user", data).then( response => {
			if( response	!== "Invalid" ){
				if( response.status === "success" ){
					setToastMessage(response.message)
					setToastChildActive(true)
					getAllUsers()
				}else if( response.status === "failure" ){
					setToastMessage(response.message)
				}else{
					setToastMessage(response.message.message)
				}
			}
			setDeleteModalFlag(false)
			setDeleteFlag(false)
		})
	}

	if( redirect ){
		return <Navigate replace to="/user/" />
	}

	return (
		<>
			<div className="container-md homePageWrapper mt-20">
				<Card sectioned title="Manage Team" actions={[{content: 'Add User', url: "/user/add-user"}]}>
					<p>Streamline User Management and Enhance User Experiences</p>
				</Card>
				<div className='mt-4 mb-4'>
					<Card>
						<div className='userListTable'>
							<IndexTable
								resourceName={resourceName}
								itemCount={users.length}
								loading={userLoading}
								emptyState={!userLoading ? emptyStateMarkup : <></>}
								headings={[
									{title: 'First Name'},
									{title: 'Last Name'},
									{title: 'Email Address'},
									{title: 'Status'},
									{title: 'Actions'},
								]}
								selectable={false}
							>
								{userMarkup}
							</IndexTable>
							{totalPages > 1 &&
								<div className='mt-4'>
									<Stack distribution='trailing'>
										<Pagination
											hasPrevious={hasPrevious}
											onPrevious={onPreviousFunc}
											hasNext={hasNext}
											onNext={onNextFunc}
										/>
									</Stack>
								</div>
							}
						</div>
					</Card>
				</div>
			</div>
			{deleteModalFlag &&
				<Modal
					instant
					small
					open={deleteModalFlag}
					onClose={toggleDeletePopup}
					primaryAction={{
						content: 'Yes',
						onAction: deleteUserConfirm,
						loading: deleteFlag
					}}
					secondaryActions={[
						{
							content: 'No',
							onAction: toggleDeletePopup,
						},
					]}
				>
					<Modal.Section>
						<p>Are you sure you want to delete this user?</p>
					</Modal.Section>
				</Modal>
			}
		</>
	);
}

export default ManageUsers;