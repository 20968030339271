const statusValidation = response => {
  switch (response.status) {
    case 200:
      return response.json();
    case 401:
      // alert('Login session expired!');
      localStorage.clear();
      window.location = "/user/logout";
      break;
    case 403:
      //alert('Login session expired!');
      localStorage.clear();
      window.location = "/user/logout";
      break;
    case 422:
      return (response.json().then((result) => {
        return {
          status: 'server_error',
          message: result,
        }
      }));
    case 400:
      return (response.json().then((result) => {
        return {
          status: 'api_error',
          message: result,
        }
      }));

    case 520:
      return (response.json().then((result) => {
        return {
          status: 'application _error',
          message: result,
        }
      }));
    default:
      return "Invalid";
  }
};

export default statusValidation;
