import { Card, DataTable, Link, Page, Pagination } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { PostData } from '../../../services/PostData';
import { CSVLink } from "react-csv";

function Contacts(props) {
	const [page, setPage]						= useState(1)
	const [pageSize, setPageSize]				= useState(25)
	const [leadsRow, setLeadsRow]				= useState([])
	const [totalPage, setTotalPage]			= useState(1)
	const [hasPrevious, setHasPrevious]		= useState(false)
	const [hasNext, setHasNext]				= useState(false)
	const [allUserLeads, setAllUserLeads]	= useState([])
	
	useEffect(() => {
		getAllUserLeads()
	}, [])

	useEffect(() => {
		getUserLeads()
	},[page])

	const headers	= [
								{ label: "Name", key: "visitor_name" },
								{ label: "Email", key: "visitor_email" },
								{ label: "Phone", key: "visitor_phone" }
							];

	const getUserLeads	= () => {
		let data = {page, pageSize}
		PostData("api/v1/getUserLeads", data).then(response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){
					let userLeads		= response.data.getUserLeads
					let leadsRowLocal	= []
					for( let i = 0; i < userLeads.length; i++ ){
						leadsRowLocal.push([userLeads[i].visitor_name, userLeads[i].visitor_email, userLeads[i].visitor_phone])
					}
					setLeadsRow(leadsRowLocal)
					let totalPageLocal	= Math.ceil(response.data.totalCount / response.data.pageSize)
					setPage(response.data.page)
					setPageSize(response.data.pageSize)
					setTotalPage(totalPageLocal)
					if( response.data.page > 1 ){
						setHasPrevious(true)
					}else{
						setHasPrevious(false)
					}
					if( totalPageLocal > 1 && response.data.page !== totalPageLocal ){
						setHasNext(true)
					}else{
						setHasNext(false)
					}
				}
			}
		})
	}

	const getAllUserLeads	= () => {
		PostData("api/v1/getAllUserLeads").then(response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){
					setAllUserLeads(response.data.getAllUserLeads)				
				}
			}
		})
	}

	// const rows = [
	// 	[
	// 	  <Link
	// 		 removeUnderline
	// 		 url="https://www.example.com"
	// 		 key="emerald-silk-gown"
	// 	  >
	// 		 Emerald Silk Gown
	// 	  </Link>,
	// 	  '$875.00',
	// 	  '$122,500.00',
	// 	],
	// 	[
	// 	  <Link
	// 		 removeUnderline
	// 		 url="https://www.example.com"
	// 		 key="mauve-cashmere-scarf"
	// 	  >
	// 		 Mauve Cashmere Scarf
	// 	  </Link>,
	// 	  '$230.00',
	// 	  '$19,090.00',
	// 	],
	// 	[
	// 	  <Link
	// 		 removeUnderline
	// 		 url="https://www.example.com"
	// 		 key="navy-merino-wool"
	// 	  >
	// 		 Navy Merino Wool Blazer with khaki chinos and yellow belt
	// 	  </Link>,
	// 	  '$445.00',
	// 	  '$14,240.00',
	// 	],
	//  ];
	
	const onPreviousFunc	= () => {
		setPage(page-1)
	}

	const onNextFunc	= () => {
		setPage(page+1)
	}

	return (
		<Page
			title="Leads"
			pagination={{
				hasPrevious: hasPrevious,
				hasNext: hasNext,
				onPrevious: onPreviousFunc,
				onNext: onNextFunc
			}}
			primaryAction={
				<CSVLink filename={"All Leads.csv"} data={allUserLeads} headers={headers} className="btn btn-outline-primary exportBtn">
					Export
				</CSVLink>
			}
		>
			<Card sectioned>
				<DataTable
					columnContentTypes={[
						'text',
						'text',
						'text',
					]}
					headings={['Name', 'Email', 'Phone']}
					rows={leadsRow}
				/>
				{totalPage > 1 &&
					<div className='d-flex justify-content-end mt-3'>
						<Pagination
							hasPrevious={hasPrevious}
							onPrevious={() => {
								setPage(page-1)
							}}
							hasNext={hasNext}
							onNext={() => {
								setPage(page+1)
							}}
						/>
					</div>
				}
			</Card>
			<div className='mb-4'></div>
		</Page>
	);
}

export default Contacts;