import { Button, Columns, Icon, TextContainer, Tooltip, Text, Spinner, Modal, Form, FormLayout, TextField, Autocomplete } from '@shopify/polaris';
import { ChatMajor, RefreshMajor, ReplayMinor, SearchMinor, SendMajor } from '@shopify/polaris-icons';
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { PostData, PostFormData } from '../../../services/PostData';
import InfiniteScroll from "react-infinite-scroll-component";
import VideoSkeleton from "../../../components/VideoSkeleton"
import moment from 'moment';
import UserListingSkeleton from '../../../components/UserListingSkeleton';
import ConversationMesssageSkeleton from '../../../components/ConversationMessageSkeleton';
import { gsap } from "gsap";
import ReactDOM from 'react-dom';
import { useCallback } from 'react';
import { useMemo } from 'react';

function Interactions(props) {
	const [userInteractions, setUserInteractions]							= useState([])
	const [page, setPage]															= useState(1)
	const [pageSize, setPageSize]													= useState(10)
	const [finalPageFlag, setFinalPageFlag]									= useState(true)
	const [resetUserInteractionFlag, setResetUserInteractionFlag]		= useState(false)
	const [videoDetailsObject, setVideoDetailsObject]						= useState({})
	const [selectedConversationId, setSelectedConversationId]			= useState(null)
	const [selectedConversation, setSelectedConversation]					= useState({})
	const [firstLoader, setFirstLoader]											= useState(true)
	const [conversationMessages, setConversationMessages]					= useState([])
	const [conversationMsgLoader, setConversationMsgLoader]				= useState(true)
	const [conversationVideo, setConversationVideo]							= useState({})
	const [selectedConvMsgIndex, setSelectedConvMsgIndex]					= useState(null)
	const [audioVideoPlayerPlayFlag, setAudioVideoPlayerPlayFlag]		= useState(true)
	const [enableReplyConversation, setEnableReplyConversation]			= useState(false);
	const polyLineRef																	= useRef(null)
	const soundWaveRef																= useRef(null)
	const audioPlayerRef																= useRef(null)
	const audioPlaybackBarRef														= useRef(null)
	const playerSpeedInnerWrapperRef												= useRef(null)
	const [createPopup, setCreatePopup]											= useState(false)
	const [videoOption, setVideoOption]											= useState("upload")
	const [videoUploadedFlag, setVideoUploadedFlag]							= useState(false)
	const [blobURL, setBlobURL]													= useState("")
	const [videoUploadLoader, setVideoUploadLoader]							= useState(false)
	const [videoLibrarySelectVideo, setVideoLibrarySelectVideo]			= useState("")
	const [VideoLibrarySelectedLoader, setVideoLibrarySelectedLoader]	= useState(false)
	const [fileUploaded, setFileUploaded]										= useState("");
	const videoPlayerRef																= useRef(null)
	const inputFileRef																= useRef(null)
	const [hasWebcam, setHasWebCam]												= useState(false)
	const [hasMicroPhone, setHasMicroPhone]									= useState(false)
	const [hasCameraPermission, setHasCameraPermission]					= useState("prompt")
	const [hasMicroPhonePermission, setHasMicroPhonePermission]			= useState("prompt")
	const [audioDeviceInput, setAudioDeviceInput]							= useState({})
	const [videoDeviceInput, setVideoDeviceInput]							= useState({})
	const [selectedAudioDevice, setSelectedAudioDevice]					= useState("")
	const [selectedVideoDevice, setSelectedVideoDevice]					= useState("")
	const [videoOptionModal, setVideoOptionModal]							= useState(false)
	const [audioOptionModal, setAudioOptionModal]							= useState(false)
	const [mirrorImageFlag, setMirrorImageFlag]								= useState(false)
	const [verticalFlag, setVerticalFlag]										= useState(false)
	const [audioLevel, setAudioLevel]											= useState(1)
	const [recordingFlag, setRecordingFlag]									= useState(false)
	const [recordCounterFlag, setRecordCounterFlag]							= useState(false)
	const [previewVideoRecorder, setPreviewVideoRecorder]					= useState(false)
	const [videoRecordMutedFlag, setVideoRecordMutedFlag]					= useState(false)
	const videoStreamRef																= useRef(null)
	const videoOptionRef																= useRef(null)
	const audioOptionRef																= useRef(null)
	const [videoStream, setVideoStream]											= useState(null)
	const [mediaRecorder, setMediaRecorder]									= useState(null)
	const [videoInputOption, setVideoInputOption]							= useState("")
	const [audioInputOption, setAudioInputOption]							= useState("")
	const [videoRecordBlob, setVideoRecordBlob]								= useState(null)
	const [videoRecordLoader, setVideoRecordLoader]							= useState(false)
	const [videoLibrary, setVideoLibrary]										= useState([])
	const [videoLibraryLoader, setVideoLibraryLoader]						= useState(true)
	const [enableAudioCollect, setEnableAudioCollect]						= useState(false)
	const [enableTextCollect, setEnableTextCollect]							= useState(false)
	const [textResponseContent, setTextResponseContent]					= useState("")
	const audioStreamRef																= useRef(null)
	const [videoAudioStream, setVideoAudioStream] 							= useState(null)
	const audioResultTagRef1														= useRef(null)
	const audioResultTagRef2														= useRef(null)
	const [videoAudioRecordBlob, setVideoAudioRecordBlob]					= useState(null)
	const [previewAudioRecorder, setPreviewAudioRecorder] 				= useState(false)
	const [recordMutedFlag, setRecordMutedFlag] 								= useState(false)
	const [recordTimerFlag, setRecordTimerFlag]								= useState(false)
	const recordTimerInnerRef														= useRef(null)
	const recordTimerInterval														= useRef(null)
	const clearIntervalRecordingRef												= useRef(null)
	const scrollRightRef																= useRef(null)
	var recordCounter																	= 3
	var videoRecordTimer																= 0
	var clearIntervalRecording														= ""
	var blobsRecorded																	= []
	var volumeInterval																= null

	const deselectedOptions = useMemo(
		() => [],
		[],
	 );
	 const [selectedOptions, setSelectedOptions] = useState([]);
	 const [inputValue, setInputValue] = useState('');
	 const [optionsH, setOptionsH] = useState(deselectedOptions);
  
	 const updateText = useCallback(
		(value) => {
		  setInputValue(value);
  
		  if (value === '') {
			 setOptionsH(deselectedOptions);
			 return;
		  }
  
		  const filterRegex = new RegExp(value, 'i');
		  const resultOptions = deselectedOptions.filter((option) =>
			 option.label.match(filterRegex),
		  );
		  setOptionsH(resultOptions);
		},
		[deselectedOptions],
	 );
  
	 const updateSelection = useCallback(
		(selected) => {
		  const selectedValue = selected.map((selectedItem) => {
			 const matchedOption = optionsH.find((option) => {
				return option.value.match(selectedItem);
			 });
			 return matchedOption && matchedOption.label;
		  });
  
		  setSelectedOptions(selected);
		  setInputValue(selectedValue[0] || '');
		},
		[optionsH],
	 );
  
	 const textField = (
		<Autocomplete.TextField
		  onChange={updateText}
		  label=""
		  value={inputValue}
		  prefix={<Icon source={SearchMinor} color="base" />}
		  placeholder="Search"
		  autoComplete="off"
		/>
	 );

	useEffect(() => {
		getUserInteraction()
		getInteractiveVideo()
		getUserVideoLibrary()
		checkPermissions()
		document.addEventListener("mousedown", handleClickOutside)
		document.title	= "Interaction | Muqala App"
		return () => {
			document.title	= "Muqala App"
			document.removeEventListener("mousedown", handleClickOutside);
			turnOffVideoCamera()
			if (clearIntervalRecording) {
				clearInterval(clearIntervalRecording)
			}
		}
	}, [])

	useEffect(() => {
		if( document.querySelector('#mainSVG') ){
			gsap.set('#mainSVG', {
				visibility: 'visible'
			})
			var select = s => document.querySelector(s),
			selectAll = s =>  document.querySelectorAll(s),
					mainSVG = select('#mainSVG'),
					poly = select('#poly'),
					numPoints = 190
			
			
			function createLine() {
				poly.points.clear()
				for(let i = 0; i < numPoints; i++) {
					let p = poly.points.appendItem(mainSVG.createSVGPoint());					
					p.x = 0 + (i * 5);
					p.y = 150;
				}
			}			
			createLine();
			let tl = gsap.timeline();
			tl.to(poly.points, {
				y: '+=60',
				stagger: {
					each: 0.009,
					repeat: -1,
					yoyo: true
				},
				ease: 'sine.inOut'
			}).seek(100)
		}
	},[selectedConvMsgIndex])

	useEffect(() => {
		if( resetUserInteractionFlag ){
			setResetUserInteractionFlag(false)
			getUserInteraction()			
		}
	}, [resetUserInteractionFlag])

	useEffect(() => {
		if( selectedConversationId ){
			getConversationMessage()
		}
	}, [selectedConversationId])

	useEffect(() => {
		if( videoInputOption === "changed" ){
			setVideoInputOption("")
			turnOffVideoCamera()
			turnOnVideoCamera()
		}
	},[videoInputOption])


	useEffect(() => {
		if (audioInputOption === "changed") {
			setAudioInputOption("")
			if( enableAudioCollect ){
				turnOffAudioMicroPhone()
				turnOnAudioMicroPhone()
			}else if( createPopup ){
				turnOffVideoCamera()
				turnOnVideoCamera()
			}
		}
	}, [audioInputOption])

	const turnOnAudioMicroPhone	= () => {
		let selectedAudioDeviceLocal = selectedAudioDevice,
			audioDeviceInputLocal = audioDeviceInput;
		let constraint = { audio: { echoCancellation: true } };
		if (selectedAudioDeviceLocal !== "" && typeof audioDeviceInputLocal[selectedAudioDeviceLocal] !== "undefined") {
			constraint.audio.deviceId = { exact: audioDeviceInputLocal[selectedAudioDeviceLocal] }
		}
		const permissions = navigator.mediaDevices.getUserMedia(constraint)
		permissions.then((stream) => {
			getDeviceInfo()
			setHasMicroPhonePermission("granted")
			setVideoAudioStream(stream);
			const audioContext					= new AudioContext();
			const audioSource						= audioContext.createMediaStreamSource(stream);
			const analyser							= audioContext.createAnalyser();
			analyser.fftSize						= 512;
			analyser.minDecibels					= -127;
			analyser.maxDecibels					= 0;
			analyser.smoothingTimeConstant	= 0.4;
			audioSource.connect(analyser);
			var bufferLength	= analyser.frequencyBinCount;
			const dataArray	= new Uint8Array(bufferLength);
			var canvas			= audioStreamRef.current,
			WIDTH					= audioStreamRef.current.parentNode.offsetWidth,
			HEIGHT				= audioStreamRef.current.parentNode.offsetHeight,
			ctx					= canvas.getContext("2d"),
			barWidth				= (WIDTH / bufferLength) * 2.5,
			x						= 0,
			barHeight;
			const renderFrame = () => {
				requestAnimationFrame(renderFrame);
				x = 0;
				analyser.getByteFrequencyData(dataArray);
				ctx.fillStyle = "#000";
				ctx.fillRect(0, 0, WIDTH, HEIGHT);
				for (var i = 0; i < bufferLength; i++) {
					barHeight	= dataArray[i];
					var r			= barHeight + (25 * (i/bufferLength)),
					g				= 250 * (i/bufferLength),
					b				= 50;
					barHeight	+= 210;
					ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
					ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
					x += barWidth + 1;
				}
			}
			renderFrame();
		}).catch((err) => {
			if (hasMicroPhonePermission === "prompt") {
				setHasMicroPhonePermission("denied")
			}
		})
	}

	const turnOffAudioMicroPhone = () => {
		if (videoAudioStream) {
			const tracks = videoAudioStream.getTracks();
			tracks.forEach(track => track.stop())
		}
	}

	useEffect(() => {
		if ( audioStreamRef && audioStreamRef.current ){
			turnOnAudioMicroPhone()
		}
	}, [audioStreamRef])

	const scrollToRight = () => {
		if (scrollRightRef && scrollRightRef.current) {
			scrollRightRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}

	const handleClickOutside = (event) => {
		if (videoOptionRef && videoOptionRef.current && !videoOptionRef.current.contains(event.target)) {
			setVideoOptionModal(false)
		}		
		if (audioOptionRef && audioOptionRef.current && !audioOptionRef.current.contains(event.target)) {
			setAudioOptionModal(false)
		}
	}

	const checkPermissions = () => {
		if (!navigator.mediaDevices?.enumerateDevices) {
			console.log("enumerateDevices() not supported.");
		} else {
			// List cameras and microphones.
			var hasWebcamLocal	= false,
			hasMicroPhoneLocal	= false;
			navigator.mediaDevices.enumerateDevices().then((devices) => {
				devices.forEach((device) => {
					if (device.kind === "videoinput") {
						hasWebcamLocal = true
						setHasWebCam(hasWebcamLocal)
					} else if (device.kind === "audioinput") {
						hasMicroPhoneLocal = true
						setHasMicroPhone(hasMicroPhoneLocal)
					}
				});
				if (hasWebcamLocal && hasMicroPhoneLocal) {
					setVideoOption("record")
				}
			}).catch((err) => {
				console.error(`${err.name}: ${err.message}`);
			});
		}

		navigator.permissions.query({ name: 'camera' }).then((result) => {
			if (result.state === 'granted') {
				setHasCameraPermission("granted")
			} else if (result.state === 'prompt') {
				setHasCameraPermission("prompt")
			} else {
				setHasCameraPermission("denied")
			}
		});

		navigator.permissions.query({ name: 'microphone' }).then((result) => {
			if (result.state === 'granted') {
				setHasMicroPhonePermission("granted")
			} else if (result.state === 'prompt') {
				setHasMicroPhonePermission("prompt")
			} else {
				setHasMicroPhonePermission("denied")
			}
		});
	}

	const getDeviceInfo = () => {
		let selectedVideoDeviceLocal	= selectedVideoDevice,
		selectedAudioDeviceLocal		= selectedAudioDevice,
		videoDeviceInputLocal			= videoDeviceInput,
		audioDeviceInputLocal			= audioDeviceInput;
		navigator.mediaDevices.enumerateDevices().then((devices) => {
			devices.forEach((device) => {
				if (device.kind === "videoinput") {
					if (selectedVideoDeviceLocal === "") {
						selectedVideoDeviceLocal = device.label
					}
					if (typeof videoDeviceInputLocal[device.label] === "undefined") {
						videoDeviceInputLocal[device.label] = device.deviceId
					}
				} else if (device.kind === "audioinput") {
					if (selectedAudioDeviceLocal === "") {
						selectedAudioDeviceLocal = device.label
					}
					if (typeof audioDeviceInputLocal[device.label] === "undefined") {
						audioDeviceInputLocal[device.label] = device.deviceId
					}
				}
			});
			setSelectedVideoDevice(selectedVideoDeviceLocal)
			setVideoDeviceInput(videoDeviceInputLocal)
			setSelectedAudioDevice(selectedAudioDeviceLocal)
			setAudioDeviceInput(audioDeviceInputLocal)
		}).catch((err) => {
			console.error(`${err.name}: ${err.message}`);
		});
	}

	const turnOnVideoCamera = () => {
		let selectedVideoDeviceLocal	= selectedVideoDevice,
		selectedAudioDeviceLocal		= selectedAudioDevice,
		videoDeviceInputLocal			= videoDeviceInput,
		audioDeviceInputLocal			= audioDeviceInput;
		let constraint = { audio: { echoCancellation: true }, video: true };
		if (selectedAudioDeviceLocal !== "" && typeof audioDeviceInputLocal[selectedAudioDeviceLocal] !== "undefined") {
			constraint.audio.deviceId = { exact: audioDeviceInputLocal[selectedAudioDeviceLocal] }
		}
		if (selectedVideoDeviceLocal !== "" && typeof videoDeviceInputLocal[selectedVideoDeviceLocal] !== "undefined") {
			constraint.video = { deviceId: { exact: videoDeviceInputLocal[selectedVideoDeviceLocal] } }
		}
		const permissions = navigator.mediaDevices.getUserMedia(constraint)
		permissions.then((stream) => {
			getDeviceInfo()
			setHasCameraPermission("granted")
			setHasMicroPhonePermission("granted")
			videoStreamRef.current.srcObject = stream
			setVideoStream(stream);
			const audioContext = new AudioContext();
			const audioSource = audioContext.createMediaStreamSource(stream);
			const analyser = audioContext.createAnalyser();
			analyser.fftSize = 512;
			analyser.minDecibels = -127;
			analyser.maxDecibels = 0;
			analyser.smoothingTimeConstant = 0.4;
			audioSource.connect(analyser);
			const volumes = new Uint8Array(analyser.frequencyBinCount);
			const volumeCallback = () => {
				analyser.getByteFrequencyData(volumes);
				let volumeSum = 0;
				for (const volume of volumes)
					volumeSum += volume;
				const averageVolume = volumeSum / volumes.length;
				const audioLevel = Math.round((averageVolume * 100 / 127) / 10)
				setAudioLevel(audioLevel)
			};
			volumeInterval = setInterval(volumeCallback, 100);
		}).catch((err) => {
			if (hasCameraPermission === "prompt") {
				setHasCameraPermission("denied")
			}
			if (hasMicroPhonePermission === "prompt") {
				setHasMicroPhonePermission("denied")
			}
		});
	}

	const flipedVideoStream = (stream) => {
		const canvas = document.createElement("canvas");
		Object.assign(canvas, { width: 0, height: 0 });
		const ctx = canvas.getContext("2d");
		const track = stream.getVideoTracks()[0];
		const drawOnCanvas = (image, width, height) => {
			// MediaStream's video size may change over time
			if (canvas.width !== width || canvas.height !== height) {
				canvas.width = width;
				canvas.height = height;
				ctx.setTransform(-1, 0, 0, 1, width, 0);
			}
			ctx.clearRect(0, 0, width, height);
			ctx.drawImage(image, 0, 0);
		};
		// the MediaStreamTrackProcessor API is available, we use it
		if (window.MediaStreamTrackProcessor) {
			const processor = new window.MediaStreamTrackProcessor(track);
			const reader = processor.readable.getReader();
			reader.read().then(function readChunk({ done, value }) {
				if (typeof value !== "undefined") {
					const { displayWidth, displayHeight } = value;
					drawOnCanvas(value, displayWidth, displayHeight);
					value.close(); // close the VideoFrame when we're done with it
				}
				if (!done) {
					reader.read().then(readChunk);
				}
			});
		} else {
			const vid = document.createElement("video");
			vid.srcObject = stream;
			// in case requestVideoFrameCallback is available, we use it
			// otherwise we fallback on rAF
			const scheduler = vid.requestVideoFrameCallback ?
				(cb) => vid.requestVideoFrameCallback(cb) : requestAnimationFrame;
			const draw = () => {
				const { videoWidth, videoHeight } = vid;
				drawOnCanvas(vid, videoWidth, videoHeight);
				scheduler(draw);
			};
			vid.play().then(draw);
		}
		var canvaStream = canvas.captureStream()
		var audioTrack = stream.getTracks().filter(function (track) {
			return track.kind === 'audio'
		})[0];
		canvaStream.addTrack(audioTrack);
		return canvaStream;
	}

	const turnOffVideoCamera = () => {
		if (videoStream) {
			const tracks = videoStream.getTracks();
			tracks.forEach(track => track.stop())
		}
		if (volumeInterval) {
			clearInterval(volumeInterval)
			volumeInterval = null
		}
	}

	const videoRecordTimerFunc	= () => {
		videoRecordTimer++
		if( recordTimerInnerRef && recordTimerInnerRef.current ){
			recordTimerInnerRef.current.innerHTML	= secondsToHourFunction()
		}
	}

	const secondsToHourFunction	= () => {
		const result = new Date(videoRecordTimer * 1000).toISOString().slice(11, 19);
		return result
	}

	const audioInputOptionToggle = () => {
		setAudioOptionModal(!audioOptionModal)
	}

	const changeAudioRecordMuteUnmute = (event) => {
		event.stopPropagation()
		if ( audioResultTagRef1 && audioResultTagRef1.current && audioResultTagRef2 && audioResultTagRef2.current ){
			setRecordMutedFlag(!recordMutedFlag)
			audioResultTagRef1.current.muted	= !audioResultTagRef1.current.muted
			audioResultTagRef2.current.muted	= !audioResultTagRef2.current.muted
		}
	}

	const getUserInteraction	= () => {
		let data	= {page, pageSize}
		PostData("api/v1/getUserInteraction", data).then(response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){
					setPage(response.data.page)
					setPageSize(response.data.pageSize)
					if( response.data.user_interactions.length > 0 ){
						let userInteractionsLocal	= [...userInteractions, ...response.data.user_interactions]
						setUserInteractions(userInteractionsLocal)
						if( userInteractionsLocal.length > response.data.totalCount ){
							setPage(page+1)
						}else{
							setFinalPageFlag(false)
						}
						if( !selectedConversationId ){
							setSelectedConversationId(response.data.user_interactions[0].conversation_id)
							setSelectedConversation(response.data.user_interactions[0])
						}						
					}else{
						setFinalPageFlag(false)
						setConversationMsgLoader(false)		
					}
					if( firstLoader ){
						setFirstLoader(false)
					}
				}
			}
		})
	}

	const getInteractiveVideo = () => {				
		let data = { page: 1, page_size:200 }
		PostData('api/v1/getInteractiveVideo', data).then(result => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					let interactiveVideosResult	= result.data.interactiveVideos
					let videoDetailsObjectLocal	= {}
					for( let i = 0; i < interactiveVideosResult.length; i++ ){
						let videoDetail	= interactiveVideosResult[i]
						videoDetailsObjectLocal[videoDetail.video_id]	= videoDetail.video_name
					}
					setVideoDetailsObject(videoDetailsObjectLocal)
				}
			}
		})
	}

	const getUserVideoLibrary = () => {
		PostData('api/v1/getUserVideoLibrary').then(result => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					setVideoLibrary(result.data.video_library)
					setVideoLibraryLoader(false)
				}
			}
		})
	}

	const resetUserInteraction	= () => {
		setPage(1)
		setUserInteractions([])
		setResetUserInteractionFlag(true)
		setSelectedConversationId(null)	
	}

	const changeSelectedConversation = (conversation_id, index) => {
		setSelectedConversationId(conversation_id)
		setSelectedConversation(userInteractions[index])
	}

	const getConversationMessage	= () => {
		setConversationMsgLoader(true)
		let data	= {conversation_id: selectedConversationId}
		PostData("api/v1/getConversationMessage", data).then(response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){					
					setConversationMessages(response.data.conversation_message)
					setConversationVideo(response.data.conversation_video)
					setSelectedConvMsgIndex(0)
					setConversationMsgLoader(false)
				}
			}
		})
	}

	const audioTimeUpdate = () => {
		if (audioPlayerRef && audioPlayerRef.current ) {
			if (audioPlayerRef.current.duration !== Infinity && document.getElementById("audioPlaybackDuration").innerHTML === "00:00" ) {
				var durationHours		= audioPlayerRef.current.duration > 1 ? parseInt(audioPlayerRef.current.duration / (60 * 60), 10) : 0;
				var durationMinutes	= parseInt(audioPlayerRef.current.duration / 60, 10);
				var durationSeconds	= audioPlayerRef.current.duration % 60;
				durationMinutes 		= ("0" + durationMinutes).slice(-2)
				durationSeconds 		= ("0" + durationSeconds.toFixed(0)).slice(-2)
				if (durationHours === 0 && document.getElementById("audioPlaybackDuration")) {
					document.getElementById("audioPlaybackDuration").innerHTML = durationMinutes + ":" + durationSeconds
				} else if(document.getElementById("audioPlaybackDuration")){
					durationHours = ("0" + durationHours).slice(-2)
					document.getElementById("audioPlaybackDuration").innerHTML = durationHours + ":" + durationMinutes + ":" + durationSeconds
				}
			}
			var hours = audioPlayerRef.current.currentTime > 1 ? parseInt(audioPlayerRef.current.currentTime / (60 * 60), 10) : 0;
			var minutes = parseInt(audioPlayerRef.current.currentTime / 60, 10);
			var seconds = audioPlayerRef.current.currentTime % 60;
			minutes = ("0" + minutes).slice(-2)
			seconds = ("0" + seconds.toFixed(0)).slice(-2)
			if (hours === 0) {
				document.getElementById("audioPlaybackCurrentTime").innerHTML = minutes + ":" + seconds
			} else {
				hours = ("0" + hours).slice(-2)
				document.getElementById("audioPlaybackCurrentTime").innerHTML = hours + ":" + minutes + ":" + seconds
			}
			var percentage = (audioPlayerRef.current.currentTime / audioPlayerRef.current.duration) * 100;
			document.getElementById("mediaPlaybackBar").style.width = percentage + "%"
			if( audioPlayerRef.current.duration === audioPlayerRef.current.currentTime ){
				setAudioVideoPlayerPlayFlag(true)
			}
		}
	}

	const audioLoadedData	= () => {
		if ( audioPlayerRef && audioPlayerRef.current ){
			document.getElementById("audioPlaybackDuration").innerHTML	= "00:00"
			if (audioPlayerRef.current.duration !== Infinity && document.getElementById("audioPlaybackDuration").innerHTML === "00:00" ) {
				var durationHours		= audioPlayerRef.current.duration > 1 ? parseInt(audioPlayerRef.current.duration / (60 * 60), 10) : 0;
				var durationMinutes	= parseInt(audioPlayerRef.current.duration / 60, 10);
				var durationSeconds	= audioPlayerRef.current.duration % 60;
				durationMinutes 		= ("0" + durationMinutes).slice(-2)
				durationSeconds 		= ("0" + durationSeconds.toFixed(0)).slice(-2)
				if (durationHours === 0 && document.getElementById("audioPlaybackDuration")) {
					document.getElementById("audioPlaybackDuration").innerHTML = durationMinutes + ":" + durationSeconds
				} else if(document.getElementById("audioPlaybackDuration")){
					durationHours = ("0" + durationHours).slice(-2)
					document.getElementById("audioPlaybackDuration").innerHTML = durationHours + ":" + durationMinutes + ":" + durationSeconds
				}
			}
			setAudioVideoPlayerPlayFlag(false)
			audioPlayerRef.current.play()
		}
	}

	const playAudioFile	= () => {
		if( audioPlayerRef && audioPlayerRef.current ){
			if( audioVideoPlayerPlayFlag ){
				setAudioVideoPlayerPlayFlag(false)
				audioPlayerRef.current.play()
			}else{
				setAudioVideoPlayerPlayFlag(true)
				audioPlayerRef.current.pause()
			}
		}
	}

	const changeAudioPlaybackPosition	= (event) => {
		event.stopPropagation()
		if( audioPlayerRef && audioPlayerRef.current && audioPlaybackBarRef && audioPlaybackBarRef.current && audioPlayerRef.current.duration !== Infinity ){
			let elementPosition	= audioPlaybackBarRef.current.getBoundingClientRect(),
			left						= (event.pageX - elementPosition.left),
			totalWidth				= audioPlaybackBarRef.current.offsetWidth,
			percentage				= ( left / totalWidth ),
			vidTime					= audioPlayerRef.current.duration * percentage;
			audioPlayerRef.current.currentTime = parseInt(vidTime);
		}
	}

	const audioChangeSpeed	= function(event){
		event.stopPropagation()
		if( playerSpeedInnerWrapperRef && playerSpeedInnerWrapperRef.current && audioPlayerRef && audioPlayerRef.current ){
			let currentSpeed	= playerSpeedInnerWrapperRef.current.dataset.speed
			switch (currentSpeed){
				case "1":
					audioPlayerRef.current.playbackRate						= 1.2
					playerSpeedInnerWrapperRef.current.dataset.speed	= "1.2"
					playerSpeedInnerWrapperRef.current.innerHTML			= "1.2x"
					break;
				case "1.2":
					audioPlayerRef.current.playbackRate						= 1.5
					playerSpeedInnerWrapperRef.current.dataset.speed	= "1.5"
					playerSpeedInnerWrapperRef.current.innerHTML			= "1.5x"
					break;
				case "1.5":
					audioPlayerRef.current.playbackRate						= 2
					playerSpeedInnerWrapperRef.current.dataset.speed	= "2"
					playerSpeedInnerWrapperRef.current.innerHTML			= "2x"
					break;
				case "2":
					audioPlayerRef.current.playbackRate						= 1
					playerSpeedInnerWrapperRef.current.dataset.speed	= "1"
					playerSpeedInnerWrapperRef.current.innerHTML			= "1x"
					break;
			}
		}
	}

	const enableReplyConversationFunc	= () => {
		setEnableReplyConversation(!enableReplyConversation)
	}

	const toggleCreatePopup	= () => {
		if (!createPopup && videoOption === "record" ) {
			turnOnVideoCamera()
		} else if ( createPopup &&  videoOption === "record" &&  videoStream !== "") {
			turnOffVideoCamera()
			if( recordTimerInterval && recordTimerInterval.current ){
				videoRecordTimer	= 0
				clearInterval(recordTimerInterval.current)
				recordTimerInterval.current	= null
			}
			setRecordTimerFlag(false)
			if (videoStreamRef.current) {
				videoStreamRef.current.src = null
				videoStreamRef.current.muted = true
				setVideoRecordMutedFlag(false)
				setPreviewVideoRecorder(false)
				setRecordingFlag(false)
				setVideoRecordBlob("")
			}
		}
		setCreatePopup(!createPopup)
		setBlobURL("")
		setVideoUploadedFlag(false)
		setVideoLibrarySelectVideo("")
		setVideoLibrarySelectedLoader(false)
	}

	const changeVideoOption = (value) => {
		if (videoOption === "record" && value !== videoOption) {
			turnOffVideoCamera()
			if (videoStreamRef.current) {
				videoStreamRef.current.src = null
				videoStreamRef.current.muted = true
				setVideoRecordMutedFlag(false)
				setPreviewVideoRecorder(false)
				setRecordingFlag(false)
				setVideoRecordBlob("")
			}
		} else if (value === "record" && value !== videoOption) {
			turnOnVideoCamera()
		}
		if (value !== videoOption) {
			setVideoOption(value)
		}
	}

	const cancelFileUpload = () => {
		setBlobURL("")
		setVideoUploadedFlag(false)
	}

	const continueFileUpload = async () => {
		setVideoUploadLoader(true)
		var data = await new Promise((resolve, reject) => {
			// load the file to a video player
			const videoPlayer = document.createElement('video');
			videoPlayer.setAttribute('src', URL.createObjectURL(fileUploaded));
			videoPlayer.load();
			videoPlayer.addEventListener('error', (ex) => {
				reject("error when loading video file", ex);
			});
			// load metadata of the video to get video duration and dimensions
			videoPlayer.addEventListener('loadedmetadata', () => {
				// delay seeking or else 'seeked' event won't fire on Safari
				setTimeout(() => {
					videoPlayer.currentTime = 1;
				}, 200);
				// extract video thumbnail once seeking is complete
				videoPlayer.addEventListener('seeked', () => {
					const canvas = document.createElement("canvas");
					canvas.width = videoPlayer.videoWidth;
					canvas.height = videoPlayer.videoHeight;
					// draw the video frame to canvas
					const ctx = canvas.getContext("2d");
					ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
					// return the canvas image as a blob
					ctx.canvas.toBlob(
						blob => {
							// let url = URL.createObjectURL(blob);
							// resolve({dataUrl:url, duration: videoPlayer.duration})
							var reader = new FileReader();
							reader.onload = (event) => { resolve({ dataUrl: event.target.result, duration: videoPlayer.duration }) };
							reader.readAsDataURL(blob);
						},
						"image/jpeg",
						0.75 /* quality */
					);
				});
			});
		});
		let fileSize = fileUploaded?.size
		const formData = new FormData()
		formData.append('video_upload', fileUploaded)
		formData.append('duration', data.duration)
		formData.append('file_size', fileSize)
		formData.append('thumbnail_url', data.dataUrl)
		formData.append('conversation_id', selectedConversationId)
		PostFormData("api/v1/sendVideoResponse", formData).then((result) => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					let videoLibraryLocal	= [...videoLibrary]
					videoLibraryLocal.unshift(result.data.video_library)
					let conversationMessagesLocal	= [...conversationMessages]
					conversationMessagesLocal.push(result.data.conversation_msg_data)
					setConversationMessages(conversationMessagesLocal);
					setSelectedConvMsgIndex(conversationMessagesLocal.length-1)
					scrollToRight()
					setVideoLibrary(videoLibraryLocal)
					setVideoUploadLoader(false)
					setCreatePopup(false)					
					setBlobURL(null)
					setVideoUploadedFlag(false)
					setEnableReplyConversation(false)
					setFileUploaded(null)
				}
			}
		})
	}

	const createUpload = () => {
		let inputFile = React.createElement('input', { type: 'file', name: 'myfile', id: 'myfile', ref: inputFileRef, accept: "video/*", onChange: (event) => { fileUpload(event) } })
		ReactDOM.render(
			inputFile,
			document.getElementById('upload-file')
		);
		inputFileRef.current.click()
	}

	const fileUpload = (event) => {
		let file = event.target.files[0];
		let blobURL = URL.createObjectURL(file);
		setBlobURL(blobURL)
		setVideoUploadedFlag(true)
		setFileUploaded(event.target.files[0])
		event.target.value = ""
	}

	const librarySelectedVideo = (video) => {
		setVideoLibrarySelectVideo(video.video_library_id)
		setVideoLibrarySelectedLoader(true)
		let data = { "video_library_id": video.video_library_id, conversation_id: selectedConversationId }
		PostData("api/v1/sendVideoResponseLibrary", data).then((result) => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					let conversationMessagesLocal	= [...conversationMessages]
					conversationMessagesLocal.push(result.data.conversation_msg_data)
					setConversationMessages(conversationMessagesLocal);
					setSelectedConvMsgIndex(conversationMessagesLocal.length-1)
					scrollToRight()
					setEnableReplyConversation(false)
					setVideoLibrarySelectVideo(null)
					setVideoLibrarySelectedLoader(false)
					setCreatePopup(false)
				}
			}
		})
	}

	const changeVideoInputOption = (deviceLabel) => {
		let selectedVideoDeviceLocal	= selectedVideoDevice
		if (selectedVideoDeviceLocal !== deviceLabel) {
			setSelectedVideoDevice(deviceLabel)
			setVideoOptionModal(false)
			setVideoInputOption("Changed")
		}
	}

	const changeAudioOption = (deviceLabel) => {
		let selectedAudioDeviceLocal	= selectedAudioDevice
		if (selectedAudioDeviceLocal !== deviceLabel) {
			setSelectedAudioDevice(deviceLabel)
			setAudioOptionModal(false)
			setAudioInputOption("changed")
		}
	}

	const changeVideoPreview = (value) => {
		let verticalFlag = false
		if (value === 'mobile') {
			verticalFlag = true
		}
		setVerticalFlag(verticalFlag)
	}

	const startStopRecording = () => {
		if (!recordingFlag) {
			setRecordingFlag(true)
			setRecordCounterFlag(true)
			clearIntervalRecording = setInterval(interValRecording, 1000)
		} else {
			if( mediaRecorder ){
				mediaRecorder.stop()
			}
			setMirrorImageFlag(false)
			setPreviewVideoRecorder(true)
			setVideoRecordMutedFlag(true)
			videoStreamRef.current.muted = !videoStreamRef.current.muted
			setRecordMutedFlag(true)
			if( recordTimerInterval && recordTimerInterval.current ){
				videoRecordTimer	= 0
				clearInterval(recordTimerInterval.current)
				recordTimerInterval.current	= null
			}
		}
	}

	const interValRecording = () => {
		if (recordCounter <= 1) {			
			clearInterval(clearIntervalRecording)
			setRecordCounterFlag(false)
			if( enableAudioCollect ){
				startStoreAudioRecording()
			}else{
				startStoreRecording()
			}
		} else {
			recordCounter--;
			if( document.getElementById('recordCounterText') ){
				document.getElementById('recordCounterText').innerHTML	= recordCounter
			}
		}
	}

	const startStoreRecording = () => {
		if (videoStream) {
			let mediaStream = videoStream
			if (mirrorImageFlag) {
				mediaStream = flipedVideoStream(mediaStream)
			}
			videoRecordTimer	= 0
			setRecordTimerFlag(true)
			recordTimerInterval.current	= setInterval(videoRecordTimerFunc, 1000);
			if (mediaStream) {
				let mediaRecorderLocal = new MediaRecorder(mediaStream, { mimeType: 'video/webm' });
				setMediaRecorder(mediaRecorderLocal)
				mediaRecorderLocal.addEventListener('dataavailable', function (e) {
					blobsRecorded.push(e.data);
				});
				mediaRecorderLocal.addEventListener('stop', function () {
					if( typeof mediaRecorderLocal.deleteFlag === "undefined" || ( typeof mediaRecorderLocal.deleteFlag !== "undefined" && !mediaRecorderLocal.deleteFlag ) ){
						let videoLocalBlob = new Blob(blobsRecorded, { type: 'video/mp4' })
						let videoLocal = URL.createObjectURL(videoLocalBlob);
						videoStreamRef.current.srcObject = null
						videoStreamRef.current.src = videoLocal
						setVideoRecordBlob(videoLocalBlob)
						turnOffVideoCamera()
					} else {
						mediaRecorder.deleteFlag	= false
					}
					blobsRecorded = []
				});
				mediaRecorderLocal.start(1000);
			}
		}
	}

	const startStoreAudioRecording = () => {
		if( recordTimerInterval && recordTimerInterval.current ){
			clearInterval(recordTimerInterval.current)
			recordTimerInterval.current	= null
		}
		videoRecordTimer	= 0
		setRecordTimerFlag(true)
		recordTimerInterval.current	= setInterval(videoRecordTimerFunc, 1000);
		if (videoAudioStream) {
			let mediaStream = videoAudioStream
			if (mediaStream) {
				let mediaRecorderLocal = new MediaRecorder(mediaStream, { mimeType: 'video/webm' });
				setMediaRecorder(mediaRecorderLocal)
				mediaRecorderLocal.addEventListener('dataavailable', function (e) {
					blobsRecorded.push(e.data);
				});
				mediaRecorderLocal.addEventListener('stop', function () {
					if (typeof mediaRecorderLocal.deleteFlag === "undefined" || (typeof mediaRecorderLocal.deleteFlag !== "undefined" && !mediaRecorderLocal.deleteFlag)) {
						if (audioStreamRef && audioStreamRef.current) {
							let audioLocalBlob = new Blob(blobsRecorded, { type: 'audio/mp4' })
							let audioLocal = URL.createObjectURL(audioLocalBlob);
							if( audioResultTagRef1 && audioResultTagRef1.current && audioResultTagRef2 && audioResultTagRef2.current ){
								audioResultTagRef1.current.src	= audioLocal
								audioResultTagRef1.current.muted	= false
								audioResultTagRef2.current.src	= audioLocal
								audioResultTagRef2.current.muted	= false
								const audioContext					= new AudioContext();
								const audioSource						= audioContext.createMediaElementSource(audioResultTagRef1.current);
								const analyser							= audioContext.createAnalyser();
								analyser.fftSize						= 512;
								analyser.minDecibels					= -127;
								analyser.maxDecibels					= 0;
								analyser.smoothingTimeConstant	= 0.4;
								audioSource.connect(analyser);
								var bufferLength	= analyser.frequencyBinCount;
								const dataArray	= new Uint8Array(bufferLength);
								var canvas			= audioStreamRef.current,
								WIDTH					= canvas.parentNode.width,
								HEIGHT				= canvas.parentNode.height,
								ctx					= canvas.getContext("2d"),
								barWidth				= (WIDTH / bufferLength) * 2.5,
								x						= 0,
								barHeight;
								const renderFrame = () => {
									requestAnimationFrame(renderFrame);
									x = 0;
									analyser.getByteFrequencyData(dataArray);
									ctx.fillStyle = "#000";
									ctx.fillRect(0, 0, WIDTH, HEIGHT);
									for (var i = 0; i < bufferLength; i++) {
										barHeight	= dataArray[i];
										var r			= barHeight + (25 * (i/bufferLength)),
										g				= 250 * (i/bufferLength),
										b				= 50;
										barHeight	+= 140;
										ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
										ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
										x += barWidth + 1;
									}
								}
								renderFrame();
							}
							setVideoAudioRecordBlob(audioLocalBlob)
						}
						turnOffAudioMicroPhone()
					} else {
						if( mediaRecorder ){
							mediaRecorder.deleteFlag = false
						}else if( mediaRecorderLocal ){
							mediaRecorderLocal.deleteFlag	= false
						}
					}
					blobsRecorded = []
				});
				mediaRecorderLocal.start(1000);
			}
		}
	}

	const discardVideoRecording = () => {
		setRecordingFlag(false)
		mediaRecorder.deleteFlag	= true
		mediaRecorder.stop()
		setRecordTimerFlag(false)
		if( recordTimerInterval && recordTimerInterval.current ){
			clearInterval(recordTimerInterval.current)
			recordTimerInterval.current	= null
		}
	}

	const changeVideoRecordMuteUnmute = (event) => {
		event.stopPropagation()		
		if (videoStreamRef && videoStreamRef.current) {
			setVideoRecordMutedFlag(!videoRecordMutedFlag)
			videoStreamRef.current.muted = !videoStreamRef.current.muted
		}
	}

	const cancelVideoRecord = () => {
		if (videoStreamRef.current) {
			videoStreamRef.current.src = null
			videoStreamRef.current.muted = true
			setVideoRecordMutedFlag(false)
			setPreviewVideoRecorder(false)
			setRecordingFlag(false)
			setVideoInputOption("changed")
		}
	}

	const continueVideoRecord = async () => {
		setVideoRecordLoader(true)
		var data = await new Promise((resolve, reject) => {
			// load the file to a video player
			const videoPlayer = document.createElement('video');
			videoPlayer.setAttribute('src', URL.createObjectURL(videoRecordBlob));
			videoPlayer.load();
			videoPlayer.addEventListener('error', (ex) => {
				reject("error when loading video file", ex);
			});
			// load metadata of the video to get video duration and dimensions
			videoPlayer.addEventListener('loadedmetadata', () => {
				// delay seeking or else 'seeked' event won't fire on Safari
				setTimeout(() => {
					videoPlayer.currentTime = 1;
				}, 200);
				// extract video thumbnail once seeking is complete
				videoPlayer.addEventListener('seeked', () => {
					const canvas = document.createElement("canvas");
					canvas.width = videoPlayer.videoWidth;
					canvas.height = videoPlayer.videoHeight;
					// draw the video frame to canvas
					const ctx = canvas.getContext("2d");
					ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
					// return the canvas image as a blob
					ctx.canvas.toBlob(
						blob => {
							// let url = URL.createObjectURL(blob);
							// resolve({dataUrl:url, duration: videoPlayer.duration})
							var reader = new FileReader();
							reader.onload = (event) => { resolve({ dataUrl: event.target.result, duration: videoPlayer.duration }) };
							reader.readAsDataURL(blob);
						},
						"image/jpeg",
						0.75 /* quality */
					);
				});
			});
		});
		let fileSize = videoRecordBlob?.size
		const formData = new FormData()
		formData.append('video_upload', videoRecordBlob)
		formData.append('duration', videoStreamRef.current.duration)
		formData.append('file_size', fileSize)
		formData.append('thumbnail_url', data.dataUrl)
		formData.append('conversation_id', selectedConversationId)
		PostFormData("api/v1/sendVideoResponse", formData).then((result) => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					let videoLibraryLocal 		= [...videoLibrary];
					videoLibraryLocal.unshift(result.data.video_library)
					let conversationMessagesLocal	= [...conversationMessages]
					conversationMessagesLocal.push(result.data.conversation_msg_data)
					setConversationMessages(conversationMessagesLocal);
					setSelectedConvMsgIndex(conversationMessagesLocal.length-1)
					scrollToRight()
					setVideoLibrary(videoLibraryLocal)
					setCreatePopup(false)
					setBlobURL(null)
					setVideoUploadedFlag(false)
					setFileUploaded(null)
					setPreviewVideoRecorder(false)
					setEnableReplyConversation(false)
					setRecordingFlag(false)
				}
			}
			setVideoRecordLoader(false)
		})
	}

	const enableAudioResponseCollect	= () => {
		if( !enableAudioCollect ){
			turnOnAudioMicroPhone()
			setEnableAudioCollect(true)
		}else{
			turnOffAudioMicroPhone()
			cancelAudioRecord()
			if( recordTimerInterval && recordTimerInterval.current ){
				videoRecordTimer	= 0
				clearInterval(recordTimerInterval.current)
				recordTimerInterval.current	= null
			}
			setRecordTimerFlag(false)
		}
		
	}

	const enableTextResponseCollect	= () => {
		setEnableTextCollect(!enableTextCollect)
	}

	const cancelAudioRecord = (event) => {
		if( event ){
			event.stopPropagation()
		}
		if (audioStreamRef.current) {
			setRecordMutedFlag(false)
			setPreviewAudioRecorder(false)
			setRecordingFlag(false)
			setEnableAudioCollect(false)
			if( videoPlayerRef && videoPlayerRef.current ){
				videoPlayerRef.current.play()
			}
		}
	}

	const continueAudioRecord	= async(event) => {
		setVideoRecordLoader(true)
		event.stopPropagation()
		setPreviewAudioRecorder(false)
		turnOffAudioMicroPhone()
		if( audioResultTagRef2 && audioResultTagRef2.current ){
			setRecordMutedFlag(false)
			audioResultTagRef2.current.muted = true
		}
		var formData	= new FormData()
		formData.append('audio_upload', videoAudioRecordBlob)
		formData.append('conversation_id', selectedConversationId)
		PostFormData("api/v1/sendAudioResponse", formData).then((result) => {
			if (result !== "Invalid") {
				if (result.status === "success") {		
					let conversationMessagesLocal	= [...conversationMessages]
					conversationMessagesLocal.push(result.data.conversation_msg_data)
					setConversationMessages(conversationMessagesLocal);
					setSelectedConvMsgIndex(conversationMessagesLocal.length-1)
					scrollToRight()
					setEnableReplyConversation(false)
					setEnableAudioCollect(false)
					setVideoRecordLoader(false)
				}
			}
		})
	}

	const startStopAudioRecording	= () => {
		if (!recordingFlag) {
			setRecordingFlag(true)
			setRecordCounterFlag(true)
			clearIntervalRecording = setInterval(interValRecording, 1000)
		} else {
			if (mediaRecorder) {
				mediaRecorder.stop()
			}
			setPreviewAudioRecorder(true)
			setRecordMutedFlag(true)
			if( recordTimerInterval && recordTimerInterval.current ){
				videoRecordTimer	= 0
				clearInterval(recordTimerInterval.current)
				recordTimerInterval.current	= null
			}
			setRecordTimerFlag(false)
		}
	}

	const discardAuidoRecording	= () => {
		turnOffAudioMicroPhone()
		setRecordingFlag(false)
		if( videoStreamRef && videoStreamRef.current ){
			videoStreamRef.current.play()
		}
		setRecordTimerFlag(false)
		if( recordTimerInterval && recordTimerInterval.current ){
			clearInterval(recordTimerInterval.current)
			recordTimerInterval.current	= null
		}
		if( mediaRecorder ){
			mediaRecorder.deleteFlag = true
			mediaRecorder.stop()
		}
		setEnableAudioCollect(false)
	}

	const changeTextResponseContent	= (value) => {
		setTextResponseContent(value)
	}

	const handleTextResponseSubmit	= () => {
		if( textResponseContent ){
			let data = { "text_response": textResponseContent, conversation_id: selectedConversationId }
			PostData("api/v1/sendTextResponse", data).then((result) => {
				if (result !== "Invalid") {
					if (result.status === "success") {
						let conversationMessagesLocal	= [...conversationMessages]
						conversationMessagesLocal.push(result.data.conversation_msg_data)
						setConversationMessages(conversationMessagesLocal);
						setSelectedConvMsgIndex(conversationMessagesLocal.length-1)
						setEnableTextCollect(false)
						setTextResponseContent("")
						setEnableReplyConversation(false)
					}
				}
			})
		}
	}

	return (
		<>
			<div className="container-md homePageWrapper mt-20">
				<Columns
					columns={{
						xs: '6fr',
						sm: '6fr',
						md: '2.5fr 3.5fr',
						lg: '1.5fr 4.5fr'
					}}
					gap={{
						xs: '4',
						md: '2',
					}}
				>
					<div className='interactionLeftHeight' id='infiniteScrollWrapDiv'>
						<Columns
							columns={{
								xs: '3fr 3fr',
								md: '5fr 1fr',
							}}
							gap={{
								xs: '4',
								md: '2',
							}}
						>
							<div className='interactionLeftWrapper'>
								<p>All Interaction</p>
							</div>
							<div className='interactionRightWrapper cursorPointer' onClick={() => resetUserInteraction()}>
								<Icon
									source={RefreshMajor}
									color="base"
								/>
							</div>
						</Columns>
						<hr />
						<div className='interactionUserWrapper'>
							{firstLoader ?
								<UserListingSkeleton />
							:
								<InfiniteScroll
									dataLength={userInteractions.length}
									next={() => getUserInteraction()}
									hasMore={finalPageFlag}
									loader={<div><UserListingSkeleton /></div>}
									//height={"100%"}
									scrollableTarget="infiniteScrollWrapDiv"
									endMessage={
										( page === 1 && userInteractions.length === 0 ) ?
											<div className="text-center mt-6 nouser-msg">
												<div>
													<span className="material-icons-outlined">person</span>
												</div>
												<h4>No Interaction Available</h4>
												<p>All the conversations will be listed here.</p>
											</div>
											:
											<p style={{ textAlign: "center" }}>
												<b>Yay! You have seen it all</b>
											</p>
									}
								>
									<div>
										{userInteractions.map((user, index) =>
											<div key={index} className={`ineractionUser ${selectedConversationId === user.conversation_id ? "activeConversation" : ""}`} onClick={() => changeSelectedConversation(user.conversation_id, index)}>
												<Columns
													columns={{
														xs: '1fr 5fr',
														md: '1fr 5fr',
													}}
													gap={{
														xs: '4',
														md: '2',
													}}
												>
													<div className='interactionUserInitals'>
														<div className='interactionUserInitalInner'>
															{user.visitor_name ? user.visitor_name.substring(0,1) : ""}
														</div>
													</div>
													<div className='interactionUserDetails'>
														<div className='interactionUserDetailTop'>
															{user.visitor_name}
														</div>
														<div className='interactionUserDetailBottom'>
															<span className='dateWrapper'>{moment(user.conversationUpdatedAt).format("DD MMM YY")} via </span>
															<span className='interactionUserDetailVideoName'>{videoDetailsObject[user.video_id]}</span>
														</div>
													</div>
												</Columns>
											</div>								
										)}
									</div>
								</InfiniteScroll>
							}
							{/* {[...Array(20)].map((e, i) => {
								return(
									<div key={i} className='ineractionUser'>
										<Columns
											columns={{
												xs: '3fr 3fr',
												md: '1fr 5fr',
											}}
											gap={{
												xs: '4',
												md: '2',
											}}
										>
											<div className='interactionUserInitals'>
												<div className='interactionUserInitalInner'>
													SM
												</div>
											</div>
											<div className='interactionUserDetails'>
												<div className='interactionUserDetailTop'>
													Suredar M
												</div>
												<div className='interactionUserDetailBottom'>
													<span className='dateWrapper'>14 Nov 22 via</span>
													<span className='interactionUserDetailVideoName'>Lead Generation(Copy)</span>
												</div>
											</div>
										</Columns>
									</div>
								)				
							})} */}
						</div>
					</div>
					<div className='interactionMessageWrapper'>
						{conversationMsgLoader ?
							<div className='my-4 mx-4'>
								<ConversationMesssageSkeleton />
							</div>
						: ( conversationMessages.length > 0 ?
							<>
								{!enableReplyConversation &&
									<>
										{(conversationMessages && conversationMessages[selectedConvMsgIndex] && conversationMessages[selectedConvMsgIndex].message_json && conversationMessages[selectedConvMsgIndex].message_json.part_number ) &&
											<div className='messageWrapperTopPartDetails'>								
												<div className='messageWrapInnerPart'>
													{( conversationVideo && conversationVideo.video_parts && conversationVideo.video_parts[conversationMessages[selectedConvMsgIndex].message_json.part_number - 1]) &&
														<div className='messageWrapInnerImage' style={{"--backgroundImage": `url(${conversationVideo.video_parts[conversationMessages[selectedConvMsgIndex].message_json.part_number - 1].video_thumbnail})`}}>
															<div className='topPartDetailNumber'>{conversationMessages[selectedConvMsgIndex].message_json.part_number}</div>
															<div className='playIconWrapper'>
																<svg fill="none" height="96" width="96" xmlns="http://www.w3.org/2000/svg" className="styles__PlayIconMini-sc-neu9r8-5 fQRBts"><path clipRule="evenodd" d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z" fill="#fff" fillRule="evenodd"></path><path clipRule="evenodd" d="M37.326 33.822c0-2.408 2.695-3.835 4.687-2.481l20.862 14.178c1.752 1.19 1.752 3.772 0 4.963L42.013 64.66c-1.992 1.354-4.687-.072-4.687-2.48V33.821z" fill="#000" fillRule="evenodd"></path></svg>
															</div>
														</div>
													}
													<div className='messageWrappInnerPartName'>
														{( conversationVideo && conversationVideo.video_parts && conversationVideo.video_parts[conversationMessages[selectedConvMsgIndex].message_json.part_number - 1]) &&
															conversationVideo.video_parts[conversationMessages[selectedConvMsgIndex].message_json.part_number - 1].part_name
														}
													</div>
												</div>
											</div>
										}
									</>
								}
								{( conversationMessages && conversationMessages[selectedConvMsgIndex] && conversationMessages[selectedConvMsgIndex].msg_type === "user_clicked" ) ?
									<div className='messageWrapMiddleWrapper'>
										{( conversationMessages[selectedConvMsgIndex].message_json && conversationMessages[selectedConvMsgIndex].message_json.part_options && conversationMessages[selectedConvMsgIndex].message_json.part_options.options && conversationMessages[selectedConvMsgIndex].message_json.part_options.options.choices && conversationMessages[selectedConvMsgIndex].message_json.part_options.options.choices.length > 0) ?
											<>
												{conversationMessages[selectedConvMsgIndex].message_json.part_options.options.choices.map((choice, index) => {
													return(
														<div className='messageUserOptionsWrap' key={index}>
															{index === parseInt(conversationMessages[selectedConvMsgIndex].message_json.button_index) &&
																<div className='activeWrapper' style={{"--width":"100%"}}></div>
															}
															<div className='userOptionContent'>
																{choice}
																<svg fill="none" height="11" width="14" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M13.644.689a1.1 1.1 0 010 1.556l-8.6 8.6-4.689-4.69A1.1 1.1 0 111.911 4.6l3.133 3.133L12.09.69a1.1 1.1 0 011.555 0z"></path></svg>
															</div>
														</div>
													)
												})}
											</>
										: ( (conversationMessages[selectedConvMsgIndex].message_json && conversationMessages[selectedConvMsgIndex].message_json.part_options && conversationMessages[selectedConvMsgIndex].message_json.part_options.type && conversationMessages[selectedConvMsgIndex].message_json.part_options.type === "open_ended") ?
											<div className='openEndedOptions'>
												{conversationMessages[selectedConvMsgIndex].message_json.part_options.options.video &&
													<div className='openEndedOptionWrap'>
														{parseInt(conversationMessages[selectedConvMsgIndex].message_json.button_index) === 0 &&
															<div className='activeWrapper' style={{"--width":"100%"}}></div>
														}
														<svg fill="none" height="24" width="27" xmlns="http://www.w3.org/2000/svg"><rect height="16" rx="3" width="18" y="4"></rect><path clipRule="evenodd" d="M20 10.425a1 1 0 01.563-.899l5-2.432a1 1 0 011.437.9v8.012a1 1 0 01-1.413.91l-5-2.264a1 1 0 01-.587-.91v-3.317z"></path></svg>
														<span>Video</span>
													</div>
												}
												{conversationMessages[selectedConvMsgIndex].message_json.part_options.options.audio &&
													<div className='openEndedOptionWrap'>
														{parseInt(conversationMessages[selectedConvMsgIndex].message_json.button_index) === 1 &&
															<div className='activeWrapper' style={{"--width":"100%"}}></div>
														}
														<svg height="24" width="27" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M14 0a4 4 0 00-4 4v8a4 4 0 008 0V4a4 4 0 00-4-4zM6 8.846a.847.847 0 011.696 0v3.33c0 3.476 2.822 6.293 6.304 6.293s6.304-2.817 6.304-6.293v-3.33a.847.847 0 011.696 0v3.33a7.991 7.991 0 01-7.152 7.93v2.2h2.657a.847.847 0 110 1.694h-7.01a.847.847 0 110-1.693h2.657v-2.201A7.991 7.991 0 016 12.176v-3.33z" fillRule="evenodd"></path></svg>
														<span>Audio</span>
													</div>
												}
												{conversationMessages[selectedConvMsgIndex].message_json.part_options.options.text &&
													<div className='openEndedOptionWrap'>
														{parseInt(conversationMessages[selectedConvMsgIndex].message_json.button_index) === 2 &&
															<div className='activeWrapper' style={{"--width":"100%"}}></div>
														}
														<svg height="24" width="27" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M6 3a2 2 0 00-2 2v15a2 2 0 002 2h15a2 2 0 002-2V5a2 2 0 00-2-2H6zm6.252 12.993h-1.264V18h5.224v-2.007h-1.288V8.837h1.682v1.436h2.64V6.896h-11.1v3.377h2.625V8.837h1.48v7.156z" fillRule="evenodd"></path></svg>
														<span>Text</span>
													</div>
												}
											</div>
										: ( (conversationMessages[selectedConvMsgIndex].message_json && conversationMessages[selectedConvMsgIndex].message_json.part_options && conversationMessages[selectedConvMsgIndex].message_json.part_options.type && conversationMessages[selectedConvMsgIndex].message_json.part_options.type === "button" && conversationMessages[selectedConvMsgIndex].message_json.part_options.options && conversationMessages[selectedConvMsgIndex].message_json.part_options.options.btn_text) &&
											<div className='messageUserOptionsWrap'>
												<div className='activeWrapper' style={{"--width":"100%"}}></div>
												<div className='userOptionContent'>
													{conversationMessages[selectedConvMsgIndex].message_json.part_options.options.btn_text}
													<svg fill="none" height="11" width="14" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M13.644.689a1.1 1.1 0 010 1.556l-8.6 8.6-4.689-4.69A1.1 1.1 0 111.911 4.6l3.133 3.133L12.09.69a1.1 1.1 0 011.555 0z"></path></svg>
												</div>
											</div>
										))}
									</div>
								: (( conversationMessages && conversationMessages[selectedConvMsgIndex] && conversationMessages[selectedConvMsgIndex].msg_type === "contact_form_submission" ) ?
									<div className='messageWrapMiddleWrapper'>
										{( conversationMessages[selectedConvMsgIndex].message_json && conversationMessages[selectedConvMsgIndex].message_json.contact_form_details && conversationMessages[selectedConvMsgIndex].message_json.contact_form_details.length > 0) &&
											<div style={{color:"#f79401"}}>
												<TextContainer>
													<Text variant="headingMd" as="h2">Contact Form Details</Text>
												</TextContainer>
												<table style={{color:"#f79401"}} className="contactFormDetailsWrapper">
													{conversationMessages[selectedConvMsgIndex].message_json.contact_form_details.map((contactForm, index) => {
														return(
															<tr key={index}>
																<th>{contactForm.field_name}</th>
																<td>{contactForm.value}</td>
															</tr>
														)
													})}
												</table>
											</div>
										}
									</div>
								: (( conversationMessages && conversationMessages[selectedConvMsgIndex] && conversationMessages[selectedConvMsgIndex].msg_type === "text_response" ) ?
									<div className='messageWrapMiddleWrapper textResponseWrapper'>
										<div style={{color:"#000"}}>
											<TextContainer>
												<Text variant="headingMd" as="h5">
													<Columns
														columns={{
															xs: 'auto 5fr'
														}}
													>
														<Icon
															source={ChatMajor}
															color="base"
														/>
														{conversationMessages[selectedConvMsgIndex].message}
													</Columns>
												</Text>
											</TextContainer>
										</div>
									</div>
								: (( conversationMessages && conversationMessages[selectedConvMsgIndex] && conversationMessages[selectedConvMsgIndex].msg_type === "audio_response" ) ?
									<>
										<div className='messageWrapperTopPartDetails audioResponseTopWrapper'>
											<div></div>
										</div>
										<div className='messageWrapMiddleWrapper audioResponseWrapper' onClick={() => playAudioFile()}>
											<div className='videoThumbnailWrapper'></div>
											<video id="VideoPlayer" ref={audioPlayerRef} playsInline="" disablePictureInPicture="" controls="" className="audioVideoPlayer" src={conversationMessages[selectedConvMsgIndex].message_json.audio_url} onLoadedData={() => audioLoadedData()} onTimeUpdate={() => audioTimeUpdate()}></video>
											<div className='mediaPlayerHeaderWrapper'>
												<div className='mediaPlaybackBarWrapper' onClick={(event) => changeAudioPlaybackPosition(event)} ref={audioPlaybackBarRef}>
													<div className='mediaPlaybackBar' id='mediaPlaybackBar'></div>
												</div>
											</div>
											<div className='playerControlHeader'>
												<div className='playerControlHeaderInner'>
													<div className='playerRunningTimeWrapper'>
														<span id="audioPlaybackCurrentTime">00:00</span> / <span id="audioPlaybackDuration">00:00</span>
													</div>												
													<Button className='playerSpeedWrapper' onClick={(event) => audioChangeSpeed(event)}>
														<div className='playerSpeedInnerWrapper' id="playerSpeedInnerWrapper" ref={playerSpeedInnerWrapperRef} data-speed="1">1x</div>
													</Button>
												</div>
											</div>
											<svg id="mainSVG" className="soundWave" ref={soundWaveRef}><g><polyline ref={polyLineRef} id="poly"></polyline></g></svg>
											{audioVideoPlayerPlayFlag &&
												<Button className='playerPlayResponseBtn' onClick={() => playAudioFile()}>
													<svg fill="none" height="96" width="96" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z" fill="#fff" fillRule="evenodd"></path><path clipRule="evenodd" d="M37.326 33.822c0-2.408 2.695-3.835 4.687-2.481l20.862 14.178c1.752 1.19 1.752 3.772 0 4.963L42.013 64.66c-1.992 1.354-4.687-.072-4.687-2.48V33.821z" fill="#000" fillRule="evenodd"></path></svg>
												</Button>
											}
											<div className="playerControlFooter">
												<div className='playerFooterContentWrapper'></div>
											</div>
										</div>
									</>
								: (( conversationMessages && conversationMessages[selectedConvMsgIndex] && conversationMessages[selectedConvMsgIndex].msg_type === "video_response" ) &&
									<>
										<div className='messageWrapperTopPartDetails videoResponseTopWrapper'>
											<div></div>
										</div>
										<div className='messageWrapMiddleWrapper videoResponseWrapper' onClick={() => playAudioFile()}>
											<div className='videoThumbnailWrapper'></div>
											<video id="VideoPlayer" ref={audioPlayerRef} playsInline="" disablePictureInPicture="" controls="" className="audioVideoPlayer" src={conversationMessages[selectedConvMsgIndex].message_json.video_url} onLoadedData={() => audioLoadedData()} onTimeUpdate={() => audioTimeUpdate()}></video>
											<div className='mediaPlayerHeaderWrapper'>
												<div className='mediaPlaybackBarWrapper' onClick={(event) => changeAudioPlaybackPosition(event)} ref={audioPlaybackBarRef}>
													<div className='mediaPlaybackBar' id='mediaPlaybackBar'></div>
												</div>
											</div>
											<div className='playerControlHeader'>
												<div className='playerControlHeaderInner'>
													<div className='playerRunningTimeWrapper'>
														<span id="audioPlaybackCurrentTime">00:00</span> / <span id="audioPlaybackDuration">00:00</span>
													</div>												
													<Button className='playerSpeedWrapper' onClick={(event) => audioChangeSpeed(event)}>
														<div className='playerSpeedInnerWrapper' id="playerSpeedInnerWrapper" ref={playerSpeedInnerWrapperRef} data-speed="1">1x</div>
													</Button>
												</div>
											</div>
											{audioVideoPlayerPlayFlag &&
												<Button className='playerPlayResponseBtn' onClick={() => playAudioFile()}>
													<svg fill="none" height="96" width="96" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z" fill="#fff" fillRule="evenodd"></path><path clipRule="evenodd" d="M37.326 33.822c0-2.408 2.695-3.835 4.687-2.481l20.862 14.178c1.752 1.19 1.752 3.772 0 4.963L42.013 64.66c-1.992 1.354-4.687-.072-4.687-2.48V33.821z" fill="#000" fillRule="evenodd"></path></svg>
												</Button>
											}
											<div className="playerControlFooter">
												<div className='playerFooterContentWrapper'></div>
											</div>
										</div>
									</>
								))))}
								{!enableReplyConversation &&						
									<div className={`messageWrapBottomWrapper ${( conversationMessages && conversationMessages[selectedConvMsgIndex] && conversationMessages[selectedConvMsgIndex].msg_type === "audio_response" ) ? "audioResponseFooter" : ""} ${( conversationMessages && conversationMessages[selectedConvMsgIndex] && conversationMessages[selectedConvMsgIndex].msg_type === "video_response" ) ? "videoResponseFooter" : ""}`}>
										<div className='messageWrapBottomHeader'>
											<div className='messageWrapContactInfo'>
												<h4 className='contactInfoName'>
													<span className='nameWrapper'>{selectedConversation.visitor_name}</span>
													<Tooltip content={"(✔) This respondent has consented to your stated terms when sharing their contact details."} dismissOnMouseOut>
														<div className="consentTickWrapper">✔</div>
													</Tooltip>
												</h4>
												<span className='contactInfoDate'>{moment(selectedConversation.conversationUpdatedAt).format("DD MMM YY @ HH:mm")}</span>
												{selectedConversation.visitor_email &&
													<div className='contactInfoEmail'>{selectedConversation.visitor_email}</div>
												}
											</div>
											<div className='messageAnsweActionWrapper'>
												{selectedConversation.visitor_email &&
													<Button primary className="answerReplayBtnWrapper" onClick={() => enableReplyConversationFunc()}>
														Reply to {selectedConversation.visitor_name} 
														<Icon
															source={ReplayMinor}
															color="base"
															/>
													</Button>
												}
											</div>
										</div>
										<div className='messageWrapBottomFooter'>
											<div className='paginationListWrapper'>
												<div className='paginationListInnerWrapper'>
													<div className='paginationListInnerWrapping'>
														{conversationMessages && conversationMessages.map((convMsg, index) => {
															if ( convMsg.msg_type !== "user_clicked" || ( convMsg.msg_type === "user_clicked" && convMsg.message_json && convMsg.message_json.part_options && convMsg.message_json.part_options.type && convMsg.message_json.part_options.type !== "open_ended" ) ){
																return(														
																	<>
																		<button className={`conversationMessageBtnWrap ${selectedConvMsgIndex === index ? "selectedBtn" : ""}`} onClick={() => setSelectedConvMsgIndex(index)}>
																			{convMsg.msg_type === "user_clicked" ?
																				<div className='answerThubmnailOption' style={{backgroundImage: `url(${require("../../../assests/images/multiple_choice_response.png")}`}}></div>
																			: convMsg.msg_type === "audio_response" ?
																				<div className='answerThubmnailOption' style={{backgroundImage: `url(${require("../../../assests/images/audio_response.png")}`}}></div>
																			: convMsg.msg_type === "video_response" ?
																				<div className='answerThubmnailOption videoResponseThumbnailWrapper' style={{backgroundImage: `url(${convMsg.message_json.thumbnail_url})`}}></div>
																			:
																				<div className='answerThubmnailOption' style={{backgroundImage: `url(${require("../../../assests/images/text_response.png")}`}}></div>
																			}
																			<span className='answerDateTimeWrapper'>
																				<span className='answerDateWrapper'>{moment(convMsg.createdAt).format("DD MMM YY")}</span>
																				<span className='answerTimeWrapper'>{moment(convMsg.createdAt).format("HH:mm")}</span>
																			</span>
																		</button>
																	</>
																)
															}else{
																return <></>
															}
														})}
														<div className='scrollLast' ref={scrollRightRef}></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
								{enableReplyConversation &&
									<div className='replyOptionOutterPannel'>
										<div className='replyOptionPannelWrapper'>
											<div className='replyOptionCloseWrap'>
												<Button onClick={() => enableReplyConversationFunc()}><svg height="12" width="12" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><path clipRule="evenodd" d="M1.707.293A1 1 0 00.293 1.707L4.536 5.95.293 10.192a1 1 0 101.414 1.415L5.95 7.364l4.242 4.243a1 1 0 001.415-1.415L7.364 5.95l4.243-4.243A1 1 0 0010.193.293L5.95 4.536 1.707.293z" fillRule="evenodd"></path></svg></Button>
											</div>
											<div className='replyOptionTextOpWrapper'>
												<div>
													<p className='optionTextReplyWrapper'>How would you like to reply to {selectedConversation.visitor_name}?</p>
													<div className='spaceBetweenReply'></div>
													<div className='replyOptionWrapper'>
														<Button onClick={() => toggleCreatePopup()}>
															<svg fill="none" height="22" width="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 54"><path d="M34 24c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10z"></path><path clipRule="evenodd" d="M24 45c11.598 0 21-9.402 21-21S35.598 3 24 3 3 12.402 3 24s9.402 21 21 21zm0 3c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z" fillRule="evenodd"></path><rect height="3" rx="1.5" width="26" x="11" y="51"></rect></svg>
															<span>Video</span>
														</Button>
														<Button onClick={() => enableAudioResponseCollect()}>
															<svg height="22" width="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 25"><path clipRule="evenodd" d="M14 0a4 4 0 00-4 4v8a4 4 0 008 0V4a4 4 0 00-4-4zM6 8.846a.847.847 0 011.696 0v3.33c0 3.476 2.822 6.293 6.304 6.293s6.304-2.817 6.304-6.293v-3.33a.847.847 0 011.696 0v3.33a7.991 7.991 0 01-7.152 7.93v2.2h2.657a.847.847 0 110 1.694h-7.01a.847.847 0 110-1.693h2.657v-2.201A7.991 7.991 0 016 12.176v-3.33z" fillRule="evenodd"></path></svg>
															<span>Audio</span>
														</Button>
														<Button onClick={() => enableTextResponseCollect()}>
															<svg height="24" width="27" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M6 3a2 2 0 00-2 2v15a2 2 0 002 2h15a2 2 0 002-2V5a2 2 0 00-2-2H6zm6.252 12.993h-1.264V18h5.224v-2.007h-1.288V8.837h1.682v1.436h2.64V6.896h-11.1v3.377h2.625V8.837h1.48v7.156z" fillRule="evenodd"></path></svg>
															<span>Text</span>
														</Button>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
							</>
						:
							<div className='d-flex align-items-center justify-content-center' style={{height:"100%", color: "#000"}}>
								<p>No messages to show</p>
							</div>
						)}
					</div>
				</Columns>
			</div>
			{toggleCreatePopup &&
				<Modal
					instant
					large
					open={createPopup}
					onClose={toggleCreatePopup}
					className="createPopupLarge"
				>
					<Modal.Section>
						<div className="row">
							<div className="col-auto videoRightArea">
								<h3>Upload / Choose Video</h3>
								<ul>
									{(hasWebcam && hasMicroPhone) &&
										<li className={videoOption === "record" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('record')}>
											<span className="material-icons-outlined">videocam</span> Record video
										</li>
									}
									<li className={videoOption === "upload" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('upload')}>
										<span className="material-icons-outlined">file_upload</span> Upload File
									</li>
									<li className={videoOption === "library" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('library')}>
										<span className="material-icons-outlined">video_library</span> Library
									</li>
									<li className={videoOption === "screen" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('screen')}>
										<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 0H2C.875 0 0 .906 0 2v9c0 1.125.875 2 2 2h4.625l-.313 1.5H4.75a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h8.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75h-1.594l-.312-1.5H16c1.094 0 2-.875 2-2V2c0-1.094-.906-2-2-2Zm-5.875 14.5H7.844l.312-1.5h1.656l.313 1.5ZM16.5 11c0 .281-.25.5-.5.5H2a.494.494 0 0 1-.5-.5V2c0-.25.219-.5.5-.5h14c.25 0 .5.25.5.5v9Z" fill="#50545E"></path></svg> &nbsp; &nbsp;Record Screen
									</li>
									<li className={videoOption === "stock" ? "videoOptions active" : "videoOptions"} onClick={() => changeVideoOption('stock')}>
										<svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.625 15.125V3.25C1.57 3.25.75 4.11.75 5.125V15.75c0 1.406 1.094 2.5 2.5 2.5h14.375c1.016 0 1.875-.82 1.875-1.875H3.875c-.703 0-1.25-.547-1.25-1.25ZM20.75.75H7a2.492 2.492 0 0 0-2.5 2.5V12c0 1.406 1.094 2.5 2.5 2.5h13.75c1.367 0 2.5-1.094 2.5-2.5V3.25c0-1.367-1.133-2.5-2.5-2.5ZM8.875 12.625H7A.617.617 0 0 1 6.375 12v-1.563h2.5v2.188Zm0-4.063h-2.5V6.688h2.5v1.875Zm0-3.75h-2.5V3.25c0-.313.273-.625.625-.625h1.875v2.188ZM17 12.625h-6.25V8.562H17v4.063Zm0-5.938h-6.25V2.625H17v4.063ZM21.375 12a.642.642 0 0 1-.625.625h-1.875v-2.188h2.5V12Zm0-3.438h-2.5V6.688h2.5v1.875Zm0-3.75h-2.5V2.626h1.875c.313 0 .625.313.625.625v1.563Z" fill="#50545E"></path></svg> &nbsp; &nbsp;Stock Videos
									</li>
								</ul>
							</div>
							<div className={`videoLeftArea col ${videoOption === "library" ? "libraryLeft" : ""}`}>
								<div>
									{videoOption === "upload" ?
										<>
											{videoUploadedFlag ?
												<div className="videoNewUploadWrapper">
													<video id="VideoPlayer" muted loop autoPlay playsinline="" disablepictureinpicture="" controls="" className="BaseVideoSize-sc-cvf9gr-0 UploadFile__Video-sc-1th8vxy-2 dKMFnV kUaiBp" src={blobURL}></video>
													{videoUploadLoader ?
														<div className="loaderWrapper">
															<Spinner accessibilityLabel="Spinner example" size="large" />
														</div>
														:
														<div className="innerOptionWrapper">
															<div className="d-flex align-items-center optionTextValueWrapper">
																<div className="optionText">
																	Use this video?
																</div>
																<div className="optionValueWrapper">
																	<div className="noOptionValue" onClick={() => cancelFileUpload()}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18">
																			<path fill="#0E1658" d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
																		</svg>
																	</div>
																	<div className="yesOptionValue" onClick={() => continueFileUpload()}>
																		<svg width="20" height="16" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M16.313 3.348a1.4 1.4 0 0 0 0-2.004c-.563-.528-1.442-.528-1.97 0L6.329 9.359 2.812 5.844c-.562-.528-1.44-.528-1.968 0a1.4 1.4 0 0 0 0 2.004l4.5 4.5c.246.28.633.421.984.421.352 0 .703-.14.984-.421l9-9Z" fill="#01BE81"></path>
																		</svg>
																	</div>
																</div>
															</div>
														</div>
													}

												</div>
												:
												<>
													<h3 className="mt-4 mb-4 text-center">Upload file</h3>
													<div className="uploadWrapper cursorPointer" onClick={() => createUpload()}>
														<div className="text-center">
															<svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M83.906 30.469C84.531 28.75 85 27.03 85 25c0-8.281-6.719-15-15-15-3.125 0-6.094.938-8.438 2.656C57.344 5.156 49.22 0 40 0 26.094 0 15 11.25 15 25v1.406C6.25 29.375 0 37.813 0 47.5 0 60 10 70 22.5 70H80c10.938 0 20-8.906 20-20 0-9.531-6.875-17.656-16.094-19.531ZM33.594 35l13.75-13.75C47.656 20.781 48.594 20 50 20c1.25 0 2.188.781 2.5 1.25L66.25 35c1.563 1.406 1.563 3.75 0 5.156-1.406 1.563-3.75 1.563-5.156 0l-7.344-7.343V56.25c0 2.188-1.719 3.75-3.75 3.75-2.188 0-3.75-1.563-3.75-3.75V32.812l-7.5 7.344c-1.406 1.563-3.75 1.563-5.156 0-1.563-1.406-1.563-3.75 0-5.156Z" fill="#7D8087"></path>
															</svg>
															<br />
															<p className="mt-3">Please upload your files</p>
														</div>
													</div>
												</>
											}
										</>
										: (videoOption === "library" ?
											<div className="videoLibraryWrapper slimScroll">
												<div className="videoLibraryWrap">
													{videoLibraryLoader ?
														<VideoSkeleton />
														: (videoLibrary.length > 0 ?
															<>
																<div className="mt-4 mb-4 row">
																	{videoLibrary.map((video, index) => {
																		return (
																			<div key={index} onClick={() => librarySelectedVideo(video)} className="mt-3 mb-3 col-4">
																				<div className="interactiveWrapper">
																					<div className="thumbnailWrapper">
																						{(video['video_thumbnail'] === "" || video['video_thumbnail'] === null) ?
																							<img src={"https://storage.googleapis.com/roundclicksview/Popup%20Template/Image_not_available.png"} width='' className='img-fluid' alt={video['Video_Name']} />
																							:
																							<img src={video.video_thumbnail} className='img-fluid' alt={video['video_name']} />
																						}
																					</div>
																					<div className="contentWrapper">
																						<h5>{video.video_name}</h5>
																						<div className="contentTimeWrap">
																							<p>{moment(video.created_at).format("MM/DD/YYYY")}</p>
																						</div>
																					</div>
																					{videoLibrarySelectVideo === video.video_library_id &&
																						<div className="loaderWrapper">
																							<Spinner accessibilityLabel="Spinner example" size="large" />
																						</div>
																					}
																				</div>
																			</div>
																		)
																	})}
																</div>
																{VideoLibrarySelectedLoader &&
																	<div className="loaderWrapper">
																		<Spinner accessibilityLabel="Spinner example" size="large" />
																	</div>
																}
															</>
															:
															<>
															</>

														)}
												</div>
											</div>
											: (videoOption === "record" ?
												<>
													<div className="videoNewUploadWrapper">
														{(hasCameraPermission !== "denied" && hasMicroPhonePermission !== "denied") ?
															<>
																<video autoPlay muted loop playsInline disablePictureInPicture="" className={`videoRecorderStream ${mirrorImageFlag ? "mirrorImage" : ""}`} id="videoRecorderStream" ref={videoStreamRef} src={videoStream}></video>
																{videoRecordLoader ?
																	<div className="loaderWrapper">
																		<Spinner accessibilityLabel="Spinner example" size="large" />
																	</div>
																	:
																	<>
																		{recordCounterFlag &&
																			<div className="countDownRecordingLayout">
																				<div className="counterText" id="recordCounterText">{recordCounter}</div>
																			</div>
																		}
																		{!recordingFlag &&
																			<div className="VideoRecordControlTop">
																				<div className="videoRecordDeviceOptionWrap">
																					<div className="videoInputWrapper" onClick={() => setVideoOptionModal(!videoOptionModal)} ref={videoOptionRef}>
																						<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none" viewBox="0 0 18 16"><path fill="white" d="M17.406 3.219a1.16 1.16 0 00-1.187-.094L13 4.625V4c0-1.094-.906-2-2-2H2C.875 2 0 2.906 0 4v8c0 1.125.875 2 2 2h9c1.094 0 2-.875 2-2v-.594l3.219 1.5c.156.063.343.125.531.125.219 0 .469-.094.656-.219.375-.218.594-.624.594-1.062V4.281a1.22 1.22 0 00-.594-1.062zM11.5 12c0 .281-.25.5-.5.5H2a.494.494 0 01-.5-.5V4c0-.25.219-.5.5-.5h9c.25 0 .5.25.5.5v8zm5-.625L13 9.781V6.25l3.5-1.594v6.719z"></path></svg>
																						{videoOptionModal &&
																							<div className="videoInputOptionWrap">
																								<div className="videoInputOptionInner">
																									<ul>
																										{Object.keys(videoDeviceInput).map((deviceLabel, index) => {
																											return (
																												<li key={index} className={`deviceLabel ${(deviceLabel === selectedVideoDevice) ? "activeDevice" : ""}`} onClick={() => changeVideoInputOption(deviceLabel)}>{deviceLabel}</li>
																											)
																										})}
																									</ul>
																								</div>
																							</div>
																						}
																					</div>
																					<div className="audioInputWrapper" onClick={() => setAudioOptionModal(!audioOptionModal)} ref={audioOptionRef}>
																						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path fill="#fff" d="M12.75 6a.74.74 0 00-.75.75V8c0 2.344-2.031 4.219-4.406 4C5.5 11.781 4 9.937 4 7.844V6.75A.76.76 0 003.25 6a.74.74 0 00-.75.75v1.031c0 2.782 2 5.282 4.75 5.657V14.5h-1.5a.74.74 0 00-.75.75c0 .438.313.75.75.75h4.5a.74.74 0 00.75-.75.76.76 0 00-.75-.75h-1.5v-1.031C11.406 13.094 13.5 10.78 13.5 8V6.75a.76.76 0 00-.75-.75zM8 11a3 3 0 003-3V3a3 3 0 00-6 0v5a3 3 0 003 3zM6.5 3A1.5 1.5 0 018 1.5c.813 0 1.5.688 1.5 1.5v5A1.5 1.5 0 018 9.5 1.48 1.48 0 016.5 8V3z"></path></svg>
																						<div className="audioTestContainerWrapper">
																							{[...Array(10)].map((e, i) => {
																								return (
																									<div key={i} className={`audioTestInnerWrap ${i < audioLevel ? "activeAudioTest" : ""}`}></div>
																								)
																							})}
																						</div>
																						{audioOptionModal &&
																							<div className="audioInputOptionWrap">
																								<div className="audioInputOptionInner">
																									<ul>
																										{Object.keys(audioDeviceInput).map((deviceLabel, index) => {
																											return (
																												<li key={index} className={`deviceLabel ${(deviceLabel === selectedAudioDevice) ? "activeDevice" : ""}`} onClick={() => changeAudioOption(deviceLabel)}>{deviceLabel}</li>
																											)
																										})}
																									</ul>
																								</div>
																							</div>
																						}
																					</div>
																				</div>
																				<div className="videoRecordDesktopMobilePreviewWrap">
																					<div className="videoRecordDesktopOuterWrap" onClick={() => changeVideoPreview('desktop')}>
																						<div className="videoRecordDesktopInnerWrap">
																							<svg className={`${verticalFlag ? "inActivePreview" : ""}`} xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="white" viewBox="0 0 18 16"><path fill="#fff" d="M16 0H2C.875 0 0 .906 0 2v9c0 1.125.875 2 2 2h4.625l-.313 1.5H4.75a.74.74 0 00-.75.75c0 .438.313.75.75.75h8.5a.74.74 0 00.75-.75.76.76 0 00-.75-.75h-1.594l-.312-1.5H16c1.094 0 2-.875 2-2V2c0-1.094-.906-2-2-2zm-5.875 14.5H7.844l.312-1.5h1.656l.313 1.5zM16.5 11c0 .281-.25.5-.5.5H2a.494.494 0 01-.5-.5V9.5h15V11zm0-3h-15V2c0-.25.219-.5.5-.5h14c.25 0 .5.25.5.5v6z"></path></svg>
																						</div>
																					</div>
																					<div className="videoRecordPreviewSepartor"></div>
																					<div className="videoRecordMobileOuterWrap" onClick={() => changeVideoPreview('mobile')}>
																						<div className="videoRecordMobileInnerWrap">
																							<svg className={`${!verticalFlag ? "inActivePreview" : ""}`} xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="white" viewBox="0 0 16 17"><path fill="#fff" d="M11.5.5h-7c-1.125 0-2 .906-2 2v12c0 1.125.875 2 2 2h7c1.094 0 2-.875 2-2v-12c0-1.094-.906-2-2-2zm.5 14c0 .281-.25.5-.5.5h-7a.494.494 0 01-.5-.5V12h8v2.5zm0-4H4v-8c0-.25.219-.5.5-.5h7c.25 0 .5.25.5.5v8zm-4 3.75a.74.74 0 00.75-.75.76.76 0 00-.75-.75.74.74 0 00-.75.75c0 .438.313.75.75.75z"></path></svg>
																						</div>
																					</div>
																				</div>
																			</div>
																		}
																		{recordTimerFlag &&
																			<div className="videoRecordTimerWrapper">
																				<div className="videoRecordTimerInner" ref={recordTimerInnerRef}>{secondsToHourFunction()}</div>
																			</div>
																		}
																		{verticalFlag &&
																			<div className="orientationPreviewOverlay">
																				<div className="orientationPreviewOverlayLeft"></div>
																				<div className="orientationPreviewOverlayRight"></div>
																			</div>
																		}
																		<div className="videoRecordControlBottom">
																			<div className="mirrorImageWrapper" >
																				{!recordingFlag &&
																					<div className="mirrorImageInnerWrap cursorPointer" onClick={() => setMirrorImageFlag(!mirrorImageFlag) }>
																						<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 5H9.96875V6.75C9.96875 7.0625 10.1562 7.34375 10.4375 7.46875C10.7188 7.5625 11.0625 7.5 11.25 7.28125L13.75 4.78125C14.0625 4.5 14.0625 4.03125 13.75 3.75L11.25 1.25C11.0625 1.03125 10.7188 0.96875 10.4375 1.0625C10.1562 1.1875 9.96875 1.46875 9.96875 1.75V3.5H5.5C2.4375 3.5 0 5.96875 0 9C0 9.4375 0.3125 9.75 0.75 9.75C1.15625 9.75 1.5 9.4375 1.5 9C1.5 6.8125 3.28125 5 5.5 5ZM15.25 8.25C14.8125 8.25 14.5 8.59375 14.5 9C14.5 11.2188 12.6875 13 10.5 13H6V11.25C6 10.9688 5.8125 10.6875 5.53125 10.5625C5.25 10.4688 4.90625 10.5312 4.71875 10.75L2.21875 13.25C1.90625 13.5312 1.90625 14 2.21875 14.2812L4.71875 16.7812C4.90625 17 5.25 17.0625 5.53125 16.9688C5.8125 16.8438 6 16.5625 6 16.25V14.5H10.5C13.5312 14.5 16 12.0625 16 9C16 8.59375 15.6562 8.25 15.25 8.25Z" fill="white"></path></svg>
																						<div className="mirrorImageTextWrap">Mirror image</div>
																					</div>
																				}
																			</div>
																			<div className="videoRecordOptionWrapper">
																				{previewVideoRecorder ?
																					<>
																						<div className="innerOptionWrapper">
																							<div className="d-flex align-items-center optionTextValueWrapper">
																								<div className="optionText">
																									Use this video?
																								</div>
																								<div className="optionValueWrapper">
																									<div className="noOptionValue" onClick={() => cancelVideoRecord()}>
																										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18">
																											<path fill="#0E1658" d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
																										</svg>
																									</div>
																									<div className="yesOptionValue" onClick={() => continueVideoRecord()}>
																										<svg width="20" height="16" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																											<path d="M16.313 3.348a1.4 1.4 0 0 0 0-2.004c-.563-.528-1.442-.528-1.97 0L6.329 9.359 2.812 5.844c-.562-.528-1.44-.528-1.968 0a1.4 1.4 0 0 0 0 2.004l4.5 4.5c.246.28.633.421.984.421.352 0 .703-.14.984-.421l9-9Z" fill="#01BE81"></path>
																										</svg>
																									</div>
																								</div>
																							</div>
																						</div>
																						<div className={`videoRecordDiscardOtion ${!recordingFlag ? "hideOption" : ""}`} onClick={(e) => changeVideoRecordMuteUnmute(e)}>
																							{!videoRecordMutedFlag ?
																								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
																									<path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" fill="white" />
																								</svg>
																								:
																								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
																									<path d="M12.79 9c0-1.3-.72-2.42-1.79-3v6c1.06-.58 1.79-1.7 1.79-3zM2 7v4h3l4 4V3L5 7H2zm9-5v1.5c2.32.74 4 2.93 4 5.5s-1.68 4.76-4 5.5V16c3.15-.78 5.5-3.6 5.5-7S14.15 2.78 11 2z" fill="white" />
																								</svg>
																							}
																						</div>
																					</>
																					:
																					<>
																						<div className="videoRecordButtonWrap" onClick={() => startStopRecording()}>
																							<div className="videoRecordButtonIcon">
																								<div className="videoRecordBtnIconInner"></div>
																							</div>
																							<div className="videoRecordBtnTextWrapper">{!recordingFlag ? "Start" : "Stop"} recording</div>
																						</div>
																						<div className={`videoRecordDiscardOtion ${!recordingFlag ? "hideOption" : ""}`} onClick={() => discardVideoRecording()}>
																							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.25 2.5H11.9062L10.8438 0.75C10.5625 0.28125 10.0938 0 9.5625 0H6.40625C5.875 0 5.40625 0.28125 5.125 0.75L4.0625 2.5H1.75C1.3125 2.5 1 2.84375 1 3.25C1 3.6875 1.3125 4 1.75 4H2L2.65625 14.5938C2.6875 15.4062 3.34375 16 4.15625 16H11.8125C12.625 16 13.2812 15.4062 13.3125 14.5938L14 4H14.25C14.6562 4 15 3.6875 15 3.25C15 2.84375 14.6562 2.5 14.25 2.5ZM6.40625 1.5H9.5625L10.1562 2.5H5.8125L6.40625 1.5ZM11.8125 14.5H4.15625L3.5 4H12.4688L11.8125 14.5Z" fill="#FFFFFF"></path></svg>
																						</div>
																					</>
																				}
																			</div>
																			<div className="videoRecordEmptyWrapper"></div>
																		</div>
																	</>
																}
															</>
															:
															<>
																<div className="permissionErrorWrapper">
																	<div className="permissionErrorContent">
																		<div className="permissionErrorContentInner">
																			<p>Please reset
																				{(hasCameraPermission === "denied" && hasMicroPhonePermission === "denied") ?
																					" camera & microphone "
																					: (hasCameraPermission === "denied" ?
																						" camera "
																						:
																						" microphone "
																					)
																				}
																				permissions and try again.
																			</p>
																		</div>
																	</div>
																</div>
															</>
														}

													</div>
												</>
											: (videoOption === "screen") ?
												<>
													<div className="permissionErrorWrapper">
														<div className="permissionErrorContent">
															<div className="permissionErrorContentInner">
																<p><strong>Hey, please install the Chrome Extension to use this feature.</strong></p>
																<p>The extension will allow you to record your screen quickly. Need help setting up the extension?</p>
															</div>
														</div>
													</div>
												</>
											: (videoOption === "stock") &&
												<div className="videoStockWrapper">
													<div className="videoStockHeaderWrapper">
														Stock Videos
													</div>
													<div style={{height: '225px'}}>
														<Autocomplete
															options={optionsH}
															selected={selectedOptions}
															onSelect={updateSelection}
															textField={textField}
														/>
													</div>
												</div>
											))}
								</div>
							</div>
						</div>
					</Modal.Section>
				</Modal>
			}
			{enableAudioCollect &&
				<Modal
					instant
					large
					open={enableAudioCollect}
					onClose={enableAudioResponseCollect}
					className="createPopupLarge"
				>
					<Modal.Section>
						<div className='AudioRecordModalWrap'>
							<div className="audioRecordOuterWrap" id="audioRecordOuterWrap">
								<div className="audioRecordInnerWrap">
									<div className="audioRecordContainer">
										<canvas className="audioRecorderStream" ref={audioStreamRef}></canvas>
										{recordCounterFlag &&
											<div className="audioRecorderCounter">
												<div className="counterText" id="recordCounterText">{recordCounter}</div>
											</div>
										}
										<div className="audioResultContainer">
											<audio muted autoPlay loop id="audioResultTag" ref={audioResultTagRef1}></audio>
											<audio muted autoPlay loop id="audioResultTag2" ref={audioResultTagRef2}></audio>
										</div>
										{videoRecordLoader ?
											<div className="loaderWrapper">
												<Spinner accessibilityLabel="Spinner example" size="large" />
											</div>
										:
											<>
												{!recordingFlag &&
													<div className="audioTopOptionWrapper">
														<div className="audioTopOptionInnerWrapper">
															<div className="audioDeviceInputWrapper">
																<div className="audioInputWrapper" onClick={() => audioInputOptionToggle()} ref={audioOptionRef}>
																	<div className="audioInputInnerWrap">
																		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
																			<path fill="#fff" d="M12.75 6a.74.74 0 00-.75.75V8c0 2.344-2.031 4.219-4.406 4C5.5 11.781 4 9.937 4 7.844V6.75A.76.76 0 003.25 6a.74.74 0 00-.75.75v1.031c0 2.782 2 5.282 4.75 5.657V14.5h-1.5a.74.74 0 00-.75.75c0 .438.313.75.75.75h4.5a.74.74 0 00.75-.75.76.76 0 00-.75-.75h-1.5v-1.031C11.406 13.094 13.5 10.78 13.5 8V6.75a.76.76 0 00-.75-.75zM8 11a3 3 0 003-3V3a3 3 0 00-6 0v5a3 3 0 003 3zM6.5 3A1.5 1.5 0 018 1.5c.813 0 1.5.688 1.5 1.5v5A1.5 1.5 0 018 9.5 1.48 1.48 0 016.5 8V3z"></path>
																		</svg>
																		{audioOptionModal &&
																			<div className="audioInputOptionWrap">
																				<div className="audioInputOptionInner">
																					<ul>
																						{Object.keys(audioDeviceInput).map((deviceLabel, index) => {
																							return (
																								<li key={index} className={`deviceLabel ${(deviceLabel === selectedAudioDevice) ? "activeDevice" : ""}`} onClick={() => changeAudioOption(deviceLabel)}>{deviceLabel}</li>
																							)
																						})}
																					</ul>
																				</div>
																			</div>
																		}
																	</div>
																	<div className="toolTipText"><p>Choose Microphone</p></div>																				
																</div>
															</div>
														</div>
													</div>
												}
												{recordTimerFlag &&
													<div className="videoRecordTimerWrapper">
														<div className="videoRecordTimerInner" ref={recordTimerInnerRef}>{secondsToHourFunction()}</div>
													</div>
												}
												<div className="audioBottomOptionWrapper">
													{previewAudioRecorder ?
														<>
															<div className="innerOptionWrapper" style={{marginBottom:0}}>
																<div className="d-flex align-items-center optionTextValueWrapper">
																	<div className="optionText">
																		Use this audio?
																	</div>
																	<div className="optionValueWrapper">
																		<div className="noOptionValue" onClick={(event) => cancelAudioRecord(event)}>
																			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18">
																				<path fill="#0E1658" d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
																			</svg>
																		</div>
																		<div className="yesOptionValue" onClick={(event) => continueAudioRecord(event)}>
																			<svg width="20" height="16" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<path d="M16.313 3.348a1.4 1.4 0 0 0 0-2.004c-.563-.528-1.442-.528-1.97 0L6.329 9.359 2.812 5.844c-.562-.528-1.44-.528-1.968 0a1.4 1.4 0 0 0 0 2.004l4.5 4.5c.246.28.633.421.984.421.352 0 .703-.14.984-.421l9-9Z" fill="#01BE81"></path>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
															<div className={`videoRecordDiscardOtion ${!recordingFlag ? "hideOption" : ""}`} onClick={(e) => changeAudioRecordMuteUnmute(e)}>
																{!recordMutedFlag ?
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
																		<path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" fill="white" />
																	</svg>
																	:
																	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
																		<path d="M12.79 9c0-1.3-.72-2.42-1.79-3v6c1.06-.58 1.79-1.7 1.79-3zM2 7v4h3l4 4V3L5 7H2zm9-5v1.5c2.32.74 4 2.93 4 5.5s-1.68 4.76-4 5.5V16c3.15-.78 5.5-3.6 5.5-7S14.15 2.78 11 2z" fill="white" />
																	</svg>
																}
															</div>
														</>
														:
															<>
																<div className="audioRecordOptionWrapper" onClick={() => startStopAudioRecording()}>
																	{!recordingFlag ?
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 16 16">
																			<path fill="white" d="M12.75 6a.74.74 0 00-.75.75V8c0 2.344-2.031 4.219-4.406 4C5.5 11.781 4 9.937 4 7.844V6.75A.76.76 0 003.25 6a.74.74 0 00-.75.75v1.031c0 2.782 2 5.282 4.75 5.657V14.5h-1.5a.74.74 0 00-.75.75c0 .438.313.75.75.75h4.5a.74.74 0 00.75-.75.76.76 0 00-.75-.75h-1.5v-1.031C11.406 13.094 13.5 10.78 13.5 8V6.75a.76.76 0 00-.75-.75zM8 11a3 3 0 003-3V3a3 3 0 00-6 0v5a3 3 0 003 3zM6.5 3A1.5 1.5 0 018 1.5c.813 0 1.5.688 1.5 1.5v5A1.5 1.5 0 018 9.5 1.48 1.48 0 016.5 8V3z"></path>
																		</svg>
																	:
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="#fff" d="M21 6c0-1.64-1.36-3-3-3H6C4.312 3 3 4.36 3 6v12c0 1.688 1.313 3 3 3h12c1.64 0 3-1.313 3-3V6z"></path></svg>
																	}

																</div>
																<div className="audioRecordCancelWrapper" onClick={() => discardAuidoRecording()}>
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 16 16">
																		<path fill="#fff" d="M12.75 11.75L9.031 8l3.719-3.719a.684.684 0 000-1.031.684.684 0 00-1.031 0L8 6.969 4.25 3.25a.684.684 0 00-1.031 0 .684.684 0 000 1.031L6.938 8l-3.72 3.75a.684.684 0 000 1.031.684.684 0 001.032 0L8 9.062l3.719 3.72a.684.684 0 001.031 0 .684.684 0 000-1.032z"></path>
																	</svg>
																</div>
															</>
													}
												</div>
											</>
										}
									</div>
								</div>
							</div>
						</div>
					</Modal.Section>
				</Modal>
			}
			{enableTextCollect &&
				<Modal
					instant
					small
					open={enableTextCollect}
					onClose={enableTextResponseCollect}
				>
					<Modal.Section>
						<div className='textResponseContainer'>
							<Form onSubmit={handleTextResponseSubmit}>
								<FormLayout>
									<TextField
										label="Text Response"
										value={textResponseContent}
										onChange={changeTextResponseContent}
										multiline={4}
										autoComplete="off"
									/>
									<Button primary submit size="large" icon={SendMajor}>Send</Button>
								</FormLayout>
							</Form>
						</div>
					</Modal.Section>
				</Modal>
			}
			<div id="upload-file"></div>
		</>
	);
}

export default Interactions;