import { Card, Columns, Page, Text } from "@shopify/polaris";
import React from "react";

const Dashboard = () => {
	return (
		<div className="homePageWrapper container-md">
			<div className="row minHeight89vh">
				<div className="col-md-8">
					<div className="homePageLeftWrapper">
						<Text variant="headingXl" as="h1">Welcome to Muqala Video</Text>
						<div className="mt-4"></div>
						<Card
							title="Getting Started with Muqala"
							secondaryFooterActions={[{
								content: 'Create Muqala',
								url: "/user/video-creator"
							}]}
						>
							<Card.Section>
								<p>To begin your Muqala journey and create your first interactive video, click on the '+ New Muqala' menu located on the left side. You can find further guidance and support in our help center articles.</p>
							</Card.Section>
						</Card>
						<div className="mt-4"></div>
						<Card
							title="Recorded Interactions & Engagements"
							secondaryFooterActions={[{content: 'Check Interactions', url: "/user/interaction"}]}
						>
							<Card.Section>
								<p>Muqala records all user interactions and engagements, including answer selection, video and audio recordings, and user information submissions. These interactions are stored under the "All Interactions" section, labeled with the respective Muqala's name. You can access this section to review and respond to user responses with audio, video, or text. For more information, please consult our support articles.</p>
							</Card.Section>
						</Card>
						<div className="mt-4"></div>
						<Card
							title="Managing Leads in Muqala"
							secondaryFooterActions={[{content: 'Access Leads', url: "/user/leads"}]}
						>
							<Card.Section>
								<p>Muqala collects all leads information submitted by users in the 'All Leads' section, which can be easily exported to CSV format with a single click on the 'Export' button. For further details, please consult our support articles.</p>
							</Card.Section>
						</Card>
						<div className="mt-4"></div>
						<Card
							title="Muqala Support: Your Go-To Resource"
							secondaryFooterActions={[{content: 'Get Support'}]}
						>
							<Card.Section>
								<p>Before reaching out to our support team, check out our comprehensive support articles covering all Muqala-related topics. We're here to help whenever you need us.</p>
							</Card.Section>
						</Card>
					</div>
				</div>
				<div className="col-md-4 homePageRightWrapper">
					<div className="hpRightInnerWrapper">						
					</div>
				</div>
			</div>
		</div>
	)
}
export default Dashboard;