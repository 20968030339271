import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import Cookies from "js-cookie";
import {
	accountRoute
  } from "../Routesdata";
class Account extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			arrayValue: "null"
		};
	}

	componentDidMount() {
		// document.body.classList.add("bg-default-img");
	}

	componentWillMount() {
		this.getroutsArray();
	}

	componentDidUpdate() {
	}

	getroutsArray = () => {
		this.setState({
			arrayValue: accountRoute
		});
	};
	componentWillUnmount() {
		// document.body.classList.remove("bg-default-img");
	}
	getRoutes = routes => {
		const Token = Cookies.get("_prod_token");
		return routes.map((prop, key) => {
			if (prop.layout === "/account") {				
				if (typeof Token === "undefined" || !Token ) {
					return (
						<Route
							exact
							path={prop.path}
							element={<prop.component />}
							key={key}
						/>
					);
				} else {
					return <Route path="*" element={<Navigate to="/user/" replace />} />;
				}
			} else {
				return null;
			}
		});
	};
	render() {
		return (
			<Routes>
				{this.getRoutes(this.state.arrayValue)}
			</Routes>
		);
	}
}

export default Account;
