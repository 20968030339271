import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import {Link as ReactRouterLink} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { ProductContext } from "../store/index";
import { LogOutMinor, SettingsMajor, MobilePlusMajor, QuestionMarkInverseMinor, DeleteMinor, FolderPlusMajor, CustomerPlusMajor, MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import {
	userRoute,
	newVideoAskRoute
} from "../Routesdata";
import { ActionList, AppProvider, Avatar, Button, Card, ContextualSaveBar, FormLayout, Frame, Icon, Layout, Loading, Modal, Navigation, Page, Popover, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Text, TextContainer, TextField, Toast, TopBar } from '@shopify/polaris';
import { PostData } from "../services/PostData";
import VideoMenuSkeleton from "../components/VideoMenuSkeleton";


const User = () => {
	const [arrayValue, setArrayValue]							= useState([])
	const navigate														= useNavigate();
	const [innerLink]													= useState("/user/")
	const [menuLink, setMenuLink]									= useState([])
	const [menuSecondLink, setMenuSecondLink]					= useState([])
	// const [menuThirdLink, setMenuThirdLink]				= useState([])
	const [videoTotalPage, setVideoTotalPage]					= useState(1)
	const [videoSkeletonLoader, setVideoSkeletonLoader]	= useState(true);
	const [deleteActionFlag, setDeleteActionFlag]			= useState(false)
	const [moveToFolderFlag, setMoveToFolderFlag]			= useState(false)
	const [deleteFlag, setDeleteFlag]							= useState(false)
	const [deleteVideoDetail, setDeleteVideoDetail]			= useState(null)
	const [videoMoveToFolder, setVideoMoveToFolder]			= useState(null)
	const [newFolderModal, setNewFolderModal]					= useState(false)
	const [createFolderFlag, setCreateFolderFlag]			= useState(false)
	const [renameFolderModal, setRenameFolderModal]			= useState(false)
	const [folderName, setFolderName]							= useState("")
	const [renameFolderName, setRenameFolderName]			= useState("")
	const [videoFolderFlag, setVideoFolderFlag]				= useState(false)
	const [activePopover, setActivePopover]					= useState(null)
	const [videoActivePopover, setVideoActivePopover]		= useState(null)
	const [openFolderId, setOpenFolderId]						= useState(null)
	const [renameFolderFlag, setRenameFolderFlag]			= useState(false)
	const [renameVideoFolder, setRenameVideoFolder]			= useState(null);
	const [folderNameError, setFolderNameError]				= useState("");
	const [deleteFolderModal, setDeleteFolderModal]			= useState(false);
	const [deleteFolderLoader, setDeleteFolderLoader]		= useState(false);
	const [deleteFolderId, setDeleteFolderId]					= useState(null)
	const [deleteFolderName, setDeleteFolderName]			= useState("")

	const togglePopover = (id) => {
		setActivePopover((prevState) => (prevState === id ? null : id));
	};

	const toggleVideoPopover = (id) => {
		setVideoActivePopover((prevState) => (prevState === "Video_"+id ? null : "Video_"+id));
	};

	const {
		isLoading,
		setIsLoading,
		toastChildActive,
		setToastChildActive,
		toastMessage,		
		toastDuration,
		userData,
		setUserData,
		planData,
		setPlanData,
		setUserLoading,
		interactiveVideos,
		setInteractiveVideos,
		loadSideMenu,
		setLoadSideMenu,
		setToastMessage,
		folderVideos,
		setFolderVideos,
		setNewVideoFolderId,
		triggerNewFolder, 
		setTriggerNewFolder,
		setCreateFolderSuccess,
		triggerRenameFolder,
		setTriggerRenameFolder,
		setRenameFolderSuccess,
		triggerDeleteFolder,
		setTriggerDeleteFolder,
		setDeleteFolderSuccess,
		triggerFolderMuqala,
		setTriggerFolderMuqala,
		triggerMoveFolder,
		setTriggerMoveFolder,
		setMoveFolderSuccess,
		setCurrentFolderId
	}									= useContext(ProductContext)
	const location					= useLocation();
	
	useEffect(() => {
		getRoutesArray()		
		if( Cookies.get("_prod_token") ){
			getInteractiveVideo()
			getUserInformation()
		}
	}, [])

	useEffect(() => {
		if( Cookies.get("_prod_token") ){
			getInteractiveVideo()
		}
	}, [planData])

	useEffect(() => {
		if( loadSideMenu && Cookies.get("_prod_token") ){
			getInteractiveVideo()
			setLoadSideMenu(false)
		}
	},[loadSideMenu])

	useEffect(() => {
		let menuArray = []
		userRoute.map((value, index) => {
			if( typeof value.type !== "undefined" &&  value.type === "MainMenu" ){
				menuArray.push({
					label: value.name,
					icon: value.icon,
					url: value.layout + value.path,
					selected: window.location.pathname === value.layout + value.path ? true : false,
					//onClick: () => redirectPage(value.layout + value.path)
				})
			}	
		}, menuArray)
		setMenuLink(menuArray)
		let menuSecondArray	= []
		newVideoAskRoute.map((value, index) => {
			if( typeof value.type !== "undefined" &&  value.type === "MainMenu" ){
				menuSecondArray.push({
					label: value.name,
					icon: value.icon,
					url: value.layout + value.path,
					selected: window.location.pathname === value.layout + value.path ? true : false,
					//onClick: () => redirectPage(value.layout + value.path)
				})
			}
		}, menuSecondArray)
		setMenuSecondLink(menuSecondArray)
	}, [window.location.pathname])

	useEffect(() => {
		if( triggerNewFolder ){
			createNewFolder()
			setTriggerNewFolder(false)
		}
	}, [triggerNewFolder])

	useEffect(() => {
		if( triggerRenameFolder ){
			renameFolder(triggerRenameFolder.folder_id, triggerRenameFolder.name)
			setTriggerRenameFolder(null)
		}
	}, [triggerRenameFolder])

	useEffect(() => {
		if( triggerDeleteFolder ){
			deleteFolder(triggerDeleteFolder.folder_id, triggerDeleteFolder.name)
			setTriggerDeleteFolder(null)
		}
	}, [triggerDeleteFolder])
	
	useEffect(() => {
		if( triggerFolderMuqala ){
			newMuqalaWithFolder(triggerFolderMuqala)
			setTriggerFolderMuqala(null)
		}
	}, [triggerFolderMuqala])

	useEffect(() => {
		if( triggerMoveFolder ){
			moveToFolderVideo(triggerMoveFolder)
			setTriggerMoveFolder(null)
		}
	}, [triggerMoveFolder])

	// useEffect(() => {
	// 	if( interactiveVideos.length > 0 ){
	// 		let menuArray = []
	// 		interactiveVideos.map((value, index) => {
	// 			menuArray.push({
	// 				label: value.video_name,
	// 				icon: value.video_thumbnail,
	// 				//url: value.layout + value.path,						
	// 				//onClick: () => redirectPage(value.layout + value.path)
	// 			})
	// 		}, menuArray)
	// 		setMenuThirdLink(menuArray)
	// 	}
	// }, [interactiveVideos])

	const getInteractiveVideo = () => {
		setVideoSkeletonLoader(true)
		if( planData && planData.length > 0 && planData.indexOf("MQ005") !== -1 ){
			setVideoFolderFlag(true)
			PostData('api/v1/get-agency-interactive-video').then(result => {
				if( result !== "Invalid" ){
					if( result.status === "success" ){							
						let videoFolders		= result.data.videoFolders,
							videoFolderObj		= [],
							folderObject		= {},
							previousFolderId	= "";
						for( let i = 0; i < videoFolders.length; i++ ){
							let videoFolder	= videoFolders[i];
							if( videoFolder.folder_id !== previousFolderId ){
								previousFolderId	= videoFolder.folder_id;
								if( i !== 0 ){
									videoFolderObj.push(folderObject)
									folderObject	= {}
								}
							}
							if( Object.keys(folderObject).length === 0 ){
								let userId		= videoFolder.folderUserId  ? videoFolder.folderUserId : videoFolder.videoUserId;
								folderObject	= {folder_id: videoFolder.folder_id, folder_name: videoFolder.folder_name, user_id: userId, videos: []}
							}
							if( videoFolder.videoId ){
								let {folder_id, folder_name, folderUserId, ...videoDetails}	= videoFolder;
								folderObject.videos.push(videoDetails);
							}
							if( videoFolders.length === i+1 ){
								videoFolderObj.push(folderObject);
							}
						}
						setFolderVideos(videoFolderObj)
						setVideoSkeletonLoader(false)
					}
				}
			})
		}else{
			let data = { page: 1, page_size:5 }
			PostData('api/v1/getInteractiveVideo', data).then(result => {
				if (result !== "Invalid") {
					if (result.status === "success") {
						setInteractiveVideos(result.data.interactiveVideos)
						setVideoTotalPage(result.data.totalPages)
						setVideoSkeletonLoader(false)
					}
				}
			})
		}
	}

	const getRoutesArray = () => {
		setArrayValue([...userRoute, ...newVideoAskRoute])
		setIsLoading(false)
	}

	const getUserInformation	= () => {
		PostData("api/v1/getUserInfo").then(response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){
					setUserLoading(false)
					setUserData(response.data.user_detail)
					let planDataLocal	= [];
					let planDetails	= response.data.plan_detail;
					for( let i = 0; i < planDetails.length; i++ ){
						planDataLocal.push(planDetails[i].plan_id)
					}
					setPlanData(planDataLocal)
				}
			}
		})
	}

	const getRoutes = routes => {		
		return routes.map((prop, key) => {
			const Token = Cookies.get("_prod_token");
			if (prop.layout === "/user") {
				if (!Token) {
					return <Route path="*" element={<Navigate to="/account/login" replace />} key={key} />
				} else {
					return (
						<Route
							exact
							path={prop.path}
							element={prop.component}
							key={key}
						/>
					);
				}
			} else {
				return null;
			}
		});
	};

	const defaultState = useRef({
		emailFieldValue: 'dharma@jadedpixel.com',
		nameFieldValue: 'Jaded Pixel',
	});
	const skipToContentRef = useRef(null);

	const [toastActive, setToastActive] = useState(false);
	
	const [isDirty, setIsDirty] = useState(false);
	const [searchActive, setSearchActive] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [userMenuActive, setUserMenuActive] = useState(false);
	const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
	const [modalActive, setModalActive] = useState(false);
	const [nameFieldValue, setNameFieldValue] = useState(
		defaultState.current.nameFieldValue,
	);
	const [emailFieldValue, setEmailFieldValue] = useState(
		defaultState.current.emailFieldValue,
	);
	const [storeName, setStoreName] = useState(
		defaultState.current.nameFieldValue,
	);
	const [supportSubject, setSupportSubject] = useState('');
	const [supportMessage, setSupportMessage] = useState('');

	const handleSubjectChange = useCallback(
		(value) => setSupportSubject(value),
		[],
	);
	const handleMessageChange = useCallback(
		(value) => setSupportMessage(value),
		[],
	);
	const handleDiscard = useCallback(() => {
		setEmailFieldValue(defaultState.current.emailFieldValue);
		setNameFieldValue(defaultState.current.nameFieldValue);
		setIsDirty(false);
	}, []);
	const handleSave = useCallback(() => {
		defaultState.current.nameFieldValue = nameFieldValue;
		defaultState.current.emailFieldValue = emailFieldValue;

		setIsDirty(false);
		setToastActive(true);
		setStoreName(defaultState.current.nameFieldValue);
	}, [emailFieldValue, nameFieldValue]);
	const handleNameFieldChange = useCallback((value) => {
		setNameFieldValue(value);
		value && setIsDirty(true);
	}, []);
	const handleEmailFieldChange = useCallback((value) => {
		setEmailFieldValue(value);
		value && setIsDirty(true);
	}, []);
	const handleSearchResultsDismiss = useCallback(() => {
		setSearchActive(false);
		setSearchValue('');
	}, []);
	const handleSearchFieldChange = useCallback((value) => {
		setSearchValue(value);
		setSearchActive(value.length > 0);
	}, []);
	const toggleToastActive = useCallback(
		() => setToastChildActive((toastChildActive) => !toastChildActive),
		[],
	);
	const toggleUserMenuActive = useCallback(
		() => setUserMenuActive((userMenuActive) => !userMenuActive),
		[],
	);
	const toggleMobileNavigationActive = useCallback(
		() =>
			setMobileNavigationActive(
				(mobileNavigationActive) => !mobileNavigationActive,
			),
		[],
	);

	const deleteModalOpen	= useCallback((video) => {
		setDeleteVideoDetail(video)
		setDeleteActionFlag(true)
		toggleVideoPopover()
	})

	const moveToFolderVideo	= useCallback((video) => {
		setVideoMoveToFolder(video)
		setMoveToFolderFlag(true)
		toggleVideoPopover()
	})

	const toggleMoveToFolder = () => {
		setVideoMoveToFolder(null)
		setMoveToFolderFlag(false)
	}

	const handleDeleteClose	= () => {
		setDeleteVideoDetail(null)
		setDeleteActionFlag(false)
		setDeleteFlag(false)
	}

	const okDelete = () => {
		setDeleteFlag(true)
		let data	= {video_id: deleteVideoDetail.video_id}
		PostData("api/v1/deleteVideo", data).then( response => {
			if( response	!== "Invalid" ){
				if( response.status === "success" ){
					setToastMessage(response.message)
					getInteractiveVideo()
					if( window.location.href.indexOf("/user/video/"+deleteVideoDetail.video_id) !== -1 ){
						navigate("/user/")
					}
				}else if( response.status === "failure" ){
					setToastMessage(response.message)
				}else{
					setToastMessage(response.message.message)
				}
			}
			handleDeleteClose()
		})
	}

	const okDeleteFolder = () => {
		setDeleteFolderLoader(true)
		let data = { folder_id: deleteFolderId }
		PostData("api/v1/delete-folder", data).then( response => {
			if( response	!== "Invalid" ){
				if( response.status === "success" ){
					setToastMessage(response.message);
					setDeleteFolderSuccess(deleteFolderId);
					setDeleteFolderLoader(false)
					toggleDeleteFolder()
					getInteractiveVideo()
					let folderVideosLocal	= [...folderVideos];
					for( let i = 0; i < folderVideosLocal.length; i++ ){
						if( folderVideosLocal[i].folder_id === deleteFolderId ){
							let folderVideoItems	= folderVideosLocal[i].videos;
							for( let j = 0; j < folderVideoItems.length; j++ ){
								if( window.location.href.indexOf("/user/video/"+folderVideoItems[j].videoId) !== -1 ){
									navigate("/user/")
								}
							}
							break;
						}
					}
				}
			}
		})
	}

	const moveToFolderFunc	= (folderId) => {
		let data	= {video_id: videoMoveToFolder.videoId, folder_id: folderId}
		PostData("api/v1/move-to-folder-video", data).then( response => {
			if( response	!== "Invalid" ){
				if( response.status === "success" ){
					setMoveFolderSuccess(true)
					setToastMessage(response.message)
					getInteractiveVideo()
				}else if( response.status === "failure" ){
					setToastMessage(response.message)
				}else{
					setToastMessage(response.message.message)
				}
			}
			toggleMoveToFolder()
		})
	}

	const createNewFolder	= () => {
		setNewFolderModal(true)
	}

	const toggleNewFolderModal = () => {
		setFolderName(null)
		setNewFolderModal(!newFolderModal)
		setFolderNameError("")
		setCreateFolderFlag(false)
		
	}

	const toggleDeleteFolder = () => {
		setDeleteFolderId(null)
		setDeleteFolderName("")
		setDeleteFolderModal(!deleteFolderModal)
		setDeleteFolderLoader(false)
	}

	const updateFolderName	= (e) => {
		setFolderName(e)
		if( !e ){
			setFolderNameError("Folder name required")
		}else{
			setFolderNameError("")
		}
	}

	const toggleRenameFolderModal	= () => {
		setFolderName(null)
		setRenameFolderName(null)
		setRenameVideoFolder(null)
		setRenameFolderFlag(false)
		setRenameFolderModal(!renameFolderModal)
		setFolderNameError("")
	}

	const createFolderFunc	= () => {
		if( !folderName ){
			setFolderNameError("Folder name required");
			return;
		}
		setFolderNameError("")
		setCreateFolderFlag(true)
		PostData('api/v1/create-folder', {folder_name: folderName}).then(response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){
					setCreateFolderSuccess(response.data.folder.folder_id)
					setToastMessage(response.message)
					setNewFolderModal(false)
					toggleNewFolderModal()
					getInteractiveVideo()
				}
			}
		})
	}

	const renameFolderFunc	= () => {
		if( !folderName ){
			setFolderNameError("Folder name required");
			return;
		}
		setFolderNameError("")
		setRenameFolderFlag(true)
		PostData('api/v1/rename-folder', {folder_name: folderName, folder_id: renameVideoFolder}).then(response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){
					setToastMessage(response.message)
					let folderVideosLocal	= [...folderVideos]
					for( let i = 0; i < folderVideosLocal.length; i++ ){
						if( folderVideosLocal[i].folder_id === renameVideoFolder ){
							folderVideosLocal[i].folder_name	= folderName;
						}
					}
					setFolderVideos(folderVideosLocal)
					setRenameFolderFlag(false)
					toggleRenameFolderModal()
					setRenameFolderSuccess(true)
				}else if( response.status === "failure" ){
					setToastMessage(response.message)
				}
			}
		})
	}

	const renameFolder = (folderId, folderName) => {
		togglePopover()
		setRenameVideoFolder(folderId)
		setFolderName(folderName)
		setRenameFolderName(folderName)
		setRenameFolderModal(true)
	}

	const deleteFolder = (folderId, folderName) => {
		togglePopover()
		setDeleteFolderId(folderId)
		setDeleteFolderName(folderName)
		setDeleteFolderModal(true)
	}

	const newMuqalaWithFolder = (folderId) => {
		togglePopover()
		setNewVideoFolderId(folderId)
		navigate('/user/video-creator')
	}

	const toastMarkup = toastChildActive ? (
		<Toast onDismiss={toggleToastActive} content={toastMessage} duration={toastDuration ? toastDuration : 2000} />
	) : null;

	let userMenuActions = [
		{
			items: [
				{ content: 'Settings', icon: SettingsMajor, url: "/user/settings"},
				{ content: 'Logout', icon: LogOutMinor, url: "/user/logout" }
			],
		},
	];

	if( planData && planData.length > 0 && planData.indexOf("MQ005") !== -1 ){
		userMenuActions	= [
			{
				items: [
					{ content: 'Settings', icon: SettingsMajor, url: "/user/settings"},
					{ content: 'Manage Team Member', icon: CustomerPlusMajor, url: "/user/manage-team"},
					{ content: 'Logout', icon: LogOutMinor, url: "/user/logout" }
				],
			},
		]
	}

	const contextualSaveBarMarkup = isDirty ? (
		<ContextualSaveBar
			message="Unsaved changes"
			saveAction={{
				onAction: handleSave,
			}}
			discardAction={{
				onAction: handleDiscard,
			}}
		/>
	) : null;

	const userMenuMarkup = (
		<TopBar.UserMenu
			actions={userMenuActions}
			name={( userData && userData.first_name ) ? userData.first_name : ""}
			//detail={}
			initials={( userData && userData.first_name ) ? userData.first_name.substring(0,1) : "U"}
			open={userMenuActive}
			onToggle={toggleUserMenuActive}
		/>
	);

	const searchResultsMarkup = (
		<ActionList
			items={[{ content: 'Shopify help center' }, { content: 'Community forums' }]}
		/>
	);

	const searchFieldMarkup = (
		<TopBar.SearchField
			onChange={handleSearchFieldChange}
			value={searchValue}
			placeholder="Search"
		/>
	);

	const topBarMarkup = (
		<TopBar
			showNavigationToggle
			userMenu={userMenuMarkup}
			//searchResultsVisible={searchActive}
			//searchField={searchFieldMarkup}
			//searchResults={searchResultsMarkup}
			//onSearchResultsDismiss={handleSearchResultsDismiss}
			onNavigationToggle={toggleMobileNavigationActive}
		/>
	);

	const navigationMarkup = (
		<Navigation location={location.pathname}>
			{/* <Navigation.Section
			items={[
			  {
				label: 'Back to Shopify',
				icon: ArrowLeftMinor,
			  },
			]}
		  /> */}
			<Navigation.Section
				title=""
				items={[...menuLink]}
			/>
			{( planData && planData.length === 0 ) || ( planData && planData.length > 0 && planData.indexOf("MQ005") === -1 ) ? 
				<Navigation.Section
					items={[...menuSecondLink]}
					separator
				/>
			:
				<div className="newMuqalaMenuWrapper">
					<ul className="Polaris-Navigation__Section">
						<li className="Polaris-Navigation__ListItem">
							<div className="Polaris-Navigation__ItemWrapper">
								<div className="Polaris-Navigation__ItemInnerWrapper">
									<ReactRouterLink to={`/user/video-creator`} className="Polaris-Navigation__Item">
										<div className="Polaris-Navigation__Icon">
											<span className="Polaris-Icon">
												<span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--regular Polaris-Text--visuallyHidden"></span>
												<svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
													<path d="M17 9h-6v-6a1 1 0 0 0-2 0v6h-6a1 1 0 0 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 0 0 0-2z"></path>
												</svg>
											</span>
										</div>
										<span className="Polaris-Navigation__Text">New Muqala</span>
									</ReactRouterLink>
									<div className="addNewFolderWrapper" onClick={() => createNewFolder()}>
										<Icon
											source={FolderPlusMajor}
											color="base"
										/>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			}

			{/* <Navigation.Section
				title=""
				items={[...menuThirdLink]}
			/> */}
			{videoSkeletonLoader ?
				<VideoMenuSkeleton />
			:
				<>
					{( !videoFolderFlag && interactiveVideos.length > 0 ) ?
						<div className="videoMenuLinkWrapper">
							<ul className="Polaris-Navigation__Section">
								{interactiveVideos.map((interactiveVideo, index) => {
									return (
										<li className="Polaris-Navigation__ListItem" key={index}>
											<div className="Polaris-Navigation__ItemWrapper">
												<div className="Polaris-Navigation__ItemInnerWrapper customMenuWrapper">
													<ReactRouterLink to={`/user/video/${interactiveVideo.video_id}`} className="Polaris-Navigation__Item">
														<div className="Polaris-Navigation__Icon">
															<span className="Polaris-Icon" style={{"--videoBackground": `url(${interactiveVideo.video_thumbnail})`}}>
															</span>
														</div>
														<span className="Polaris-Navigation__Text">{interactiveVideo.video_name}</span>
													</ReactRouterLink>
													<div className="deleteWrapper" onClick={() => deleteModalOpen(interactiveVideo)}>
														<Icon
															source={DeleteMinor}
															color="base"
														/>
													</div>
												</div>
											</div>
										</li>
									)
								})}
							</ul>
							{(videoTotalPage > 1 ) &&
								<div className="px-2">
									<Button primary url={"/user/video"} onClick={() => setCurrentFolderId(-1)} fullWidth small>View All</Button>
								</div>
							}
						</div>
					:	( videoFolderFlag && folderVideos.length > 0 ) &&
						<div className="agencyVideoMenuOuter">
							<div className="videoMenuLinkWrapper agencyVideoMenuWrapper">
								{folderVideos.map((folderVideo, index) => {
									return (
										<>
											<div className="folderContainer" key={index}>
												{folderVideo.folder_name &&
													<div className="folderWrapper" onClick={() => setOpenFolderId((prevState) => (prevState === folderVideo.folder_id ? null : folderVideo.folder_id))}>
														<div className="folderIconWrapper">
															<div className="closedFolderIconWrap">
																{folderVideo.folder_id !== openFolderId ?
																	<svg fill="none" height="14" width="16" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M0 2.5A2.5 2.5 0 012.5 0h2.465a2.5 2.5 0 012.08 1.113L7.636 2H13.5A2.5 2.5 0 0116 4.5v7a2.5 2.5 0 01-2.5 2.5h-11A2.5 2.5 0 010 11.5v-9zm6.833 1H13.5a1 1 0 011 1v7a1 1 0 01-1 1h-11a1 1 0 01-1-1v-9a1 1 0 011-1h2.465a1 1 0 01.832.445L6.833 3.5z" fill="#000" fill-rule="evenodd"></path></svg>
																:
																	<svg fill="none" height="14" width="19" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 0A2.5 2.5 0 000 2.5v9A2.5 2.5 0 002.5 14h11a2.5 2.5 0 002.5-2.5v-7A2.5 2.5 0 0013.5 2H7.636l-.591-.887A2.5 2.5 0 004.965 0H2.5z" fill="#7D00FE"></path><path d="M4.24 6.106a1 1 0 01.92-.606h10.824a1 1 0 01.919 1.394l-2.143 5a1 1 0 01-.92.606H3.018a1 1 0 01-.92-1.394l2.143-5z" fill="#F6EDFF"></path><path clip-rule="evenodd" d="M5.16 4h10.823c1.795 0 3.005 1.835 2.298 3.485l-2.143 5A2.5 2.5 0 0113.84 14H3.016C1.222 14 .012 12.165.72 10.515l2.142-5A2.5 2.5 0 015.16 4zm0 1.5a1 1 0 00-.92.606l-2.143 5a1 1 0 00.92 1.394H13.84a1 1 0 00.92-.606l2.142-5a1 1 0 00-.919-1.394H5.16z" fill="#7D00FE" fill-rule="evenodd"></path></svg>
																}
																
															</div>
														</div>
														<div className="folderNameWrapper">
															{folderVideo.folder_name}
														</div>
														<div className="folderActionWrapper" onClick={(e) => { e.stopPropagation();togglePopover(folderVideo.folder_id)}}>
															<Icon
																source={MobileVerticalDotsMajor}
																color="base"
															/>
														</div>
														<Popover
															active={activePopover === folderVideo.folder_id}
															activator={<div />} // Use a dummy element as the activator
															onClose={() => togglePopover(folderVideo.folder_id)}
														>
															<ActionList
																actionRole="menuitem"
																items={[
																	{
																		content: 'New Muqala',
																		onAction: () => { newMuqalaWithFolder(folderVideo.folder_id) }
																	},
																	{
																		content: 'Rename',
																		onAction: () => { renameFolder(folderVideo.folder_id, folderVideo.folder_name)}
																	},
																	{
																		content: 'Delete',
																		onAction: () => {deleteFolder(folderVideo.folder_id, folderVideo.folder_name)}
																	}
																]}
															/>
														</Popover>
													</div>
												}
												<div className={`folderVideoWrapper ${(folderVideo.folder_name ? ( folderVideo.folder_id !== openFolderId ? "closeVideoListWrapper" : "openVideoListWrapper") : "noFolderVideoWrapper" )  }`}>
													{folderVideo.videos.length > 0 ?
														<ul className="Polaris-Navigation__Section">
															{folderVideo.videos.map((video, index) => {
																return (
																	<li className="Polaris-Navigation__ListItem" key={index}>
																		<div className="Polaris-Navigation__ItemWrapper">
																			<div className="Polaris-Navigation__ItemInnerWrapper customMenuWrapper">
																				<ReactRouterLink to={`/user/video/${video.videoId}`} className="Polaris-Navigation__Item">
																					<div className="Polaris-Navigation__Icon">
																						<span className="Polaris-Icon" style={{"--videoBackground": `url(${video.video_thumbnail})`}}>
																						</span>
																					</div>
																					<span className="Polaris-Navigation__Text">{video.video_name}</span>
																				</ReactRouterLink>
																				<div className="deleteWrapper" onClick={() => toggleVideoPopover(video.videoId)}>
																					<Icon
																						source={MobileVerticalDotsMajor}
																						color="base"
																					/>
																				</div>
																				<Popover
																					active={videoActivePopover === "Video_"+video.videoId}
																					activator={<div />} // Use a dummy element as the activator
																					onClose={() => toggleVideoPopover(video.videoId)}
																				>
																					<ActionList
																						actionRole="menuitem"
																						items={
																							[
																								{
																									content: 'Move to folder',
																									onAction: () => moveToFolderVideo(video)
																								},
																								{
																									content: 'Edit',
																									onAction: () => toggleVideoPopover(),
																									url: `/user/video/${video.videoId}`
																								},
																								{
																									content: 'Delete',
																									onAction: () => deleteModalOpen(video)
																								}
																							]
																						}
																					/>
																				</Popover>
																			</div>
																		</div>
																	</li>
																)
															})}
														</ul>
													:
														<ul className="Polaris-Navigation__Section">
															<li className="Polaris-Navigation__ListItem">
																<div className="Polaris-Navigation__ItemWrapper">
																	<div className="Polaris-Navigation__ItemInnerWrapper customMenuWrapper">
																		<a className="Polaris-Navigation__Item customFontLink">
																			This folder is lonely 😢
																		</a>
																	</div>
																</div>
															</li>
														</ul>
													}
												</div>
											</div>
										</>
									)
								})}
							</div>
							<div className="px-2 viewAllAgency">
								<Button primary url={"/user/video"} fullWidth small>View All</Button>
							</div>
						</div>
					}
				</>
			}
			<div style={{flexGrow: 1}}></div>
			<ul className="Polaris-Navigation__Section" style={{marginTop:"20px"}}>
				<li className="Polaris-Navigation__ListItem" key={0}>
					<div className="Polaris-Navigation__ItemWrapper">
						<div className="Polaris-Navigation__ItemInnerWrapper">
							<a href={`https://www.youtube.com/playlist?list=PLOonn5CUBir7OB0E5CGlSEljWDAJU6Otb`} target="_blank" className="Polaris-Navigation__Item">
								<div className="Polaris-Navigation__Icon">
									<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 107.78" style={{enableBackground:"new 0 0 122.88 107.78"}} xmlSpace="preserve">
										<g>
											<path d="M2.1,81.26c-1.16,0-2.1-0.94-2.1-2.1c0-1.16,0.94-2.1,2.1-2.1h9.09V12.44H2.1c-1.16,0-2.1-0.94-2.1-2.1 c0-1.16,0.94-2.1,2.1-2.1h57.12V2.1c0-1.16,0.94-2.1,2.1-2.1s2.1,0.94,2.1,2.1v6.14h57.12c1.16,0,2.1,0.94,2.1,2.1 c0,1.16-0.94,2.1-2.1,2.1h-8.95v64.62h9.18c1.16,0,2.1,0.94,2.1,2.1c0,1.16-0.94,2.1-2.1,2.1H63.75v9.6 c0.1,0.07,0.2,0.15,0.29,0.23l13.12,12.48c0.84,0.8,0.87,2.12,0.07,2.96c-0.8,0.84-2.12,0.87-2.96,0.07L63.75,96.6v9.08 c0,1.16-0.94,2.1-2.1,2.1c-1.16,0-2.1-0.94-2.1-2.1V96.2L48.6,106.61c-0.84,0.8-2.17,0.76-2.96-0.07c-0.8-0.84-0.77-2.17,0.07-2.96 L58.84,91.1c0.21-0.2,0.46-0.35,0.71-0.45v-9.39H2.1L2.1,81.26z M61.44,28.04c9.28,0,16.81,7.53,16.81,16.81 c0,9.28-7.53,16.81-16.81,16.81c-9.28,0-16.81-7.53-16.81-16.81C44.63,35.57,52.16,28.04,61.44,28.04L61.44,28.04z M70.05,44.94 l-13.4-8.72v17.89L70.05,44.94L70.05,44.94z M15.39,77.06h92V12.44h-92V77.06L15.39,77.06z"/>
										</g>
									</svg>
								</div>
								<span className="Polaris-Navigation__Text">Training</span>
							</a>
						</div>
					</div>
				</li>
				<li className="Polaris-Navigation__ListItem" key={1}>
					<div className="Polaris-Navigation__ItemWrapper">
						<div className="Polaris-Navigation__ItemInnerWrapper">
							<a href={`https://muqala.freshdesk.com/support/home`} target="_blank" className="Polaris-Navigation__Item">
								<div className="Polaris-Navigation__Icon">
									<Icon
										source={QuestionMarkInverseMinor}
										color="base"
									/>
								</div>
								<span className="Polaris-Navigation__Text">Support</span>
							</a>
						</div>
					</div>
				</li>
				<li className="Polaris-Navigation__ListItem" key={2}>
					<div className="Polaris-Navigation__ItemWrapper">
						<div className="Polaris-Navigation__ItemInnerWrapper">
							<ReactRouterLink to={`/user/settings`} className="Polaris-Navigation__Item">
								<div className="Polaris-Navigation__Icon">
									<Icon
										source={SettingsMajor}
										color="base"
									/>
								</div>
								<span className="Polaris-Navigation__Text">Settings</span>
							</ReactRouterLink>
						</div>
					</div>
				</li>
			</ul>
		</Navigation>
	);

	const loadingMarkup = isLoading ? <Loading /> : null;

	const skipToContentTarget = (
		<a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
	);

	const actualPageMarkup = (
		<>
			{arrayValue.length > 0 &&
				<Routes>
					{getRoutes(arrayValue)}
					<Route path="/user/" element={<Navigate to={innerLink} />} />
				</Routes>
			}
		</>
	);

	const loadingPageMarkup = (
		<SkeletonPage>
			<Layout>
				<Layout.Section>
					<Card sectioned>
						<TextContainer>
							<SkeletonDisplayText size="small" />
							<SkeletonBodyText lines={9} />
						</TextContainer>
					</Card>
				</Layout.Section>
			</Layout>
		</SkeletonPage>
	);

	const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

	const logo = {
		width: 124,
		topBarSource:
			'https://storage.googleapis.com/speechvid/muqala/muqala_app_new_logo_white_black.png',
		contextualSaveBarSource:
			'https://storage.googleapis.com/speechvid/muqala/muqala_app_new_logo_white_black.png',
		url: '/',
		accessibilityLabel: 'SuperVideos',
	};

	const CustomLinkComponent = ({ children, url, ...rest }) => {
		if (isOutboundLink(url) || rest.download) {
			return (
				<a href={url} {...rest}>
				{children}
				</a>
			);
		}		
		return (
			<ReactRouterLink to={url} {...rest}>
				{children}
			</ReactRouterLink>
		);
	};

	const isOutboundLink = (url) => {
		return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
	}

	return (
		<div>
			<AppProvider
				linkComponent={CustomLinkComponent}
				i18n={{
					Polaris: {
						Avatar: {
							label: 'Avatar',
							labelWithInitials: 'Avatar with initials {initials}',
						},
						ContextualSaveBar: {
							save: 'Save',
							discard: 'Discard',
						},
						TextField: {
							characterCount: '{count} characters',
						},
						TopBar: {
							toggleMenuLabel: 'Toggle menu',
							SearchField: {
								clearButtonLabel: 'Clear',
								search: 'Search',
							},
						},
						Modal: {
							iFrameTitle: 'body markup',
						},
						Frame: {
							skipToContent: 'Skip to content',
							navigationLabel: 'Navigation',
							Navigation: {
								closeMobileNavigationLabel: 'Close navigation',
							},
						},
					},
				}}
			>
				<Frame
					logo={logo}
					topBar={topBarMarkup}
					navigation={navigationMarkup}
					showMobileNavigation={mobileNavigationActive}
					onNavigationDismiss={toggleMobileNavigationActive}
					skipToContentTarget={skipToContentRef.current}
				>					
					{contextualSaveBarMarkup}
					{loadingMarkup}					
					{pageMarkup}					
					{toastMarkup}
				</Frame>
				{deleteActionFlag &&
					<Modal
						instant
						small
						open={deleteActionFlag}
						onClose={handleDeleteClose}
						title=""
						primaryAction={{
							content: 'Delete',
							onAction: okDelete,
							loading: deleteFlag
						}}
						secondaryActions={[
						{
							content: 'Cancel',
							onAction: handleDeleteClose,
						},
						]}
					>
						<Modal.Section>
							<TextContainer>
								<p>Are you sure you want to delete this Muqala Video Sequence - {deleteVideoDetail && deleteVideoDetail.video_name}?</p>
							</TextContainer>
						</Modal.Section>
					</Modal>
				}
				{newFolderModal &&
					 <Modal
						instant
						small
						title="Create a new folder"
						open={newFolderModal}
						onClose={toggleNewFolderModal}
						primaryAction={{
							content: 'Create',
							onAction: createFolderFunc,
							loading: createFolderFlag
						}}
						secondaryActions={[
						{
							content: 'Cancel',
							onAction: toggleNewFolderModal,
						},
						]}
					>
						<Modal.Section>
							<div className="my-3">
								<TextField
									value={folderName}
									onChange={(e) => updateFolderName(e)}
									autoComplete="off"
									placeholder="Name your new folder"
									error={folderNameError ? folderNameError : ""}
									className="form-control"
								/>
							</div>
						</Modal.Section>
					</Modal>
				}
				{renameFolderModal &&
					 <Modal
						instant
						small
						title={`Rename this folder (${renameFolderName})`}
						open={renameFolderModal}
						onClose={toggleRenameFolderModal}
						primaryAction={{
							content: 'Rename',
							onAction: renameFolderFunc,
							loading: renameFolderFlag
						}}
						secondaryActions={[
						{
							content: 'Cancel',
							onAction: toggleRenameFolderModal,
						},
						]}
					>
						<Modal.Section>
							<div className="my-3">
								<TextField
									value={folderName}
									onChange={(e) => updateFolderName(e)}
									autoComplete="off"
									placeholder="Rename your new folder"
									className="form-control"
									error={folderNameError ? folderNameError : ""}
								/>
							</div>
						</Modal.Section>
					</Modal>
				}
				{moveToFolderFlag &&
					 <Modal
						instant
						title={`Move "${videoMoveToFolder.video_name}"`}
						open={moveToFolderFlag}
						onClose={toggleMoveToFolder}
						secondaryActions={[
						{
							content: 'Cancel',
							onAction: toggleMoveToFolder,
						},
						]}
					>
						<Modal.Section>
							<Text variant="headingXs" as="h6">
								Choose a folder...
							</Text>
							<div className="folderNameMoveWrapper">
								<div className="folderMoveInnerWrap">
									<Button plain primary={videoMoveToFolder.videoFolderId === null} disabled={videoMoveToFolder.videoFolderId === null} onClick={() => moveToFolderFunc(null)}>
										<svg fill="none" height="14" width="16" xmlns="http://www.w3.org/2000/svg" style={{marginRight: "8px"}}>
											<path clipRule="evenodd" d="M0 2.5A2.5 2.5 0 012.5 0h2.465a2.5 2.5 0 012.08 1.113L7.636 2H13.5A2.5 2.5 0 0116 4.5v7a2.5 2.5 0 01-2.5 2.5h-11A2.5 2.5 0 010 11.5v-9zm6.833 1H13.5a1 1 0 011 1v7a1 1 0 01-1 1h-11a1 1 0 01-1-1v-9a1 1 0 011-1h2.465a1 1 0 01.832.445L6.833 3.5z" fill="currentColor" fillRule="evenodd"></path>
										</svg>
										Root level 'folder'
									</Button>
									<hr />
									{folderVideos.map((folderVideo, index) => {
										
										return (
											<>
												{folderVideo.folder_id !== null &&
													<>
														<Button key={index} plain primary={videoMoveToFolder.videoFolderId === folderVideo.folder_id} disabled={videoMoveToFolder.videoFolderId === folderVideo.folder_id}  onClick={() => moveToFolderFunc(folderVideo.folder_id)}>
															<svg fill="none" height="14" width="16" xmlns="http://www.w3.org/2000/svg" style={{marginRight: "8px"}}>
																<path clipRule="evenodd" d="M0 2.5A2.5 2.5 0 012.5 0h2.465a2.5 2.5 0 012.08 1.113L7.636 2H13.5A2.5 2.5 0 0116 4.5v7a2.5 2.5 0 01-2.5 2.5h-11A2.5 2.5 0 010 11.5v-9zm6.833 1H13.5a1 1 0 011 1v7a1 1 0 01-1 1h-11a1 1 0 01-1-1v-9a1 1 0 011-1h2.465a1 1 0 01.832.445L6.833 3.5z" fill="currentColor" fillRule="evenodd"></path>
															</svg>
															{folderVideo.folder_name}
														</Button>
														<hr />
													</>
												}
											</>
										)
									})}
								</div>
							</div>
						</Modal.Section>
					</Modal>
				}
				{deleteFolderModal &&
					<Modal
						instant
						small
						open={deleteFolderModal}
						onClose={toggleDeleteFolder}
						title={`Delete this folder (${deleteFolderName})`}
						primaryAction={{
							content: 'Yes, delete it',
							onAction: okDeleteFolder,
							loading: deleteFolderLoader
						}}
						secondaryActions={[
						{
							content: 'Cancel',
							onAction: toggleDeleteFolder,
						},
						]}
					>
						<Modal.Section>
							<TextContainer>
								<p>All Muqala contained within this folder have also been permanently deleted.</p>
								<p><strong>Are you sure you want to permanently delete this folder?</strong></p>
							</TextContainer>
						</Modal.Section>
					</Modal>
				}
			</AppProvider>
		</div>
	);

}

export default User;
