import {
	SkeletonPage,
	Layout,
	Card,
	SkeletonBodyText,
	TextContainer,
	SkeletonDisplayText,
	Columns,
} from '@shopify/polaris';
import React from 'react';

function VideoMenuSkeleton() {
	return (
		<SkeletonPage fullWidth={true} title="">
			<Layout>
				<Layout.Section>
					<Card subdued>
						<Card.Section>
							<Columns
								columns={{
									xs: '3fr 3fr',
									md: '2fr 4fr',
								}}
								gap={{
									xs: '4',
									md: '2',
								}}
							>
								<SkeletonDisplayText size="small" />
								<div>
									<SkeletonBodyText lines={2} />
								</div>
							</Columns>
						</Card.Section>
					</Card>
				</Layout.Section>
				<Layout.Section>
					<Card subdued>
						<Card.Section>
							<Columns
								columns={{
									xs: '3fr 3fr',
									md: '2fr 4fr',
								}}
								gap={{
									xs: '4',
									md: '2',
								}}
							>
								<SkeletonDisplayText size="small" />
								<div>
									<SkeletonBodyText lines={2} />
								</div>
							</Columns>
						</Card.Section>
					</Card>
				</Layout.Section>
				<Layout.Section>
					<Card subdued>
						<Card.Section>
							<Columns
								columns={{
									xs: '3fr 3fr',
									md: '2fr 4fr',
								}}
								gap={{
									xs: '4',
									md: '2',
								}}
							>
								<SkeletonDisplayText size="small" />
								<div>
									<SkeletonBodyText lines={2} />
								</div>
							</Columns>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
		</SkeletonPage>
	);
}
export default VideoMenuSkeleton;