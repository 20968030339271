import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

function Logout(props) {
	const [redirectLogut, setRedirectLogout] = useState(false)
	useEffect(() => {
		localStorage.clear();
		Cookies.remove('_prod_token');
		setRedirectLogout(true);
	}, [])
	if( redirectLogut ){
		return <Navigate to ="/account/login" replace />
	}
	return (
		<div>

		</div>
	);
}

export default Logout;