import { Badge, Button, Card, DropZone, Form, FormLayout, Icon, Label, Layout, Spinner, Stack, Tabs, Text, TextField, Thumbnail } from '@shopify/polaris';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { CustomersMinor, KeyMajor, AddCodeMajor, ClipboardMinor, ImageMajor, NoteMinor, CircleCancelMajor } from '@shopify/polaris-icons';
import { ProductContext } from '../../store';
import { GetData, PostData } from '../../services/PostData';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Settings = (props) => {
	const [selected, setSelected] = useState(0)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errors, setErrors] = useState({})
	const [userDataLocal, setUserDataLocal] = useState({})
	const [passwordObject, setPasswordObject] = useState({ old_password: "", password: "", confirm_password: "" })
	const [oldPasswordShown, setOldPasswordShown] = useState(false)
	const [passwordShown, setPasswordShown] = useState(false)
	const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
	const [passwordSubmitting, setPasswordSubmitting] = useState(false)
	const [brandSubmitting, setBrandSubmitting]	= useState(false)
	const [passwordErrors, setPasswordErrors] = useState({})
	const [scriptContent, setScriptContent] = useState("")
	const [copied, setCopied] = useState(false)
	const [fileImageUrl, setFileImageUrl]	= useState(null)

	const [tabs, setTabs] = useState([
		{
			id: 'personal-info',
			content: (
				<div className='d-flex align-items-center'>
					<Icon source={CustomersMinor} />
					<p className='m-2'>Personal Info</p>
				</div>
			),
			accessibilityLabel: 'Personal Info',
			panelID: 'personal-info-content-1',
		},
		{
			id: "password",
			content: (
				<div className='d-flex align-item-center'>
					<Icon source={KeyMajor} />
					<p className='m-2'>Password</p>
				</div>
			),
			accessibilityLabel: "Password",
			panelID: 'password-content-1'
		},
		{
			id: 'integration',
			content: (
				<div className='d-flex align-item-center'>
					<Icon source={AddCodeMajor} />
					<p className='m-2'>Integration</p>
				</div>
			),
			accessibilityLabel: "Integration",
			panelID: 'integration-content-1',
		},
	]);
	const [file, setFile] = useState();
	const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];


	const {
		userData,
		setUserData,
		planData
	} = useContext(ProductContext)

	useEffect(() => {
		if (userData) {
			setUserDataLocal(JSON.parse(JSON.stringify(userData)))
		}
	}, [userData])

	useEffect(() => {
		if( file ){
			if( !/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(file) ){
				createImage(file)
			}else{
				setFileImageUrl(file)
			}
		}else{
			setFileImageUrl(null)
		}
	}, [file])

	useEffect(() => {
		if (planData && planData.length > 0 && planData.indexOf('MQ005') !== -1 && tabs.length === 3) {
			setTabs([
				...tabs,
				{
					id: 'brand',
					content: (
						<div className='d-flex align-item-center'>
							<Icon source={ImageMajor} />
							<p className='m-2'>Brand</p>
						</div>
					),
					accessibilityLabel: "Brand",
					panelID: 'brand-content-1',
				}
			])
		}
	}, [planData])

	useEffect(() => {
		if( userData.brand_logo ){
			setFile(userData.brand_logo);
		}
	}, [userData])

	useEffect(() => {
		getVideoScript()
	}, [])

	useEffect(() => {
		if (copied) {
			setTimeout(function () {
				setCopied(false)
			}, 5000)
		}
	}, [copied])

	const handleDropZoneDrop = useCallback(
		(_dropFiles, acceptedFiles, _rejectedFiles) =>
			setFile(acceptedFiles[0]),
		[],
	);

	const getVideoScript = () => {
		GetData("api/v1/getVideoScript").then(response => {
			if (response !== "Invalid") {
				if (response.status === "success") {
					setScriptContent(response.data.script)
				}
			}
		})
	}

	const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), [],);

	const handleInputChange = (fieldName, value) => {
		setUserDataLocal({ ...userDataLocal, [fieldName]: value })
		if (value.trim() === "") {
			setErrors({ ...errors, [fieldName]: "Please enter your " + fieldName.replace("_", " ") })
		} else {
			let errorsLocal = JSON.parse(JSON.stringify(errors));
			delete errorsLocal[fieldName]
			setErrors(errorsLocal)
		}
	}

	const handlePasswordChange = (fieldName, value) => {
		setPasswordObject({ ...passwordObject, [fieldName]: value })
		if (value.trim() === "") {
			setPasswordErrors({ ...passwordErrors, [fieldName]: "Please enter " + fieldName.replace("_", " ") })
		} else if (fieldName === "old_password" || fieldName === "password") {
			if (value.length < 6) {
				setPasswordErrors({ ...passwordErrors, [fieldName]: "Minimum six character needed" })
			} else if (fieldName === "password" && passwordObject.old_password === value) {
				setPasswordErrors({ ...passwordErrors, [fieldName]: "New password must differ from previous one" })
			} else {
				let passwordErrorLocal = JSON.parse(JSON.stringify(passwordErrors));
				delete passwordErrorLocal[fieldName]
				setPasswordErrors(passwordErrorLocal)
			}
		} else if (fieldName === "confirm_password") {
			if (passwordObject && passwordObject.password !== value) {
				setPasswordErrors({ ...passwordErrors, [fieldName]: "Passwords do not match!" })
			} else {
				let passwordErrorLocal = JSON.parse(JSON.stringify(passwordErrors));
				delete passwordErrorLocal[fieldName]
				setPasswordErrors(passwordErrorLocal)
			}
		}
	}

	const handleInputBlur = (fieldName) => {
		if (userDataLocal[fieldName].trim() === "") {
			setErrors({ ...errors, [fieldName]: "Please enter your " + fieldName.replace("_", " ") })
		} else {
			let errorsLocal = JSON.parse(JSON.stringify(errors));
			delete errorsLocal[fieldName]
			setErrors(errorsLocal)
		}
	}

	const handlePasswordBlur = (fieldName) => {
		if (passwordObject[fieldName].trim() === "") {
			setPasswordErrors({ ...passwordErrors, [fieldName]: "Please enter " + fieldName.replace("_", " ") })
		} else if (fieldName === "old_password" || fieldName === "password") {
			if (passwordObject[fieldName].length < 6) {
				setPasswordErrors({ ...passwordErrors, [fieldName]: "Minimum six character needed" })
			} else if (fieldName === "password" && passwordObject.old_password === passwordObject[fieldName]) {
				setPasswordErrors({ ...passwordErrors, [fieldName]: "New password must differ from previous one" })
			} else {
				let passwordErrorLocal = JSON.parse(JSON.stringify(passwordErrors));
				delete passwordErrorLocal[fieldName]
				setPasswordErrors(passwordErrorLocal)
			}
		} else if (fieldName === "confirm_password") {
			if (passwordObject && passwordObject.password !== passwordObject[fieldName]) {
				setPasswordErrors({ ...passwordErrors, [fieldName]: "Passwords do not match!" })
			} else {
				let passwordErrorLocal = JSON.parse(JSON.stringify(passwordErrors));
				delete passwordErrorLocal[fieldName]
				setPasswordErrors(passwordErrorLocal)
			}
		}
	}

	const updateUserDataSubmit = () => {
		let errorFlag = true,
			errorObject = {};
		if (userDataLocal.first_name.trim() === "") {
			errorFlag = false
			errorObject = { ...errorObject, first_name: "Please enter your first name" }
		}
		if (userDataLocal.last_name.trim() === "") {
			errorFlag = false
			errorObject = { ...errorObject, last_name: "Please enter your last name" }
		}
		setErrors(errorObject)
		if (errorFlag) {
			setIsSubmitting(true)
			let data = { first_name: userDataLocal.first_name, last_name: userDataLocal.last_name }
			PostData("api/v1/updateUserInfo", data).then(response => {
				if (response !== "Invalid") {
					if (response.status === "success") {
						setUserData({ ...userData, first_name: userDataLocal.first_name, last_name: userDataLocal.last_name })
					}
				}
				setIsSubmitting(false)
			})
		}
	}

	const updateBrandInfo = async () => {
		setBrandSubmitting(true)
		let brand_logo	= file
		if( !/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(file) ){
			brand_logo	= fileImageUrl
		}
		PostData('api/v1/agency-brand-update', {hide_brand: userDataLocal.hide_brand, brand_logo}).then( response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){
					setUserData({...userData, hide_brand: userDataLocal.hide_brand, brand_logo: response.data.brand_logo});
					setFileImageUrl(response.data.brand_logo);
					setFile(response.data.brand_logo);
					setBrandSubmitting(false)
				}
			}
		})
	}

	const createImage = ( e ) => {
		let reader = new FileReader();
		reader.onload = e => {
			setFileImageUrl(e.target.result)
		};
		reader.readAsDataURL(e);
	}

	const updateUserPassword = () => {
		let errorFlag = true,
			errorObject = {};
		if (passwordObject.old_password.trim() === "") {
			errorFlag = false
			errorObject = { ...errorObject, old_password: "Please enter the current password" }
		} else if (passwordObject.old_password.length < 6) {
			errorFlag = false
			errorObject = { ...errorObject, old_password: "Minimum six character needed" }
		}

		if (passwordObject.password.trim() === "") {
			errorFlag = false
			errorObject = { ...errorObject, password: "Please enter the new password" }
		} else if (passwordObject.password.length < 6) {
			errorFlag = false
			errorObject = { ...errorObject, password: "Minimum six character needed" }
		} else if (passwordObject.password === passwordObject.old_password) {
			errorFlag = false
			errorObject = { ...errorObject, password: "New password must differ from previous one" }
		}
		if (passwordObject.confirm_password.trim() === "") {
			errorFlag = false
			errorObject = { errorObject, confirm_password: "Please enter the confirm password" }
		} else if (passwordObject.confirm_password !== passwordObject.password) {
			errorFlag = false
			errorObject = { ...errorObject, confirm_password: "Passwords do not match!" }
		}
		setPasswordErrors(errorObject)
		if (errorFlag) {
			setPasswordSubmitting(true)
			let data = { old_password: passwordObject.old_password, password: passwordObject.password }
			PostData("api/v1/updateUserPassword", data).then(response => {
				if (response !== "Invalid") {
					if (response.status === "success") {
						setPasswordObject({ password: "", old_password: "", confirm_password: "" })
					} else if (response.status === "failure") {
						setPasswordErrors({ old_password: "Old password is incorrect" })
					}
				}
				setPasswordSubmitting(false)
			})
		}
	}

	const fileUpload = !file && <DropZone.FileUpload actionTitle='Upload Logo Image' />;
	const uploadedFile = file && (		
		<div className='brandLogoImageWrapper d-flex align-items-center' onClick={(e) => {e.stopPropagation(); setFile(null); }}>
			<Thumbnail
				size="small"
				alt={typeof file.name !== "undefined" ? file.name : ""}
				source={
					/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(file) ?
					file : (
					validImageTypes.includes(file.type)
						? window.URL.createObjectURL(file)
						: NoteMinor
					)
				}
			/>
			{typeof file.name !== "undefined" &&
				<div>
					{file.name}{' '}
					<Text variant="bodySm" as="p">
						{file.size} bytes
					</Text>
				</div>
			}
			<div className='deleteImageWrapper'>
				<Icon
					source={CircleCancelMajor}
					color='red'
				/>

			</div>
		</div>
	);

	return (
		<div className="container-md homePageWrapper mt-20">
			<Card sectioned title="Account Settings">
				<p>Here is the place where you can edit your account and get video script.</p>
			</Card>
			<div className='mt-4 mb-4'>
				<Tabs tabs={tabs} onSelect={handleTabChange} selected={selected}>
					<div className='mt-5 mb-5'>
						{((selected) => {
							switch (selected) {
								case 0:
									return (
										<Card sectioned>
											<Layout>
												<Layout.Section>
													<div className='profileWrapper'>
														<div className='profileInnerWrapper'>
															<Form onSubmit={updateUserDataSubmit}>
																<FormLayout>
																	<div className='mb-4 passwordWrapper'>
																		<TextField
																			value={userDataLocal && userDataLocal.first_name}
																			onChange={(e) => handleInputChange('first_name', e)}
																			onBlur={(e) => handleInputBlur('first_name')}
																			label="First Name"
																			type="text"
																			name="first_name"
																			error={typeof errors['first_name'] !== "undefined" ? errors['first_name'] : ""}
																			placeholder='Enter your first name'
																			className="form-control"
																		/>
																	</div>
																	<div className='mb-4 passwordWrapper'>
																		<TextField
																			value={userDataLocal && userDataLocal.last_name}
																			onChange={(e) => handleInputChange('last_name', e)}
																			onBlur={(e) => handleInputBlur('last_name')}
																			label="Last Name"
																			type="text"
																			name="last_name"
																			error={typeof errors['last_name'] !== "undefined" ? errors['last_name'] : ""}
																			placeholder='Enter your last name'
																			className="form-control"
																		/>
																	</div>
																	<div className='mb-4 passwordWrapper'>
																		<TextField
																			value={userDataLocal && userDataLocal.email}
																			onChange={(e) => handleInputChange('email', e)}
																			onBlur={(e) => handleInputBlur('email')}
																			label="Email Address"
																			type="email"
																			name="email"
																			disabled
																			error={typeof errors['email'] !== "undefined" ? errors['email'] : ""}
																			placeholder='Enter your email address'
																			className="form-control"
																		/>
																	</div>
																	<div className='mb-4 row'>
																		<div className='col-auto'>
																			<p className='m-0'><strong>Your Account License</strong></p>
																		</div>
																		<div className='col'>
																			{(planData && planData.length > 0) &&
																				<div className='planDetailWrapper'>
																					{(planData.indexOf('MQ001') !== -1 || planData.indexOf('MQ007') !== -1) &&
																						<Badge status="success" progress="complete">Standard Edition</Badge>
																					}
																					{(planData.indexOf('MQ002') !== -1 || planData.indexOf('MQ003') !== -1) &&
																						<Badge status="success" progress="complete">Pro Edition</Badge>
																					}
																					{planData.indexOf('MQ004') !== -1 &&
																						<Badge status="success" progress="complete">Citations Edition</Badge>
																					}
																					{(planData.indexOf('MQ005') !== -1) &&
																						<Badge status="success" progress="complete">Local Agency</Badge>
																					}
																				</div>
																			}
																		</div>
																	</div>
																	<Button submit className="cta" primary>
																		{isSubmitting ?
																			<>
																				<Spinner accessibilityLabel="Small spinner example" size="small" /> Loading
																			</>
																			:
																			<>Update</>
																		}
																	</Button>
																	<div className='mt-5 row'>
																		<div className='col-12 mb-4'>
																			<p className='m-0'><strong>API Information</strong></p>
																		</div>
																		<div className='col-12'>
																			<div className='mb-4 passwordWrapper apiKeyWrapper d-flex align-items-center'>
																				<TextField
																					value={userDataLocal && userDataLocal.api_key}
																					label="API Key"
																					type="api_key"
																					name="api_key"
																					disabled
																					className="form-control"
																				/>
																				<CopyToClipboard text={userDataLocal.api_key} onCopy={() => setCopied(true)}>
																					<div role="button" className='apiCopyClipBoard'>
																						<Icon source={ClipboardMinor} />
																						{copied && (
																							<div className='copiedToolTip' role="tooltip" aria-describedby="copied ETH address!">
																								Copied
																							</div>
																						)}
																					</div>
																				</CopyToClipboard>
																			</div>
																		</div>
																	</div>
																</FormLayout>
															</Form>
														</div>
													</div>
												</Layout.Section>
											</Layout>
										</Card>
									);
								case 1:
									return (
										<Card sectioned>
											<Layout>
												<Layout.Section>
													<div className='profileWrapper'>
														<div className='profileInnerWrapper'>
															<Form onSubmit={updateUserPassword}>
																<FormLayout>
																	<div className='passwordWrapper mb-4'>
																		<TextField
																			value={passwordObject && passwordObject.old_password}
																			onChange={(e) => handlePasswordChange('old_password', e)}
																			onBlur={(e) => handlePasswordBlur('old_password')}
																			label="Old Password"
																			name="old_password"
																			type={oldPasswordShown ? "text" : "password"}
																			error={typeof passwordErrors['old_password'] !== "undefined" ? passwordErrors['old_password'] : ""}
																			placeholder='Old password'
																			className="form-control"
																		/>
																		<div className='passwordShowIcon' onClick={() => setOldPasswordShown(!oldPasswordShown)}>
																			{oldPasswordShown ?
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18" fill="none"><path d="M11.73 4.60209C9.4845 4.60209 7.65775 6.42884 7.65775 8.67463C7.65775 10.9204 9.4845 12.7472 11.73 12.7472C13.9755 12.7472 15.8022 10.9201 15.8022 8.67463C15.8022 6.42913 13.9755 4.60209 11.73 4.60209ZM11.73 11.2881C10.289 11.2881 9.11681 10.1156 9.11681 8.67463C9.11681 7.23366 10.2893 6.06116 11.73 6.06116C13.1707 6.06116 14.3432 7.23366 14.3432 8.67463C14.3432 10.1156 13.171 11.2881 11.73 11.2881ZM23.1591 8.25151C20.3318 4.27877 16.1658 2 11.73 2C7.29415 2 3.12823 4.27877 0.300859 8.25151L0 8.67463L0.300859 9.09747C3.12794 13.0705 7.29386 15.3493 11.73 15.3493C16.1661 15.3493 20.3321 13.0705 23.1591 9.09747L23.46 8.67463L23.1591 8.25151ZM11.73 13.8902C7.9303 13.8902 4.33896 11.9963 1.80165 8.67463C4.33896 5.35293 7.9306 3.45906 11.73 3.45906C15.5294 3.45906 19.121 5.35293 21.6583 8.67463C19.121 11.9963 15.5297 13.8902 11.73 13.8902Z" fill="#17173A"></path></svg>
																				:
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" fill="none"><path d="M16.8733 0.72943L15.6099 0L14.4336 2.03737C13.5497 1.84798 12.6448 1.75071 11.7284 1.75071C7.29313 1.75071 3.1278 4.02916 0.300817 8.00134L0 8.42441L0.300817 8.84719C2.16569 11.468 4.61323 13.3515 7.3366 14.3298L6.41908 15.919L7.68248 16.6484L16.8733 0.72943ZM8.07999 13.0422L9.00156 11.446C8.17615 10.7003 7.65668 9.62192 7.65668 8.42441C7.65668 6.17893 9.48317 4.35244 11.7284 4.35244C12.1659 4.35244 12.5875 4.42182 12.9829 4.55015L13.6616 3.37453C13.0262 3.26534 12.3803 3.20957 11.7284 3.20957C7.92949 3.20957 4.33836 5.10317 1.8014 8.42441C3.50456 10.6541 5.68271 12.2403 8.07999 13.0422ZM12.2271 5.85909C12.0656 5.82773 11.8989 5.8113 11.7284 5.8113C10.2879 5.8113 9.11554 6.98364 9.11554 8.42441C9.11554 9.07919 9.35761 9.67852 9.75696 10.1376L12.2271 5.85909ZM15.1566 6.22951L14.261 7.78073C14.3133 7.98671 14.3412 8.20237 14.3412 8.42441C14.3412 9.61967 13.5345 10.6302 12.437 10.9399L11.5408 12.4921C11.603 12.495 11.6655 12.4964 11.7284 12.4964C13.9735 12.4964 15.8 10.6696 15.8 8.42441C15.8 7.61679 15.5637 6.8633 15.1566 6.22951ZM11.7284 13.6393C11.4497 13.6393 11.1722 13.6291 10.8961 13.6089L10.0961 14.9945C10.6347 15.0632 11.1794 15.0981 11.7284 15.0981C16.1639 15.0981 20.3292 12.8197 23.1559 8.84719L23.4567 8.42441L23.1559 8.00134C21.5119 5.69133 19.4152 3.95414 17.0761 2.90476L16.3417 4.17686C18.3574 5.05172 20.1837 6.49789 21.6553 8.42441C19.1184 11.7457 15.5275 13.6393 11.7284 13.6393Z" fill="#C4C4CD" fillRule="evenodd" clipRule="evenodd"></path></svg>
																			}
																		</div>
																	</div>
																	<div className='passwordWrapper mb-4'>
																		<TextField
																			value={passwordObject && passwordObject.password}
																			onChange={(e) => handlePasswordChange('password', e)}
																			onBlur={(e) => handlePasswordBlur('password')}
																			label="Password"
																			name="password"
																			type={passwordShown ? "text" : "password"}
																			error={typeof passwordErrors['password'] !== "undefined" ? passwordErrors['password'] : ""}
																			placeholder='Password'
																			className="form-control"
																		/>
																		<div className='passwordShowIcon' onClick={() => setPasswordShown(!passwordShown)}>
																			{passwordShown ?
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18" fill="none"><path d="M11.73 4.60209C9.4845 4.60209 7.65775 6.42884 7.65775 8.67463C7.65775 10.9204 9.4845 12.7472 11.73 12.7472C13.9755 12.7472 15.8022 10.9201 15.8022 8.67463C15.8022 6.42913 13.9755 4.60209 11.73 4.60209ZM11.73 11.2881C10.289 11.2881 9.11681 10.1156 9.11681 8.67463C9.11681 7.23366 10.2893 6.06116 11.73 6.06116C13.1707 6.06116 14.3432 7.23366 14.3432 8.67463C14.3432 10.1156 13.171 11.2881 11.73 11.2881ZM23.1591 8.25151C20.3318 4.27877 16.1658 2 11.73 2C7.29415 2 3.12823 4.27877 0.300859 8.25151L0 8.67463L0.300859 9.09747C3.12794 13.0705 7.29386 15.3493 11.73 15.3493C16.1661 15.3493 20.3321 13.0705 23.1591 9.09747L23.46 8.67463L23.1591 8.25151ZM11.73 13.8902C7.9303 13.8902 4.33896 11.9963 1.80165 8.67463C4.33896 5.35293 7.9306 3.45906 11.73 3.45906C15.5294 3.45906 19.121 5.35293 21.6583 8.67463C19.121 11.9963 15.5297 13.8902 11.73 13.8902Z" fill="#17173A"></path></svg>
																				:
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" fill="none"><path d="M16.8733 0.72943L15.6099 0L14.4336 2.03737C13.5497 1.84798 12.6448 1.75071 11.7284 1.75071C7.29313 1.75071 3.1278 4.02916 0.300817 8.00134L0 8.42441L0.300817 8.84719C2.16569 11.468 4.61323 13.3515 7.3366 14.3298L6.41908 15.919L7.68248 16.6484L16.8733 0.72943ZM8.07999 13.0422L9.00156 11.446C8.17615 10.7003 7.65668 9.62192 7.65668 8.42441C7.65668 6.17893 9.48317 4.35244 11.7284 4.35244C12.1659 4.35244 12.5875 4.42182 12.9829 4.55015L13.6616 3.37453C13.0262 3.26534 12.3803 3.20957 11.7284 3.20957C7.92949 3.20957 4.33836 5.10317 1.8014 8.42441C3.50456 10.6541 5.68271 12.2403 8.07999 13.0422ZM12.2271 5.85909C12.0656 5.82773 11.8989 5.8113 11.7284 5.8113C10.2879 5.8113 9.11554 6.98364 9.11554 8.42441C9.11554 9.07919 9.35761 9.67852 9.75696 10.1376L12.2271 5.85909ZM15.1566 6.22951L14.261 7.78073C14.3133 7.98671 14.3412 8.20237 14.3412 8.42441C14.3412 9.61967 13.5345 10.6302 12.437 10.9399L11.5408 12.4921C11.603 12.495 11.6655 12.4964 11.7284 12.4964C13.9735 12.4964 15.8 10.6696 15.8 8.42441C15.8 7.61679 15.5637 6.8633 15.1566 6.22951ZM11.7284 13.6393C11.4497 13.6393 11.1722 13.6291 10.8961 13.6089L10.0961 14.9945C10.6347 15.0632 11.1794 15.0981 11.7284 15.0981C16.1639 15.0981 20.3292 12.8197 23.1559 8.84719L23.4567 8.42441L23.1559 8.00134C21.5119 5.69133 19.4152 3.95414 17.0761 2.90476L16.3417 4.17686C18.3574 5.05172 20.1837 6.49789 21.6553 8.42441C19.1184 11.7457 15.5275 13.6393 11.7284 13.6393Z" fill="#C4C4CD" fillRule="evenodd" clipRule="evenodd"></path></svg>
																			}
																		</div>
																	</div>
																	<div className='passwordWrapper mb-4'>
																		<TextField
																			value={passwordObject && passwordObject.confirm_password}
																			onChange={(e) => handlePasswordChange('confirm_password', e)}
																			onBlur={(e) => handlePasswordBlur('confirm_password')}
																			label="Confirm Password"
																			name="confirm_password"
																			type={confirmPasswordShown ? "text" : "password"}
																			error={typeof passwordErrors['confirm_password'] !== "undefined" ? passwordErrors['confirm_password'] : ""}
																			placeholder='Confirm Password'
																			className="form-control"
																		/>
																		<div className='passwordShowIcon' onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}>
																			{confirmPasswordShown ?
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18" fill="none"><path d="M11.73 4.60209C9.4845 4.60209 7.65775 6.42884 7.65775 8.67463C7.65775 10.9204 9.4845 12.7472 11.73 12.7472C13.9755 12.7472 15.8022 10.9201 15.8022 8.67463C15.8022 6.42913 13.9755 4.60209 11.73 4.60209ZM11.73 11.2881C10.289 11.2881 9.11681 10.1156 9.11681 8.67463C9.11681 7.23366 10.2893 6.06116 11.73 6.06116C13.1707 6.06116 14.3432 7.23366 14.3432 8.67463C14.3432 10.1156 13.171 11.2881 11.73 11.2881ZM23.1591 8.25151C20.3318 4.27877 16.1658 2 11.73 2C7.29415 2 3.12823 4.27877 0.300859 8.25151L0 8.67463L0.300859 9.09747C3.12794 13.0705 7.29386 15.3493 11.73 15.3493C16.1661 15.3493 20.3321 13.0705 23.1591 9.09747L23.46 8.67463L23.1591 8.25151ZM11.73 13.8902C7.9303 13.8902 4.33896 11.9963 1.80165 8.67463C4.33896 5.35293 7.9306 3.45906 11.73 3.45906C15.5294 3.45906 19.121 5.35293 21.6583 8.67463C19.121 11.9963 15.5297 13.8902 11.73 13.8902Z" fill="#17173A"></path></svg>
																				:
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" fill="none"><path d="M16.8733 0.72943L15.6099 0L14.4336 2.03737C13.5497 1.84798 12.6448 1.75071 11.7284 1.75071C7.29313 1.75071 3.1278 4.02916 0.300817 8.00134L0 8.42441L0.300817 8.84719C2.16569 11.468 4.61323 13.3515 7.3366 14.3298L6.41908 15.919L7.68248 16.6484L16.8733 0.72943ZM8.07999 13.0422L9.00156 11.446C8.17615 10.7003 7.65668 9.62192 7.65668 8.42441C7.65668 6.17893 9.48317 4.35244 11.7284 4.35244C12.1659 4.35244 12.5875 4.42182 12.9829 4.55015L13.6616 3.37453C13.0262 3.26534 12.3803 3.20957 11.7284 3.20957C7.92949 3.20957 4.33836 5.10317 1.8014 8.42441C3.50456 10.6541 5.68271 12.2403 8.07999 13.0422ZM12.2271 5.85909C12.0656 5.82773 11.8989 5.8113 11.7284 5.8113C10.2879 5.8113 9.11554 6.98364 9.11554 8.42441C9.11554 9.07919 9.35761 9.67852 9.75696 10.1376L12.2271 5.85909ZM15.1566 6.22951L14.261 7.78073C14.3133 7.98671 14.3412 8.20237 14.3412 8.42441C14.3412 9.61967 13.5345 10.6302 12.437 10.9399L11.5408 12.4921C11.603 12.495 11.6655 12.4964 11.7284 12.4964C13.9735 12.4964 15.8 10.6696 15.8 8.42441C15.8 7.61679 15.5637 6.8633 15.1566 6.22951ZM11.7284 13.6393C11.4497 13.6393 11.1722 13.6291 10.8961 13.6089L10.0961 14.9945C10.6347 15.0632 11.1794 15.0981 11.7284 15.0981C16.1639 15.0981 20.3292 12.8197 23.1559 8.84719L23.4567 8.42441L23.1559 8.00134C21.5119 5.69133 19.4152 3.95414 17.0761 2.90476L16.3417 4.17686C18.3574 5.05172 20.1837 6.49789 21.6553 8.42441C19.1184 11.7457 15.5275 13.6393 11.7284 13.6393Z" fill="#C4C4CD" fillRule="evenodd" clipRule="evenodd"></path></svg>
																			}
																		</div>
																	</div>
																	<Button submit className="cta" primary>
																		{passwordSubmitting ?
																			<>
																				<Spinner accessibilityLabel="Small spinner example" size="small" /> Loading
																			</>
																			:
																			<>Update Password</>
																		}
																	</Button>
																</FormLayout>
															</Form>
														</div>
													</div>
												</Layout.Section>
											</Layout>
										</Card>
									);
								case 2:
									return (
										<>
											<Card sectioned title="Install the code on your website">
												<p>paste this code snippet before "&lt;/body&gt;"</p>
											</Card>
											<div className='mb-4 mt-4'>
												<CopyToClipboard text={scriptContent} onCopy={() => setCopied(true)}>
													<div className='codeScriptWrapper'>
														<div className='codeScriptInnerWrapper'>
															<span className="snippet-cont"><code>{scriptContent && scriptContent}</code></span>
															<div role="button">
																<Icon source={ClipboardMinor} />
																{copied && (
																	<div className='copiedToolTip' role="tooltip" aria-describedby="copied ETH address!">
																		Copied
																	</div>
																)}
															</div>
														</div>
													</div>
												</CopyToClipboard>
											</div>
										</>
									);
								case 3:
									return (
										<Card sectioned>
											<Layout>
												<Layout.Section>
													<div className='profileWrapper'>
														<div className='profileInnerWrapper'>
															<Form onSubmit={updateBrandInfo}>
																<FormLayout>
																	<div className='passwordWrapper mb-4'>
																		<Label>Hide Brand</Label>
																		<label className="custom-toggle m-0 mt-3">
																			<input type="checkbox" checked={userDataLocal.hide_brand} onClick={() => setUserDataLocal({...userDataLocal, hide_brand: !userDataLocal.hide_brand})} />
																			<span className="custom-toggle-slider rounded-circle" />
																		</label>
																	</div>
																	<div className='passwordWrapper mb-4'>
																		<DropZone label="Brand Logo" allowMultiple={false} accept="image/*" type="image" onDrop={handleDropZoneDrop} >
																			{uploadedFile}
																			{fileUpload}
																		</DropZone>
																	</div>
																	<Button submit className="cta" primary>
																		{brandSubmitting ?
																			<>
																				<Spinner accessibilityLabel="Small spinner example" size="small" /> Loading
																			</>
																			:
																			<>Update Brand</>
																		}
																	</Button>
																</FormLayout>
															</Form>
														</div>
													</div>
												</Layout.Section>
											</Layout>
										</Card>
									)
								default:
									return (<></>)
							}
						})(selected)}
					</div>
				</Tabs>
			</div>
		</div>
	);
}

export default Settings;